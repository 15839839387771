import Cookies from "js-cookie";
import uid from "uid-safe";
import { COOKIE_DOMAIN, NODE_ENV } from "../config/constants";

/**
 * Get the user session id
 */
export const getUserSessionId = (): string => {
  return Cookies.get("session-id") || generateNewSessionId();
};

const environmentPrefix = (): string => {
  switch (NODE_ENV) {
    case "staging": {
      return "s";
    }
    case "testing": {
      return "t";
    }
    case "acceptance": {
      return "c";
    }
    case "production": {
      return "p";
    }
    default: {
      return "d";
    }
  }
};

const generateNewSessionId = (): string => {
  // Generate a new session-id
  const newId = `${environmentPrefix()}-${uid.sync(18)}`;

  // Save it as a cookie
  Cookies.set("session-id", newId, { domain: COOKIE_DOMAIN, path: "/" });

  return newId;
};
