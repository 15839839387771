import React, { ErrorInfo } from "react";
import { NODE_ENV } from "../../config/constants";
import "./style.scss";

interface IState {
  error?: Error;
  hasError: boolean;
  info?: ErrorInfo;
}

const RenderErrorDetails = ({ error }: { error?: Error }) => {
  if (error) {
    return (
      <div className="global-error-trace">
        <strong>{error.message}</strong>
        <br />
        {error.stack}
      </div>
    );
  }
  return null;
};

const RenderInfo = ({ info }: { info?: ErrorInfo }) => {
  if (info) {
    return <div className="global-error-info">{info.componentStack!}</div>;
  }
  return null;
};

const RenderError = ({ error, info }: { error?: Error; info?: ErrorInfo }) => (
  <div className="global-error">
    <h1>An error has occurred</h1>
    <RenderInfo info={info} />
    <RenderErrorDetails error={error} />
  </div>
);

class ErrorHandler extends React.Component<{}, IState> {
  public static getDerivedStateFromError() {
    return { hasError: true };
  }

  constructor(props: {}) {
    super(props);
    this.state = {
      error: undefined,
      hasError: false,
      info: undefined,
    };
  }

  public componentDidCatch(error: Error, info: ErrorInfo) {
    this.setState({ error, info });
  }

  public render() {
    return this.state.hasError ? this.showError() : this.props.children;
  }

  private showError = () => {
    // console.log(this.state);
    switch (NODE_ENV) {
      case "development":
      case "demo":
      case "testing":
        return <RenderError error={this.state.error} info={this.state.info} />;
      case "acceptance":
      case "production":
      default:
        return (
          <div className="global-error">
            <h1>An error has occurred</h1>
          </div>
        );
    }
  };
}

export default ErrorHandler;
