import * as React from "react";

const SVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 70 46"
    className={`svg-icon icon-${props.name} ${props.className || ""}`}
    width={props.width || "1em"}
    height={props.height || "1em"}
    strokeWidth={props.strokeWidth || "1.5"}
  >
    <g fill="none" stroke="currentColor"  strokeLinecap="round" strokeLinejoin="round">
      <path d="M68.4 31.7c0 7.1-5.8 12.9-13.1 12.9s-13.1-5.8-13.1-12.9S48 18.8 55.3 18.8s13.1 5.8 13.1 12.9zM27.7 31.7c0 7.1-5.8 12.9-13.1 12.9s-13-5.7-13-12.9 5.8-12.9 13.1-12.9 13 5.8 13 12.9zM55.3 31.7L45.2 1.6" />
      <path d="M39.4 1.6h13.1c2.4 0 4.4 1.9 4.4 4.3s-1.9 4.3-4.4 4.3H21.9l-7.3 21.5M16.1 4.5h11.6" />
    </g>
  </svg>
);

export default SVG;
