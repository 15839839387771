import * as React from "react";

const SVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 70 58"
    className={`svg-icon icon-${props.name} ${props.className || ""}`}
    width={props.width || "1em"}
    height={props.height || "1em"}
    strokeWidth={props.strokeWidth || "1.5"}
  >
    <g fill="none" stroke="currentColor"  strokeLinecap="round" strokeLinejoin="round">
      <path d="M41.2 14.9c0 7.7-6.2 13.9-13.9 13.9s-13.9-6.2-13.9-13.9S19.6 1 27.3 1s13.9 6.2 13.9 13.9zM53.5 56.6v-3.8c0-13.3-11.8-24-26.3-24C12.8 28.8 1 39.6 1 52.8v3.8M48.9 7.2c6 0 10.8 4.8 10.8 10.8s-4.8 10.8-10.8 10.8" />
      <path d="M69 50.5v-3c0-10.3-9-18.7-20.1-18.7" />
    </g>
  </svg>
);

export default SVG;
