import React from "react";
import { FormattedMessage } from "react-intl";
import Modal from "../../ui_kit/modal/modal";
import Section from "../../ui_kit/section/section";
import ImgEsitoPositivo from "../../../assets/esito_p.png";
import ImgEsitoNegativo from "../../../assets/esito_n.png";
import "./modal_numero_contratto.scss";

export interface IOwnProps {
  onCloseEnd?: (modal: Modal, element: Element) => void;
}

type Props = IOwnProps;

const ModalNumeroContratto: React.FunctionComponent<Props> = ({ onCloseEnd }) => {
  const closeEndHandler = (modal: Modal, element: Element) => {
    if (onCloseEnd) {
      onCloseEnd(modal, element);
    }
  };
  return (
    <Modal id="numero-contratto-modal" autoOpen={false} dismissible={true} onCloseEnd={closeEndHandler}>
      <Section className="center">
        <Section.Title>
          <FormattedMessage id="modal_numero_contratto.title" defaultMessage="Numero di contratto" />
        </Section.Title>
        <Section.Content>
          <p>
            {
              <FormattedMessage
                id="modal_numero_contratto.intro"
                defaultMessage={
                  'Il "numero di contratto" è visibile all\'interno della documentazione ricevuta via e-mail in fase di esame della richiesta di finanziamento:'
                }
              />
            }
          </p>
          <p>
            {
              <FormattedMessage
                id="modal_numero_contratto.esito_positivo"
                defaultMessage={
                  'In caso di buon esito, il "numero di contratto" è visibile nell\'intestazione del contratto stesso in corrispondenza della voce "n. contratto"'
                }
              />
            }
            <br />
            <img src={ImgEsitoPositivo} className="modal-image" />
          </p>
          <p>
            {
              <FormattedMessage
                id="modal_numero_contratto.esito_negativo"
                defaultMessage={
                  'In caso di esito negativo, il "numero di contratto" è rintracciabile nell\'oggetto della lettera di motivazione rifiuto/liberatoria'
                }
              />
            }
            <br />
            <img src={ImgEsitoNegativo} className="modal-image" />
          </p>
        </Section.Content>
      </Section>
    </Modal>
  );
};

export default React.memo(ModalNumeroContratto);
