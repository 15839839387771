import produce from "immer";
import { SET_ERROR } from "./consts";
import { IState } from "./types";

const initialState: IState = {
  errors: undefined,
};

// const successful = {
//   error: {
//     status: 200,
//   },
//   loading: false,
// };

export default produce((draft: IState, action) => {
  switch (action.type) {
    case SET_ERROR: {
      draft.errors = action.payload;
      return;
    }
  }
}, initialState);
