import { Form, Formik, FormikProps } from "formik";
import get from "lodash.get";
import React, { useEffect, useState } from "react";
import { defineMessages, FormattedHTMLMessage, FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import * as Yup from "yup";
import { LoginResponse, LoginResponseOtpDeviceStateEnum, RecoverUsernameRequest } from "../../generated";
import { clearToken, recoverUsername, resetResponseCode } from "../../logic/authentication/actions";
import {
  ERROR_REQUEST_BLOCKED_OTP,
  ERROR_REQUEST_BLOCKED_USER,
  ERROR_REQUEST_DISABLED_USER,
  ERROR_REQUEST_MIGRATED_WITHOUT_NUMBER,
  ERROR_REQUEST_REGISTER_PHONE,
  RECOVER_USERNAME_RESET_RESPONSE,
} from "../../logic/authentication/consts";
import { IBasicRequest, IState } from "../../logic/authentication/types";
import { numeroContrattoRegexp } from "../../logic/ui/consts";
import { IStoreState } from "../../types/store";
import FormAuth from "../shared/form_auth/form_auth";
import NumeroContrattoInput from "../shared/Inputs/NumeroContrattoInput/NumeroContrattoInput";
import ModalNumberNotCorresponding from "../shared/modals/modal_number_not_corresponding";
import ModalRegisterAgain from "../shared/modals/modal_register_again";
import ModalRegisterNumber from "../shared/modals/modal_register_number";
import ModalServerError from "../shared/modals/modal_server_error";
import ModalUserBlocked from "../shared/modals/modal_user_blocked";
import ModalUserDisabled from "../shared/modals/modal_user_disabled";
import ReCaptcha, { execute, reCaptchaPubKey } from "../shared/recaptcha/recaptcha";
import Spinner from "../shared/spinner/spinner";
import Button from "../ui_kit/button/button";
import { InputText } from "../ui_kit/form/input_text";
import "./_form_recover_username.scss";

export interface IDispatchProps {
  // getPolicies: () => void;
  recoverUsername: (recoverUsernameRequest: RecoverUsernameRequest) => void;
  resetResponseCode: (action: string) => void;
  clearToken: () => void;
}

export interface IStateProps {
  // policies: IBasicRequest & Policies;
  recoverUsernameResponse: IBasicRequest & LoginResponse;
}

const translations = defineMessages({
  mustBeANumber: {
    defaultMessage: "Il dato inserito deve essere numerico",
    description: "Il dato inserito deve essere numerico",
    id: "generic.mustBeANumber",
  },
  required: {
    defaultMessage: "Campo obbligatorio",
    description: "campo obbligatorio",
    id: "generic.required",
  },
});

const initialState = {
  cellulare: "",
  codiceFiscale: "",
  numeroContratto: "",
  recaptchaToken: "",
};

const getValidationSchema = (t: any) => {
  return Yup.object().shape({
    cellulare: Yup.string().required(t(translations.required)),
    codiceFiscale: Yup.string().required(t(translations.required)),
    numeroContratto: Yup.string()
      .matches(numeroContrattoRegexp, { message: t(translations.mustBeANumber) })
      .required(t(translations.required)),
    recaptchaToken: Yup.string(),
  });
};

type Props = IStateProps & IDispatchProps;

const FormRecoverUsername: React.FunctionComponent<Props> = ({
  // getPolicies,
  // policies,
  clearToken,
  recoverUsername,
  recoverUsernameResponse,
  resetResponseCode,
  intl: { formatMessage: t },
}) => {
  const [recaptchaPending, setRecaptchaPending] = useState(false);

  useEffect(() => {
    clearToken();
  }, []);

  useEffect(() => {
    // getPolicies();
    return () => {
      resetResponseCode(RECOVER_USERNAME_RESET_RESPONSE);
    };
  }, []);

  if (get(recoverUsernameResponse, "error.status") === 200 && !recoverUsernameResponse.ndg) {
    return <Redirect to="/server-error" />;
  }

  if (
    get(recoverUsernameResponse, "error.status") === 200 &&
    recoverUsernameResponse.otpDeviceState === LoginResponseOtpDeviceStateEnum.MISSING
  ) {
    return <Redirect to="/users/add-otp-device" />;
  }

  if (
    get(recoverUsernameResponse, "error.status") === 200 &&
    !get(recoverUsernameResponse, "mobileNumberDifferent", false)
  ) {
    return (
      <Redirect
        // to="/auth/otp"
        to={{
          pathname: "/auth/otp",
          state: { showRecoverUsername: true },
        }}
      />
    );
  }

  const formikSubmit = (values: any, actions: any) => {
    execute({ sitekey: reCaptchaPubKey, callback: recaptchaCallBack(values), action: "recoverUsername" });
    setRecaptchaPending(true);
  };

  const recaptchaCallBack = (values: any) => (token: any) => {
    if (token) {
      values.recaptchaToken = token;
    }
    const data = {
      usernameRecoveryRequest: values,
    };
    recoverUsername(data);

    setRecaptchaPending(false);
  };

  const closeModalHandler = () => {
    resetResponseCode(RECOVER_USERNAME_RESET_RESPONSE);
  };

  return (
    <FormAuth
      hasRecaptcha={true}
      pageContainerClass="recover-username-container"
      actionsClass=""
      formContentClass=""
      title={<FormattedHTMLMessage id="form_recover_username.title" defaultMessage="Recupero username" />}
      intro={
        <FormattedHTMLMessage
          id="form_recover_username.intro"
          defaultMessage="Inserisci i tuoi dati personali per procedere al recupero."
        />
      }
      showRequired={true}
      content={
        <Formik initialValues={initialState} validationSchema={getValidationSchema(t)} onSubmit={formikSubmit}>
          {(formikProps: FormikProps<any>) => (
            <Form>
              {/* <InputText label={<FormattedMessage id="form_recover_username.email" defaultMessage="Email*" />} name="email" /> */}
              <NumeroContrattoInput />
              <InputText
                label={<FormattedMessage id="form_recover_username.codice_fiscale" defaultMessage="Codice fiscale*" />}
                name="codiceFiscale"
              />
              <InputText
                label={<FormattedMessage id="form_recover_username.cellulare" defaultMessage="Numero cellulare*" />}
                name="cellulare"
                defaultHelperText={
                  <FormattedMessage
                    id="form_recover_username.mobile_phone_helper"
                    defaultMessage="Il numero di cellulare deve corrispondere con quello registrato in fase di richiesta della pratica, in caso contrario utilizza il link a fondo pagina per inserirlo/modificarlo."
                  />
                }
              />
              <ReCaptcha
                action="recover-username"
                callback={recaptchaCallBack(formikProps)}
                sitekey={reCaptchaPubKey}
              />
              {get(recoverUsernameResponse, "error.status") === 400 && (
                <div className="form-error">{get(recoverUsernameResponse, "error.response.description")}</div>
              )}
              {(recoverUsernameResponse && recoverUsernameResponse.loading) || recaptchaPending ? (
                <Spinner />
              ) : (
                <Button data-cy="button-submit" disabled={!formikProps.isValid} inverted={false} type="submit">
                  <FormattedMessage id="form_recover_username.proceed" defaultMessage="Procedi" />
                  {/* <Icon name="arrow-right" className="icon-blue" strokeWidth="2" /> */}
                </Button>
              )}
            </Form>
          )}
        </Formik>
      }
      actions={
        <>
          <a className="change-number modal-trigger" data-target="register-number-modal" data-cy="register-number">
            <FormattedMessage
              id="form_recover_username.change_number"
              defaultMessage="Vuoi inserire/modificare il cellulare? Chiedi a un esperto."
            />
          </a>
        </>
      }
      modals={
        <>
          {get(recoverUsernameResponse, "error.status") === 403 &&
            (get(recoverUsernameResponse, "error.response.code") === ERROR_REQUEST_BLOCKED_USER ||
              get(recoverUsernameResponse, "error.response.code") === ERROR_REQUEST_BLOCKED_OTP) && (
              <ModalUserBlocked onCloseEnd={closeModalHandler} />
            )}
          {get(recoverUsernameResponse, "error.status") === 403 &&
            get(recoverUsernameResponse, "error.response.code") === ERROR_REQUEST_DISABLED_USER && (
              <ModalUserDisabled onCloseEnd={closeModalHandler} />
            )}
          {get(recoverUsernameResponse, "error.status") === 403 &&
            get(recoverUsernameResponse, "error.response.code") === ERROR_REQUEST_MIGRATED_WITHOUT_NUMBER && (
              <ModalRegisterAgain onCloseEnd={closeModalHandler} />
            )}
          {get(recoverUsernameResponse, "error.status") === 400 &&
            get(recoverUsernameResponse, "error.response.code") === ERROR_REQUEST_REGISTER_PHONE && (
              <ModalRegisterNumber
                context="recover_username"
                autoOpen={true}
                dismissible={false}
                onCloseEnd={closeModalHandler}
              />
            )}
          {get(recoverUsernameResponse, "error.status") === 200 &&
            get(recoverUsernameResponse, "mobileNumberDifferent", false) && (
              <ModalNumberNotCorresponding onCloseEnd={closeModalHandler} showUsernameModal={true} />
            )}
          <ModalRegisterNumber context="recover_username" />
          {get(recoverUsernameResponse, "error.status") >= 500 && <ModalServerError onCloseEnd={closeModalHandler} />}
        </>
      }
    />
  );
};

const mapStateToProps = (state: IStoreState): IState => ({
  // policies: state.authentication.policies,
  recoverUsernameResponse: state.authentication.recoverUsernameResponse,
});

const mapDispatchToProps: IDispatchProps = {
  // getPolicies,
  clearToken,
  recoverUsername,
  resetResponseCode,
};

const withIntlComponent = injectIntl(FormRecoverUsername);

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(withIntlComponent));
