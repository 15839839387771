import * as React from "react";

const SVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 90 90"
    className={`svg-icon icon-${props.name} ${props.className || ""}`}
    width={props.width || "1em"}
    height={props.height || "1em"}
    strokeWidth={props.strokeWidth || "1.5"}
  >
    <g fill="none" stroke="currentColor"  strokeLinecap="round" strokeLinejoin="round">
      <path d="M78.244 58.944c-3.6 8.8-10.5 15.7-19.3 19.4h0M30.544 78.344c-8.8-3.7-15.5-10.6-19.1-19.4M78.244 30.844h0M11.444 30.544c3.6-8.8 10.3-15.5 19.1-19.1" />
      <path d="M78.244 58.944c-3.6 8.8-10.5 15.7-19.3 19.4h0M78.544 30.844h0c-3.6-8.8-10.3-15.7-19.1-19.4h0M23.844 53.744l-15.5 6.5c-2.1-4.6-3.1-10.1-3.1-15.5s1-10.6 3.1-15.5l15.7 6.7M53.544 65.644l6.7 16c-4.6 2.1-10.1 3.1-15.5 3.1s-10.6-1-15.5-3.1l6.7-16" />
      <path d="M65.644 36.244l16-6.7c2.1 4.6 3.1 10.1 3.1 15.5s-1 10.6-3.1 15.5l-16-6.7M36.244 24.344l-6.7-16c4.6-2.1 10.1-3.1 15.5-3.1s10.6 1 15.5 3.1l-6.7 16" />
      <path d="M67.144 45.044c0 12.4-10.1 22.4-22.4 22.4s-22.4-10-22.4-22.4 10.1-22.4 22.4-22.4 22.4 10 22.4 22.4z" />
    </g>
  </svg>
);

export default SVG;
