import * as React from "react";

const SVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 90 90"
    className={`svg-icon icon-${props.name} ${props.className || ""}`}
    width={props.width || "1em"}
    height={props.height || "1em"}
    strokeWidth={props.strokeWidth || "1.5"}
  >
    <g fill="none" stroke="currentColor"  strokeLinecap="round" strokeLinejoin="round">
      <path d="M84.067 44.967c0 21.6-17.6 38.9-38.9 38.9s-39.2-17.3-39.2-38.9 17.3-38.8 38.9-38.8 39.2 17.2 39.2 38.8z" />
      <path d="M54.189 33.989c0 4.9-4 8.9-9.3 8.9s-9.5-4-9.5-8.9 4-8.9 9.3-8.9 9.5 3.9 9.5 8.9zM54.189 65.089h-9.3v-22.2M44.889 56.189h9.3" />
    </g>
  </svg>
);

export default SVG;
