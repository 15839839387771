import * as React from "react";

const SVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 44 70"
    className={`svg-icon icon-${props.name} ${props.className || ""}`}
    width={props.width || "1em"}
    height={props.height || "1em"}
    strokeWidth={props.strokeWidth || "1.5"}
  >
    <g fill="none" stroke="currentColor"  strokeLinecap="round" strokeLinejoin="round">
      <path d="M21.8 16.4c3.1 0 5.6 2.5 5.6 5.6s-2.5 5.6-5.6 5.6-5.6-2.5-5.6-5.6 2.6-5.6 5.6-5.6z" />
      <path d="M24.9 1.6c-1-.1-2-.2-3-.2s-2 .1-3 .2C7.7 3.3 0 13.5 1.6 24.5c1 9.7 8.1 16.8 13.5 25.1 2.7 4.1 4.2 8.9 4.2 13.9v3.2c0 1.3 1.1 2.3 2.4 2.3s2.4-1 2.4-2.3v-2.9c0-4.9 1.4-9.7 4.1-13.8C33.7 41.6 41 34.4 42 24.5c1.7-11-6-21.2-17.1-22.9z" />
    </g>
  </svg>
);

export default SVG;
