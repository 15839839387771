import React from "react";
import { FormattedHTMLMessage, FormattedMessage } from "react-intl";
import { CHIEDI_ESPERTO_URL } from "../../../config/constants";
import Modal from "../../ui_kit/modal/modal";
import Section from "../../ui_kit/section/section";

export interface IOwnProps {
  onCloseEnd?: (modal: Modal, element: Element) => void;
}

type Props = IOwnProps;

const ModalMigratedNumberMissing: React.FunctionComponent<Props> = ({ onCloseEnd }) => {
  const closeEndHandler = (modal: Modal, element: Element) => {
    if (onCloseEnd) {
      onCloseEnd(modal, element);
    }
  };
  return (
    <Modal id="modal-migrated-number-missing" autoOpen={true} dismissible={false} onCloseEnd={closeEndHandler}>
      <Section className="center">
        <Section.Title>
          <FormattedMessage id="modal_migrated_number_missing.title" defaultMessage="Numero non corrispondente" />
        </Section.Title>
        <Section.Content>
          <FormattedHTMLMessage
            id="modal_migrated_number_missing.description"
            defaultMessage="Il numero inserito non corrisponde a quello presente in anagrafica."
          />
          <div className="modal-actions center">
            <a target="_blank" href={CHIEDI_ESPERTO_URL} className="btn modal-action">
              <FormattedMessage id="modal_migrated_number_missing.cta" defaultMessage="Chiedi a un esperto" />
            </a>
          </div>
        </Section.Content>
      </Section>
    </Modal>
  );
};

export default React.memo(ModalMigratedNumberMissing);
