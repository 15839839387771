import React from "react";
import { FormattedHTMLMessage, FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { CHIEDI_ESPERTO_URL } from "../../../config/constants";
import { otpResend } from "../../../logic/authentication/actions";
import { ButtonColors } from "../../ui_kit/button/button";
import LinkButton from "../../ui_kit/button/link_button";
import Modal from "../../ui_kit/modal/modal";
import Section from "../../ui_kit/section/section";

export interface IOwnProps {
  onCloseEnd?: (modal: Modal, element: Element) => void;
  showUsernameModal?: boolean;
}

export interface IDispatchProps {
  otpResend: () => void;
}

type Props = IOwnProps & IDispatchProps;

const ModalNumberNotCorresponding: React.FunctionComponent<Props> = ({ showUsernameModal, onCloseEnd, otpResend }) => {
  const closeEndHandler = (modal: Modal, element: Element) => {
    if (onCloseEnd) {
      onCloseEnd(modal, element);
    }
  };

  const handleResendOTP = () => {
    otpResend();
  };

  const state = { showRecoverUsername: showUsernameModal };

  return (
    <Modal id="cell-different-modal" autoOpen={true} dismissible={true} onCloseEnd={closeEndHandler}>
      <Section className="center">
        <Section.Title>
          <FormattedMessage id="modal_number_not_corresponding.title" defaultMessage="Numero non corrispondente" />
        </Section.Title>
        <Section.Content>
          <FormattedHTMLMessage
            id="modal_number_not_corresponding.number_not_corresponding"
            defaultMessage="Il numero inserito non corrisponde a quello presente in anagrafica.</br>Puoi inviare il codice al numero precedente oppure contattare il servizio clienti."
          />
          <div className="modal-actions center">
            <a target="_blank" href={CHIEDI_ESPERTO_URL} className="btn modal-action">
              <FormattedMessage
                id="modal_number_not_corresponding.ask_to_expert"
                defaultMessage="Chiedi a un esperto"
              />
            </a>
            <LinkButton
              to={{
                pathname: "/auth/otp",
                state,
              }}
              color={ButtonColors.Red}
              inverted={true}
              className="modal-action"
              onClick={handleResendOTP}
            >
              <FormattedMessage
                id="modal_number_not_corresponding.send_otp_to_previous_number"
                defaultMessage="Invia codice al numero precedente"
              />
            </LinkButton>
          </div>
        </Section.Content>
      </Section>
    </Modal>
  );
};

const mapDispatchToProps: IDispatchProps = {
  otpResend,
};

export default React.memo(connect(undefined, mapDispatchToProps)(ModalNumberNotCorresponding));
