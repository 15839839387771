import * as React from "react";

const SVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 68 61"
    className={`svg-icon icon-${props.name} ${props.className || ""}`}
    width={props.width || "1em"}
    height={props.height || "1em"}
    strokeWidth={props.strokeWidth || "1.5"}
  >
    <g fill="none" stroke="currentColor"  strokeLinecap="round" strokeLinejoin="round">
      <path d="M16.214 59.8h-11.3v-8.2l5.7-5.5 5.6 5.5v4.1zM33.114 59.8h-11.2V40.6l5.6-5.5 5.6 5.5v9.6zM50.114 59.8h-11.3v-33l5.6-5.5 5.7 5.5v16.5zM67.014 59.8h-11.3V13.1l5.7-5.5 5.6 5.5v23.3zM34.716 14.6V.8h-14.1M34.716.8l-33.9 33" />
    </g>
  </svg>
);

export default SVG;
