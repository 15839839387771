import React from "react";
import { Link } from "react-router-dom";
import Icon from "../ui_kit/icon/icon";
import "./_header.scss";

const Header: React.FunctionComponent = () => {
  return (
    <div className="header-container">
      <Link to="/auth/login">
        <Icon name="logo-desktop" className="icon-red logo-desktop" />
      </Link>
    </div>
  );
};

export default React.memo(Header);
