import * as React from "react";

const SVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 70 62"
    className={`svg-icon icon-${props.name} ${props.className || ""}`}
    width={props.width || "1em"}
    height={props.height || "1em"}
    strokeWidth={props.strokeWidth || "1.5"}
  >
    <g fill="none" stroke="currentColor"  strokeLinecap="round" strokeLinejoin="round">
      <path d="M31.4 25.7L17.5 2c-.5-.8-1.7-.8-2.2 0L1.4 25.8M16.4 60.3V1.7M69 36.3L55.1 60c-.5.8-1.7.8-2.2 0L39 36.3M54 1.7v58.6" />
    </g>
  </svg>
);

export default SVG;
