import * as React from "react";

const SVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 67 60"
    className={`svg-icon icon-${props.name} ${props.className || ""}`}
    width={props.width || "1em"}
    height={props.height || "1em"}
    strokeWidth={props.strokeWidth || "1.5"}
  >
    <g fill="none" stroke="currentColor"  strokeLinecap="round" strokeLinejoin="round">
      <path d="M25 45.6h-1.9c-11.8 0-21.4-4.1-21.4-9.1M1.7 22.5v14" />
      <path d="M35.9 56.8c-3.6 1.2-8 1.8-12.9 1.8-11.8 0-21.4-4.1-21.4-9.1v-13M23.4 32.6c-.2 1.3-.4 2.6-.4 3.9 0 3.2.7 6.3 1.9 9.1M44.5 14.3c11.8 0 21.4 9.9 21.4 22.2s-9.6 22.2-21.4 22.2c-3 0-5.9-.7-8.5-1.8M25 45.6c2.2 5 6.1 9 11 11.2M42.3 14.5c.7-.1 1.5-.1 2.2-.1M31.7 18.8c3-2.3 6.6-3.9 10.6-4.3M23.4 32.6c1-5.6 4-10.5 8.2-13.8" />
      <path d="M38.3 33.6c.3-.7.6-1.3.9-1.9.4-.6.8-1.2 1.2-1.7.5-.5 1-1 1.5-1.4.5-.4 1.1-.8 1.7-1 .6-.3 1.2-.5 1.9-.7.7-.1 1.3-.2 2.1-.2.7 0 1.4.1 2.1.2M38.3 40.9c.3.7.6 1.3.9 1.9.4.6.8 1.2 1.2 1.7.5.5 1 1 1.5 1.4.5.4 1.1.8 1.7 1 .6.3 1.2.5 1.9.7.7.1 1.3.2 2.1.2.7 0 1.4-.1 2.1-.2M35.3 33.8h12.6M35.3 40.8h12.6M23.2 31.6c-11.8 0-21.3-4.1-21.3-9.1" />
      <path d="M36.9 15.8c-3.8-1.4-8.3-2.3-13.7-2.3-11.8 0-21.3 4.1-21.3 9.1" />
      <g>
        <path d="M24.175 5.063l-3.89-3.889-3.747 3.748M20.286 1.174l.283 18.385" />
      </g>
    </g>
  </svg>
);

export default SVG;
