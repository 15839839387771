import * as React from "react";

const SVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 70 56"
    className={`svg-icon icon-${props.name} ${props.className || ""}`}
    width={props.width || "1em"}
    height={props.height || "1em"}
    strokeWidth={props.strokeWidth || "1.5"}
  >
    <g fill="none" stroke="currentColor"  strokeLinecap="round" strokeLinejoin="round">
      <path d="M24.8 10.4V4.6c0-1.6 1.3-2.9 2.9-2.9h14.5c1.6 0 2.9 1.3 2.9 2.9v5.9M65.5 32.1v19.4c0 1.6-1.3 2.9-2.9 2.9H7.4c-1.6 0-2.9-1.3-2.9-2.9V32.1" />
      <path d="M39.4 36.7c10.6-.4 20.9-2.3 29-5.8V13.4c0-1.6-1.3-2.9-2.9-2.9h-61c-1.6 0-2.9 1.3-2.9 2.9V31c8.1 3.5 18.4 5.4 29 5.8" />
      <path d="M30.6 39.8h8.8v-5.9h-8.8z" />
    </g>
  </svg>
);

export default SVG;
