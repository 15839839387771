import * as React from "react";

const SVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 70 62"
    className={`svg-icon icon-${props.name} ${props.className || ""}`}
    width={props.width || "1em"}
    height={props.height || "1em"}
    strokeWidth={props.strokeWidth || "1.5"}
  >
    <g fill="none" stroke="currentColor"  strokeLinejoin="round">
      <path strokeLinecap="round" d="M20 1.5v14.3c0 1.6.3 2.7.8 3.5s1.4 1.4 2.5 1.8c2.1.6 4.4.9 6.8 1.3l27.4 4.5" />
      <path
        strokeLinecap="square"
        d="M64.9 43.6v-5.7c0-1.4-.3-2.9-.6-4.2-.9-3.1-3.1-5.6-6.2-6.6-.2-.1-.5-.1-.7-.2v-1.2c0-5.2-5.1-10.8-7.6-13C42 5.8 29.6 1.3 12.6 1.2H8.9c-2 0-3.8 1.8-3.8 3.7v25.7s-1 .3-1.2.3c-.9.3-1.5.7-1.9 1.4-.4.6-.6 1.5-.6 2.8v15.6M19 56.3h31.9"
      />
      <path
        strokeLinecap="round"
        d="M68.6 51.3c0 5.1-4.2 9.2-9.3 9.2S50 56.4 50 51.3s4.2-9.2 9.3-9.2 9.3 4.2 9.3 9.2zM20.1 51.3c0 5.1-4.2 9.2-9.3 9.2s-9.3-4.1-9.3-9.2 4.2-9.2 9.3-9.2 9.3 4.2 9.3 9.2z"
      />
    </g>
  </svg>
);

export default SVG;
