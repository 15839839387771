import { Dispatch } from "redux";
import {
  AuthenticationApi,
  AuthorizationResponse,
  AuthorizeRequest,
  ChangePasswordRequest,
  LoginRequest,
  LoginResponse,
  OtpAddDeviceResponse,
  OtpVerificationRequest,
  OtpVerificationResponse,
  Policies,
  RecoverPasswordRequest,
  RecoverUsernameRequest,
  RecoveryRequest,
  SetOtpDeviceRequest,
  SsoAreaRiservataPrecedenteResponse,
  UserRegistrationRequest,
} from "../../generated";
import { PasswordChangeResponse } from "../../generated/models/PasswordChangeResponse";
import { apiConfig, errorAction } from "../../lib/api";
import IAction from "../../types/action";
import {
  AUTHORIZE,
  AUTHORIZE_FAILED,
  AUTHORIZE_PENDING,
  CLEAR_TOKEN,
  GET_POLICIES,
  GET_POLICIES_FAILED,
  GET_POLICIES_PENDING,
  LOGIN,
  LOGIN_FAILED,
  LOGIN_PENDING,
  OTP_RESEND,
  OTP_RESEND_FAILED,
  OTP_RESEND_PENDING,
  OTP_VERIFICATION,
  OTP_VERIFICATION_FAILED,
  OTP_VERIFICATION_PENDING,
  PASSWORD_CHANGE,
  PASSWORD_CHANGE_FAILED,
  PASSWORD_CHANGE_PENDING,
  RE_ENABLE_USER,
  RE_ENABLE_USER_FAILED,
  RE_ENABLE_USER_PENDING,
  RECOVER_PASSWORD,
  RECOVER_PASSWORD_FAILED,
  RECOVER_PASSWORD_PENDING,
  RECOVER_USERNAME,
  RECOVER_USERNAME_FAILED,
  RECOVER_USERNAME_PENDING,
  REGISTER_USER,
  REGISTER_USER_FAILED,
  REGISTER_USER_PENDING,
  SET_OTP_DEVICE,
  SET_OTP_DEVICE_FAILED,
  SET_OTP_DEVICE_PENDING,
  SET_TOKEN,
  SSO,
  SSO_FAILED,
  SSO_PENDING,
} from "./consts";

const api = new AuthenticationApi(apiConfig());

export const resetResponseCode = (action: string) => ({
  type: action,
});

export const setToken = (token: string) => ({
  payload: { token },
  type: SET_TOKEN,
});

export const clearToken = () => ({
  type: CLEAR_TOKEN,
});

export const loginUser = (loginRequest: LoginRequest) => async (dispatch: Dispatch<IAction<LoginResponse>>) => {
  dispatch({
    type: LOGIN_PENDING,
  });

  api.loginUser({ loginRequest }).subscribe(
    (value) => {
      dispatch({
        payload: value,
        type: LOGIN,
      });
    },
    (errorPayload) => dispatch({ error: true, payload: errorPayload, type: LOGIN_FAILED })
  );
};

export const otpValidate =
  (otpVerificationRequest: OtpVerificationRequest) => async (dispatch: Dispatch<IAction<OtpVerificationResponse>>) => {
    dispatch({
      type: OTP_VERIFICATION_PENDING,
    });

    api.otpValidate({ otpVerificationRequest }).subscribe(
      (payload: OtpVerificationResponse) => {
        dispatch({
          payload,
          type: OTP_VERIFICATION,
        });
      },
      (errorPayload) => dispatch({ error: true, payload: errorPayload, type: OTP_VERIFICATION_FAILED })
    );
  };

export const otpResend = () => async (dispatch: Dispatch<IAction<null>>) => {
  dispatch({
    type: OTP_RESEND_PENDING,
  });

  api.otpResend().subscribe(
    () => {
      dispatch({
        type: OTP_RESEND,
      });
    },
    (errorPayload) => dispatch(errorAction(OTP_RESEND_FAILED, errorPayload))
  );
};

export const getPolicies = () => async (dispatch: Dispatch<IAction<Policies>>) => {
  dispatch({
    type: GET_POLICIES_PENDING,
  });

  api.getPolicies().subscribe(
    (payload: Policies) => {
      dispatch({
        payload,
        type: GET_POLICIES,
      });
    },
    (errorPayload) => dispatch(errorAction(GET_POLICIES_FAILED, errorPayload))
  );
};

export const registerUser =
  (userRegistrationRequest: UserRegistrationRequest) => async (dispatch: Dispatch<IAction<LoginResponse>>) => {
    dispatch({
      type: REGISTER_USER_PENDING,
    });

    api.registerUser({ userRegistrationRequest }).subscribe(
      (payload: LoginResponse) => {
        dispatch({
          payload,
          type: REGISTER_USER,
        });
      },
      (errorPayload) => dispatch({ error: true, payload: errorPayload, type: REGISTER_USER_FAILED })
    );
  };

export const recoverPassword =
  (recoverPasswordRequest: RecoverPasswordRequest) => async (dispatch: Dispatch<IAction<LoginResponse>>) => {
    dispatch({
      type: RECOVER_PASSWORD_PENDING,
    });

    api.recoverPassword(recoverPasswordRequest).subscribe(
      (payload: LoginResponse) => {
        dispatch({
          payload,
          type: RECOVER_PASSWORD,
        });
      },
      (errorPayload) => dispatch({ error: true, payload: errorPayload, type: RECOVER_PASSWORD_FAILED })
    );
  };

export const recoverUsername =
  (recoverUsernameRequest: RecoverUsernameRequest) => async (dispatch: Dispatch<IAction<LoginResponse>>) => {
    dispatch({
      type: RECOVER_USERNAME_PENDING,
    });

    api.recoverUsername(recoverUsernameRequest).subscribe(
      (payload: LoginResponse) => {
        dispatch({
          payload,
          type: RECOVER_USERNAME,
        });
      },
      (errorPayload) => dispatch({ error: true, payload: errorPayload, type: RECOVER_USERNAME_FAILED })
    );
  };

export const reEnableUser =
  (recoveryRequest: RecoveryRequest) => async (dispatch: Dispatch<IAction<LoginResponse>>) => {
    dispatch({
      type: RE_ENABLE_USER_PENDING,
    });

    api.reEnableUser({ recoveryRequest }).subscribe(
      (payload: LoginResponse) => {
        dispatch({
          payload,
          type: RE_ENABLE_USER,
        });
      },
      (errorPayload) => dispatch({ error: true, payload: errorPayload, type: RE_ENABLE_USER_FAILED })
    );
  };

export const setOtpDevice =
  (setOtpDeviceRequest: SetOtpDeviceRequest) => async (dispatch: Dispatch<IAction<OtpAddDeviceResponse>>) => {
    dispatch({
      type: SET_OTP_DEVICE_PENDING,
    });

    api.setOtpDevice(setOtpDeviceRequest).subscribe(
      (payload: OtpAddDeviceResponse) => {
        dispatch({
          payload,
          type: SET_OTP_DEVICE,
        });
      },
      (errorPayload) => dispatch({ error: true, payload: errorPayload, type: SET_OTP_DEVICE_FAILED })
    );
  };

export const changePassword =
  (changePasswordRequest: ChangePasswordRequest) => async (dispatch: Dispatch<IAction<PasswordChangeResponse>>) => {
    dispatch({
      type: PASSWORD_CHANGE_PENDING,
    });

    api.changePassword(changePasswordRequest).subscribe(
      (payload: PasswordChangeResponse) => {
        dispatch({
          payload,
          type: PASSWORD_CHANGE,
        });
      },
      (errorPayload) => dispatch(errorAction(PASSWORD_CHANGE_FAILED, errorPayload))
    );
  };

export const ssoAreaRiservataPrecedente =
  () => async (dispatch: Dispatch<IAction<SsoAreaRiservataPrecedenteResponse>>) => {
    dispatch({
      type: SSO_PENDING,
    });
    api.sso().subscribe(
      (value) => {
        dispatch({
          payload: value,
          type: SSO,
        });
      },
      (errorPayload) => dispatch(errorAction(SSO_FAILED, errorPayload))
    );
  };

export const authorize =
  (authorizeRequest: AuthorizeRequest) => async (dispatch: Dispatch<IAction<AuthorizationResponse>>) => {
    dispatch({
      type: AUTHORIZE_PENDING,
    });

    api.authorize(authorizeRequest).subscribe(
      (value) => {
        dispatch({
          payload: value,
          type: AUTHORIZE,
        });
      },
      (errorPayload) => dispatch(errorAction(AUTHORIZE_FAILED, errorPayload))
    );
  };
