import * as React from "react";

const SVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 70 42"
    className={`svg-icon icon-${props.name} ${props.className || ""}`}
    width={props.width || "1em"}
    height={props.height || "1em"}
    strokeWidth={props.strokeWidth || "1.5"}
  >
    <g fill="none" stroke="currentColor"  strokeLinecap="round" strokeLinejoin="round">
      <path d="M7.5 25.7H3.1c-.8 0-1.5-.7-1.5-1.5v-6c0-.8.7-1.5 1.5-1.5h4.4M45.5 25.7H25.1M63.1 16.7h4.4c.8 0 1.5.7 1.5 1.5v6c0 .8-.7 1.5-1.5 1.5h-4.4M45.5 16.7H25.1M11.9 31.7c-2.4 0-4.4-2-4.4-4.5v-12c0-2.5 2-4.5 4.4-4.5s4.4 2 4.4 4.5v12c0 2.5-2 4.5-4.4 4.5zM58.7 31.7c-2.4 0-4.4-2-4.4-4.5v-12c0-2.5 2-4.5 4.4-4.5 2.4 0 4.4 2 4.4 4.5v12c0 2.5-2 4.5-4.4 4.5zM49.9 40.7c-2.4 0-4.4-2-4.4-4.5v-30c0-2.5 2-4.5 4.4-4.5s4.4 2 4.4 4.5v30c0 2.5-2 4.5-4.4 4.5z" />
      <path d="M20.7 40.7c-2.4 0-4.4-2-4.4-4.5v-30c0-2.5 2-4.5 4.4-4.5s4.4 2 4.4 4.5v30c0 2.5-2 4.5-4.4 4.5z" />
    </g>
  </svg>
);

export default SVG;
