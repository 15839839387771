import * as React from "react";

const SVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 70 67"
    className={`svg-icon icon-${props.name} ${props.className || ""}`}
    width={props.width || "1em"}
    height={props.height || "1em"}
    strokeWidth={props.strokeWidth || "1.5"}
  >
    <path
      fill="none"
      stroke="currentColor"
      
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M43.4 48.3v17.8h19.4V30.6h5.8L57 20.3V9.9h-8.8v2.7L35.1 1 1.6 30.6h5.8v35.5h19.5V48.3z"
    />
  </svg>
);

export default SVG;
