/*
 * Import all languages from react-intl here
 * Ensure that `./locales.json` is aligned
 */

// import en from "react-intl/locale-data/en";
import it from "react-intl/locale-data/it";

export default {
  // en,
  it,
};
