import classNames from "classnames";
import { Form, Formik, FormikProps } from "formik";
import get from "lodash.get";
import React, { useEffect, useRef, useState } from "react";
import { defineMessages, FormattedHTMLMessage, FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import * as Yup from "yup";
import { LoginResponse, OtpVerificationResponse, OtpVerificationResponseActionEnum, Policies } from "../../generated";
import { OtpVerificationRequest } from "../../generated/models/OtpVerificationRequest";
import { getPolicies, otpResend, otpValidate, resetResponseCode } from "../../logic/authentication/actions";
import {
  ERROR_REQUEST_BLOCKED_OTP,
  OTP_RESEND_RESET_RESPONSE,
  OTP_VERIFICATION_RESET_RESPONSE,
} from "../../logic/authentication/consts";
import { IBasicRequest, IState } from "../../logic/authentication/types";
import { IStoreState } from "../../types/store";
import FormAuth from "../shared/form_auth/form_auth";
import ModalRegisterNumber from "../shared/modals/modal_register_number";
import ModalServerError from "../shared/modals/modal_server_error";
import ModalShowUsername from "../shared/modals/modal_show_username";
import ModalUserBlocked from "../shared/modals/modal_user_blocked";
import Spinner from "../shared/spinner/spinner";
import Button, { ButtonColors } from "../ui_kit/button/button";
import { InputText } from "../ui_kit/form/input_text";
import "./_form_otp.scss";

export interface IDispatchProps {
  getPolicies: () => void;
  otpResend: () => void;
  otpValidate: (otpVerificationRequest: OtpVerificationRequest) => void;
  resetResponseCode: (action: string) => void;
}
export interface IStateProps {
  otpVerificationResponse: IBasicRequest & OtpVerificationResponse;
  policies: IBasicRequest & Policies;
  recoverUsernameResponse: IBasicRequest & LoginResponse;
}

const translations = defineMessages({
  required: {
    defaultMessage: "Campo obbligatorio",
    description: "campo obbligatorio",
    id: "generic.required",
  },
});

// type Props = IStateProps & IDispatchProps & IOwnProps & RouteComponentProps;
type Props = IStateProps & IDispatchProps & RouteComponentProps;

const initialState = {
  otp: "",
};

const getValidationSchema = (t: any) => {
  return Yup.object().shape({
    otp: Yup.string().required(t(translations.required)),
  });
};

const FormOtp: React.FunctionComponent<Props> = ({
  getPolicies,
  location,
  otpResend,
  otpValidate,
  otpVerificationResponse,
  policies,
  recoverUsernameResponse,
  resetResponseCode,
  intl: { formatMessage: t },
}) => {
  const [enableResendOTP, setEnableResendOTP] = useState(false);
  const [otpResent, setOtpResent] = useState(false);
  let OTPTimeout: number;
  const formikRef = useRef();

  useEffect(() => {
    OTPTimeout = window.setTimeout(() => {
      setEnableResendOTP(true);
    }, 10000);
    return () => {
      clearTimeout(OTPTimeout);
    };
  }, []);

  useEffect(() => {
    getPolicies();
    return () => {
      resetResponseCode(OTP_VERIFICATION_RESET_RESPONSE);
      resetResponseCode(OTP_RESEND_RESET_RESPONSE);
    };
  }, []);

  const clearOtpField = () => {
    formikRef.current.setFieldValue("otp", "");
    resetResponseCode(OTP_VERIFICATION_RESET_RESPONSE);
    resetResponseCode(OTP_RESEND_RESET_RESPONSE);
  };

  const handleResendOTP = () => {
    setOtpResent(true);
    otpResend();
    setEnableResendOTP(false);

    clearOtpField();

    OTPTimeout = window.setTimeout(() => {
      setEnableResendOTP(true);
    }, 10000);
  };

  const formikSubmit = (values: any, actions: any) => {
    otpValidate(values);
    resetResponseCode(OTP_VERIFICATION_RESET_RESPONSE);
    clearOtpField();
  };

  // const hideMobileNumber = (mobileNumber: string) => {
  //   if (mobileNumber) {
  //     return mobileNumber.length >= 3
  //       ? padStart(mobileNumber.substring(mobileNumber.length - 3, mobileNumber.length), mobileNumber.length, "*")
  //       : mobileNumber;
  //   } else {
  //     return "";
  //   }
  // };

  const actionTelefonoSmarrito =
    get(location.state, "actionTelefonoSmarrito") !== undefined ? get(location.state, "actionTelefonoSmarrito") : true;
  const showRecoverUsername =
    get(location.state, "showRecoverUsername") !== undefined ? get(location.state, "showRecoverUsername") : false;

  const showRecoverUsernameModal =
    showRecoverUsername &&
    get(otpVerificationResponse, "error.status") === 200 &&
    get(otpVerificationResponse, "action") !== OtpVerificationResponseActionEnum.CHANGEPASSWORD;

  if (get(otpVerificationResponse, "error.status") === 200) {
    if (!otpVerificationResponse.action) {
      return <Redirect to="/server-error" />;
    }
    if (get(otpVerificationResponse, "action") === OtpVerificationResponseActionEnum.CHANGEPASSWORD) {
      return <Redirect to="/users/change-password" />;
    } else if (get(otpVerificationResponse, "action") === OtpVerificationResponseActionEnum.REDIRECTTOOLDARAPP) {
      return <Redirect to="/old-application" />;
    } else if (get(otpVerificationResponse, "action") === OtpVerificationResponseActionEnum.REDIRECTTOAPP) {
      if (!showRecoverUsernameModal) {
        return <Redirect to="/application" />;
      }
    } else if (get(otpVerificationResponse, "action") === OtpVerificationResponseActionEnum.REDIRECTTOLOGIN) {
      if (!showRecoverUsernameModal) {
        return <Redirect to="/auth/login" />;
      }
    } else {
      return <div>Error</div>;
    }
  } else if (get(otpVerificationResponse, "error.status") === 401) {
    // token non valido, redirect a login
    return <Redirect to="/auth/login" />;
  }

  const closeModalHandler = () => {
    resetResponseCode(OTP_VERIFICATION_RESET_RESPONSE);
  };

  return (
    <FormAuth
      pageContainerClass="otp-container"
      actionsClass=""
      formContentClass=""
      title={<FormattedHTMLMessage id="form_otp.title" defaultMessage="Inserisci codice di conferma" />}
      intro={
        <FormattedMessage
          id="form_otp.intro"
          defaultMessage="Riporta di seguito il codice che hai ricevuto tramite SMS."
        />
      }
      showRequired={true}
      content={
        <Formik
          ref={formikRef}
          initialValues={initialState}
          validationSchema={getValidationSchema(t)}
          onSubmit={formikSubmit}
        >
          {(formikProps: FormikProps<any>) => (
            <Form>
              <InputText
                label={<FormattedMessage id="form_otp.otp" defaultMessage="Codice di conferma*" />}
                name="otp"
              />
              {!enableResendOTP && otpResent && (
                <div className="form-error">
                  <FormattedMessage
                    id="form_otp.new_otp_sent"
                    defaultMessage="Un nuovo codice è stato inviato tramite SMS."
                  />
                </div>
              )}
              {get(otpVerificationResponse, "error.status") === 400 && (
                <div className="form-error">{get(otpVerificationResponse, "error.response.description", "Errore")}</div>
              )}
              {otpVerificationResponse.loading ? (
                <Spinner />
              ) : (
                <Button data-cy="button-submit" disabled={!formikProps.isValid} inverted={false} type="submit">
                  <FormattedMessage id="form_otp.access" defaultMessage="Accedi" />
                  {/* <Icon name="arrow-right" className="icon-blue" strokeWidth="2" /> */}
                </Button>
              )}
            </Form>
          )}
        </Formik>
      }
      actions={
        <>
          <a
            data-cy="re-send-otp-button"
            onClick={handleResendOTP}
            className={classNames({ "richiedi-codice": true, disabled: !enableResendOTP })}
          >
            <FormattedMessage id="form_otp.request_otp" defaultMessage="Non ricevi il codice? Invia nuovo codice" />
          </a>
          {actionTelefonoSmarrito && (
            <a className="change-number modal-trigger" data-target="register-number-modal">
              <FormattedMessage
                id="form_otp.change_number"
                defaultMessage="Vuoi inserire/modificare il cellulare? Chiedi a un esperto."
              />
            </a>
          )}
        </>
      }
      modals={
        <>
          {/* {get(otpVerificationResponse, "error.status") === 403 && (
            <ModalUserDisabled />
          )} */}
          {get(otpVerificationResponse, "error.status") === 403 &&
            get(otpVerificationResponse, "error.response.code") === ERROR_REQUEST_BLOCKED_OTP && (
              <ModalUserBlocked onCloseEnd={closeModalHandler} />
            )}

          <ModalRegisterNumber context="otp" autoOpen={false} />
          {showRecoverUsernameModal && (
            <ModalShowUsername username={get(recoverUsernameResponse, "username")} onCloseEnd={closeModalHandler} />
          )}
          {get(otpVerificationResponse, "error.status") >= 500 && <ModalServerError onCloseEnd={closeModalHandler} />}
        </>
      }
    />
  );
};

const mapStateToProps = (state: IStoreState): IState => ({
  otpVerificationResponse: state.authentication.otpVerificationResponse,
  policies: state.authentication.policies,
  recoverUsernameResponse: state.authentication.recoverUsernameResponse,
});

const mapDispatchToProps: IDispatchProps = {
  getPolicies,
  otpResend,
  otpValidate,
  resetResponseCode,
};

const withIntlComponent = injectIntl(FormOtp);

export default React.memo(
  // connect<IStateProps, IDispatchProps, IOwnProps & RouteComponentProps>(
  connect<IStateProps, IDispatchProps & RouteComponentProps>(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(withIntlComponent))
);
