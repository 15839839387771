import * as React from "react";

const SVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 10 6"
    className={`svg-icon icon-${props.name} ${props.className || ""}`}
    width={props.width || "1em"}
    height={props.height || "1em"}
    strokeWidth={props.strokeWidth || "1.5"}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="currentColor"
      d="M9.2 4L5.7.5C5.6.4 5.5.3 5.4.3 5 .2 4.6.2 4.3.6L.8 4.1c-.4.4-.4 1 0 1.4.4.4 1 .4 1.4 0L5 2.6l2.9 2.9c.4.4 1 .4 1.4 0 .3-.4.3-1-.1-1.5z"
    />
  </svg>
);

export default SVG;
