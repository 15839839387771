import * as React from "react";

const SVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 70 66"
    className={`svg-icon icon-${props.name} ${props.className || ""}`}
    width={props.width || "1em"}
    height={props.height || "1em"}
    strokeWidth={props.strokeWidth || "1.5"}
  >
    <g fill="none" stroke="currentColor"  strokeLinecap="round" strokeLinejoin="round">
      <path d="M35 64.4c-.2 0-.5-.1-.7-.2-.3-.2-8.4-4.8-16.6-12.6-4.8-4.6-8.6-9.3-11.4-14.1C2.8 31.5 1 25.4 1 19.4 1 9.3 9.2 1 19.2 1c3.8 0 7.5 1.2 10.5 3.4 2.2 1.5 3.9 3.5 5.2 5.8C36.2 7.9 38 6 40.1 4.4 43.3 2.2 46.9 1 50.7 1 60.8 1 69 9.3 69 19.4c0 6-1.8 12.1-5.3 18.1-2.8 4.8-6.6 9.5-11.4 14.1C44.1 59.4 36 64 35.7 64.2c-.2.2-.5.2-.7.2" />
      <path d="M57.1 30H41l-6.6 16.9h.1l-7.2-26-3.6 9.2h-12" />
    </g>
  </svg>
);

export default SVG;
