import * as React from "react";

const SVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 70 70"
    className={`svg-icon icon-${props.name} ${props.className || ""}`}
    width={props.width || "1em"}
    height={props.height || "1em"}
    strokeWidth={props.strokeWidth || "1.5"}
  >
    <g fill="none" stroke="currentColor"  strokeLinecap="round" strokeLinejoin="round">
      <path d="M49.6 5l3.3 11.7 11.9 3.4h0M28.5 10.5L16.6 7.2M28.5 10.5l3.4 11.9M28.5 10.5l8.6-8.6M43.8 25.8l9.1-9.1M68.2 32l-9.6 9.5M22.7 31.5l-11.9-3.3-3.4-12M31.4 22.9l11.9 3.4 3.4 11.9M22.7 31.5l3.4 11.9 12 3.4M22.7 31.5l8.6-8.6M38.1 46.8l3.3 11.9 12.8 3.4M46.7 38.2l11.9 3.3 3.9 12.4M38.1 46.8l8.6-8.6M1.7 37.2l9.1-9.1M17 52.5l8.6-8.6M40.9 59.2l-8.6 8.6M5.1 49.8L16.5 53l3.3 11.7" />
      <path d="M68.4 35c0 18.4-14.9 33.4-33.4 33.4S1.6 53.4 1.6 35 16.6 1.6 35 1.6s33.4 15 33.4 33.4z" />
    </g>
  </svg>
);

export default SVG;
