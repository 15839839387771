import get from "lodash.get";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import { SsoAreaRiservataPrecedenteResponse } from "../../generated";
import { ssoAreaRiservataPrecedente } from "../../logic/authentication/actions";
import { IBasicRequest, IState } from "../../logic/authentication/types";
import { IStoreState } from "../../types/store";
import Spinner from "../shared/spinner/spinner";
export interface IDispatchProps {
  ssoAreaRiservataPrecedente: () => void;
}
export interface IStateProps {
  ssoAreaRiservataPrecedenteResponse: IBasicRequest & SsoAreaRiservataPrecedenteResponse;
}

const DummyOldApplication: React.FunctionComponent<IDispatchProps & IStateProps> = ({
  ssoAreaRiservataPrecedente,
  ssoAreaRiservataPrecedenteResponse,
}) => {
  useEffect(() => {
    ssoAreaRiservataPrecedente();
  }, []);

  if (get(ssoAreaRiservataPrecedenteResponse, "error.status") === 200) {
    if (!ssoAreaRiservataPrecedenteResponse.redirectURI) {
      return <Redirect to="/server-error" />;
    }
    // Redirect to authorized Application
    window.location.replace(get(ssoAreaRiservataPrecedenteResponse, "redirectURI"));
  }

  return <div>{ssoAreaRiservataPrecedenteResponse.loading && <Spinner />}</div>;
};

const mapStateToProps = (state: IStoreState): IState => ({
  ssoAreaRiservataPrecedenteResponse: state.authentication.ssoAreaRiservataPrecedenteResponse,
});

const mapDispatchToProps: IDispatchProps = {
  ssoAreaRiservataPrecedente,
};

export default React.memo(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(DummyOldApplication)
);
