import React from "react";
import { FormattedHTMLMessage, FormattedMessage } from "react-intl";
import { CHIEDI_ESPERTO_URL } from "../../../config/constants";
import Modal from "../../ui_kit/modal/modal";
import Section from "../../ui_kit/section/section";

export interface IOwnProps {
  context: string;
  autoOpen?: boolean;
  dismissible?: boolean;
  onCloseEnd?: (modal: Modal, element: Element) => void;
}
type Props = IOwnProps;

const ModalRegisterNumber: React.FunctionComponent<Props> = ({
  context,
  autoOpen = false,
  dismissible = true,
  onCloseEnd,
}) => {
  const closeEndHandler = (modal: Modal, element: Element) => {
    if (onCloseEnd) {
      onCloseEnd(modal, element);
    }
  };
  let idDescr = <></>;

  switch (context) {
    case "otp":
      idDescr = (
        <FormattedHTMLMessage
          id="modal_register_number.otp"
          defaultMessage='Per registrare o modificare il numero di cellulare utilizza il form "Chiedi a un esperto", seleziona il prodotto (es. Prestito personale/finalizzato) e il tipo di richiesta "Modifica/inserimento recapito cellulare/email". Sarai avvisato da un nostro operatore quando la modifica sarà effettuata, entro 2 giorni lavorativi, e potrai associare il nuovo numero al tuo account.'
        />
      );
      break;
    case "recover_password":
      idDescr = (
        <FormattedHTMLMessage
          id="modal_register_number.recover_password"
          defaultMessage='Per inserire il numero nella tua anagrafica utilizza il form "Chiedi a un esperto", seleziona il prodotto (es. Prestito personale/finalizzato) e il tipo di richiesta "Modifica/inserimento recapito cellulare/email". Sarai avvisato da un nostro operatore quando la modifica sarà effettuata, entro 2 giorni lavorativi, e potrai associare il nuovo numero al tuo account.'
        />
      );
      break;
    case "recover_username":
      idDescr = (
        <FormattedHTMLMessage
          id="modal_register_number.recover_username"
          defaultMessage='Per recuperare il tuo username è necessario fornire un numero di cellulare. Per inserire il numero nella tua anagrafica utilizza il form "Chiedi a un esperto", seleziona il prodotto (es. Prestito personale/finalizzato) e il tipo di richiesta "Modifica/inserimento recapito cellulare/email". Sarai avvisato da un nostro operatore quando la modifica sarà effettuata, entro 2 giorni lavorativi, e potrai associare il nuovo numero al tuo account.'
        />
      );
      break;
    case "form_user":
      idDescr = (
        <FormattedHTMLMessage
          id="modal_register_number.form_user"
          defaultMessage='Per inserire il numero nella tua anagrafica utilizza il form "Chiedi a un esperto", seleziona il prodotto (es. Prestito personale/finalizzato) e il tipo di richiesta "Modifica/inserimento recapito cellulare/email". Sarai avvisato da un nostro operatore quando la modifica sarà effettuata, entro 2 giorni lavorativi, e potrai associare il nuovo numero al tuo account.'
        />
      );
      break;
  }
  return (
    <Modal id="register-number-modal" autoOpen={autoOpen} dismissible={dismissible} onCloseEnd={closeEndHandler}>
      <Section className="center">
        <Section.Title>
          <FormattedMessage id="modal_register_number.title" defaultMessage="Registra numero cellulare" />
        </Section.Title>
        <Section.Content>
          {idDescr}
          <div className="modal-actions center">
            <a target="_blank" href={CHIEDI_ESPERTO_URL} className="btn modal-action">
              <FormattedMessage id="modal_register_number.ask_to_expert" defaultMessage="Chiedi a un esperto" />
            </a>
          </div>
        </Section.Content>
      </Section>
    </Modal>
  );
};

export default React.memo(ModalRegisterNumber);
