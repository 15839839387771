import * as React from "react";

const SVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 70 69"
    className={`svg-icon icon-${props.name} ${props.className || ""}`}
    width={props.width || "1em"}
    height={props.height || "1em"}
    strokeWidth={props.strokeWidth || "1.5"}
  >
    <g fill="none" stroke="currentColor"  strokeLinecap="round" strokeLinejoin="round">
      <path d="M18.2 67.9V1.6h33.6v66.3" />
      <path d="M24.3 13.7v-3h3.1M33.5 13.7v-3h3M42.6 13.7v-3h3.1M24.3 22.7v-3h3.1M33.5 22.7v-3h3M42.6 22.7v-3h3.1M24.3 31.8v-3h3.1M33.5 31.8v-3h3M42.6 31.8v-3h3.1M24.3 40.8v-3h3.1M33.5 40.8v-3h3M42.6 40.8v-3h3.1M24.3 49.8v-3h3.1M33.5 49.8v-3h3M42.6 49.8v-3h3.1M24.3 58.9v-3h3.1M33.5 58.9v-3h3M42.6 58.9v-3h3.1M9.1 58.9v-3h3M9.1 49.8v-3h3M9.1 40.8v-3h3M57.9 58.9v-3h3M57.9 49.8v-3h3M57.9 40.8v-3h3" />
      <path d="M3 67.9V28.8h15.2M51.8 28.8H67v39.1M1 68.1h68" />
    </g>
  </svg>
);

export default SVG;
