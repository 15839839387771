import React from "react";
import { FormattedHTMLMessage, FormattedMessage } from "react-intl";
import { ButtonColors } from "../../ui_kit/button/button";
import LinkButton from "../../ui_kit/button/link_button";
import Modal from "../../ui_kit/modal/modal";
import Section from "../../ui_kit/section/section";

export interface IOwnProps {
  username: string | undefined;
  onCloseEnd?: (modal: Modal, element: Element) => void;
}

type Props = IOwnProps;

const ModalShowUsername: React.FunctionComponent<Props> = ({ username, onCloseEnd }) => {
  const closeEndHandler = (modal: Modal, element: Element) => {
    if (onCloseEnd) {
      onCloseEnd(modal, element);
    }
  };
  return (
    <Modal id="mostra-username-modal" autoOpen={true} dismissible={false} onCloseEnd={closeEndHandler}>
      <Section className="center">
        <Section.Title>
          <FormattedMessage id="modal_show_username.title" defaultMessage="Smarrimento username" />
        </Section.Title>
        <Section.Content>
          <FormattedHTMLMessage
            id="modal_show_username.your_username"
            defaultMessage="Il tuo username é: </br> {username}"
            values={{
              username,
            }}
          />
          <div className="modal-actions center">
            <LinkButton
              color={ButtonColors.Red}
              inverted={true}
              to="/auth/login"
              data-cy="to-application"
              className="modal-action"
            >
              <FormattedMessage id="modal_show_username.continue" defaultMessage="Continua" />
            </LinkButton>
          </div>
        </Section.Content>
      </Section>
    </Modal>
  );
};

export default React.memo(ModalShowUsername);
