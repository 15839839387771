import { LocationDescriptorObject, LocationState, Path } from "history";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import Button, { IProps as IButtonProps } from "./button";

interface IProps<HistoryLocationState = LocationState> extends RouteComponentProps, IButtonProps {
  children: React.ReactNode;
  to: Path | LocationDescriptorObject<HistoryLocationState>;
}

const LinkButton = (props: IProps) => {
  const { history, location, match, staticContext, onClick, to, ...rest } = props;

  const clickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (onClick) {
      onClick(event);
    }

    // typescript compliant signature
    typeof to === "string" ? history.push(to, undefined) : history.push(to);
  };

  return <Button {...rest} onClick={clickHandler} />;
};

export default withRouter(LinkButton);
