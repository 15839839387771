// tslint:disable
/**
 * TIMFIN Authentication
 * TIMFIN Authentication API.
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface UserRegistrationRequest
 */
export interface UserRegistrationRequest {
    /**
     * username
     * @type {string}
     * @memberof UserRegistrationRequest
     */
    username: string;
    /**
     * @type {string}
     * @memberof UserRegistrationRequest
     */
    password: string;
    /**
     * @type {string}
     * @memberof UserRegistrationRequest
     */
    codiceFiscale: string;
    /**
     * @type {string}
     * @memberof UserRegistrationRequest
     */
    cellulare: string;
    /**
     * @type {string}
     * @memberof UserRegistrationRequest
     */
    recaptchaToken: string;
    /**
     * @type {string}
     * @memberof UserRegistrationRequest
     */
    numeroContratto: string;
}
