import { Form, Formik, FormikProps } from "formik";
import get from "lodash.get";
import React, { useEffect } from "react";
import { defineMessages, FormattedHTMLMessage, FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import * as Yup from "yup";
import { ChangePasswordRequest, LoginResponse, PasswordChangeResponse, Policies } from "../../generated";
import { changePassword, getPolicies, resetResponseCode } from "../../logic/authentication/actions";
import { GET_POLICIES_RESET_RESPONSE, PASSWORD_CHANGE_RESET_RESPONSE } from "../../logic/authentication/consts";
import { IBasicRequest, IState } from "../../logic/authentication/types";
import { IStoreState } from "../../types/store";
import FormAuth from "../shared/form_auth/form_auth";
import ModalServerError from "../shared/modals/modal_server_error";
import Spinner from "../shared/spinner/spinner";
import Button, { ButtonColors } from "../ui_kit/button/button";
import { InputPassword } from "../ui_kit/form/input_password";
import "./_form_change_password.scss";

export interface IDispatchProps {
  changePassword: (changePasswordRequest: ChangePasswordRequest) => void;
  getPolicies: () => void;
  resetResponseCode: (action: string) => void;
}
export interface IStateProps {
  loginResponse: IBasicRequest & LoginResponse;
  passwordChangeResponse: IBasicRequest & PasswordChangeResponse;
  policies: IBasicRequest & Policies;
}

const translations = defineMessages({
  passwordsMustMatch: {
    defaultMessage: "Le password devono corrispondere",
    description: "Le password devono corrispondere",
    id: "generic.passwordsMustMatch",
  },
  required: {
    defaultMessage: "Campo obbligatorio",
    description: "campo obbligatorio",
    id: "generic.required",
  },
});

const initialState = {
  newPassword: "",
  newPasswordConfirmation: "",
};

type Props = IStateProps & IDispatchProps;

const FormChangePassword: React.FunctionComponent<Props> = ({
  changePassword,
  loginResponse,
  passwordChangeResponse,
  getPolicies,
  policies,
  resetResponseCode,
  intl: { formatMessage: t },
}) => {
  useEffect(() => {
    getPolicies();
    return () => {
      resetResponseCode(GET_POLICIES_RESET_RESPONSE);
      resetResponseCode(PASSWORD_CHANGE_RESET_RESPONSE);
    };
  }, []);

  const regexString = get(policies, "password.regex");
  const regex = new RegExp(regexString);

  const getValidationSchema = (t: any) => {
    return Yup.object().shape({
      newPassword: Yup.string()
        .matches(regex, { message: get(policies, "password.description"), excludeEmptyString: true })
        .required(t(translations.required)),
      newPasswordConfirmation: Yup.string()
        .required(t(translations.required))
        .oneOf([Yup.ref("newPassword"), null], t(translations.passwordsMustMatch)),
    });
  };

  const formikSubmit = (values: any, actions: any) => {
    const data = { passwordChangeRequest: values };
    changePassword(data);
  };

  if (get(passwordChangeResponse, "error.status") === 200) {
    // TODO: handle a 200 response with content-type text/html by redirecting
    // to /server-error.
    return <Redirect to="/application" />;
    // window.location.replace(passwordChangeResponse.applicationUrl!);
  } else if (get(passwordChangeResponse, "error.status") === 401) {
    // token non valido, redirect a login
    return <Redirect to="/auth/login" />;
  }

  const closeModalHandler = () => {
    resetResponseCode(GET_POLICIES_RESET_RESPONSE);
    resetResponseCode(PASSWORD_CHANGE_RESET_RESPONSE);
  };

  return (
    <FormAuth
      pageContainerClass="change-password-container"
      actionsClass=""
      formContentClass=""
      title={<FormattedMessage id="form_change_password.title" defaultMessage="Cambio password" />}
      intro={
        <div>
          <FormattedHTMLMessage
            id="form_change_password.intro"
            defaultMessage="Prima di procedere ti richiediamo di <strong>cambiare la tua password.</strong>"
          />
          <br />
          <br />
          <div>{get(policies, "password.description") && policies.password.description}</div>
        </div>
      }
      showRequired={true}
      content={
        <Formik initialValues={initialState} validationSchema={getValidationSchema(t)} onSubmit={formikSubmit}>
          {(formikProps: FormikProps<any>) => (
            <Form>
              <InputPassword
                label={<FormattedMessage id="form_change_password.new_password" defaultMessage="Nuova password*" />}
                name="newPassword"
                fieldClassName="validate-password"
              />
              <div className="input-field">
                <InputPassword
                  label={
                    <FormattedMessage
                      id="form_change_password.new_password_confirmation"
                      defaultMessage="Conferma password*"
                    />
                  }
                  name="newPasswordConfirmation"
                />
              </div>

              {get(passwordChangeResponse, "error.status") === 400 && (
                <div className="form-error">{get(loginResponse, "error.response.description")}</div>
              )}
              {passwordChangeResponse.loading ? (
                <Spinner />
              ) : (
                <Button data-cy="button-submit" disabled={!formikProps.isValid} inverted={false} type="submit">
                  <FormattedMessage id="form_change_password.confirm" defaultMessage="Conferma" />
                  {/* <Icon name="arrow-right" className="icon-blue" strokeWidth="2" /> */}
                </Button>
              )}
            </Form>
          )}
        </Formik>
      }
      modals={
        <>{get(passwordChangeResponse, "error.status") >= 500 && <ModalServerError onCloseEnd={closeModalHandler} />}</>
      }
    />
  );
};

const mapStateToProps = (state: IStoreState): IState => ({
  loginResponse: state.authentication.loginResponse,
  passwordChangeResponse: state.authentication.passwordChangeResponse,
  policies: state.authentication.policies,
});

const mapDispatchToProps: IDispatchProps = {
  changePassword,
  getPolicies,
  resetResponseCode,
};

const withIntlComponent = injectIntl(FormChangePassword);

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(withIntlComponent));
