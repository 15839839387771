import produce from "immer";
import { LoginResponseOtpDeviceStateEnum, OtpVerificationResponseActionEnum } from "../../generated";
import {
  AUTHORIZE,
  AUTHORIZE_FAILED,
  AUTHORIZE_PENDING,
  CLEAR_TOKEN,
  GET_POLICIES,
  GET_POLICIES_FAILED,
  GET_POLICIES_PENDING,
  GET_POLICIES_RESET_RESPONSE,
  LOGIN,
  LOGIN_FAILED,
  LOGIN_PENDING,
  LOGIN_RESET_RESPONSE,
  OTP_VERIFICATION,
  OTP_VERIFICATION_FAILED,
  OTP_VERIFICATION_PENDING,
  OTP_VERIFICATION_RESET_RESPONSE,
  PASSWORD_CHANGE,
  PASSWORD_CHANGE_FAILED,
  PASSWORD_CHANGE_PENDING,
  PASSWORD_CHANGE_RESET_RESPONSE,
  RE_ENABLE_USER,
  RE_ENABLE_USER_FAILED,
  RE_ENABLE_USER_PENDING,
  RE_ENABLE_USER_RESET_RESPONSE,
  RECOVER_PASSWORD,
  RECOVER_PASSWORD_FAILED,
  RECOVER_PASSWORD_PENDING,
  RECOVER_PASSWORD_RESET_RESPONSE,
  RECOVER_USERNAME,
  RECOVER_USERNAME_FAILED,
  RECOVER_USERNAME_PENDING,
  RECOVER_USERNAME_RESET_RESPONSE,
  REGISTER_USER,
  REGISTER_USER_FAILED,
  REGISTER_USER_PENDING,
  REGISTER_USER_RESET_RESPONSE,
  SET_OTP_DEVICE,
  SET_OTP_DEVICE_FAILED,
  SET_OTP_DEVICE_PENDING,
  SET_OTP_DEVICE_RESET_RESPONSE,
  SET_TOKEN,
  SSO,
  SSO_FAILED,
  SSO_PENDING,
} from "./consts";
import { IState } from "./types";

const initialState: IState = {
  authorizationResponse: {
    error: undefined,
    loading: false,
    redirectUri: "",
  },
  jwt: "",
  loginResponse: {
    error: undefined,
    loading: false,
    mobileNumber: "",
    mobileNumberDifferent: false,
    ndg: "",
    otpDeviceState: LoginResponseOtpDeviceStateEnum.MISSING,
    username: "",
  },
  otpAddDeviceResponse: {
    mobileNumberDifferent: false,
  },
  otpDevice: {
    error: undefined,
    loading: false,
  },
  otpVerificationResponse: {
    action: OtpVerificationResponseActionEnum.CHANGEPASSWORD,
    error: undefined,
    loading: false,
  },
  passwordChangeResponse: {
    applicationUrl: "",
    error: undefined,
    loading: false,
  },
  policies: {
    error: undefined,
    loading: false,
    password: {
      description: "",
      regex: "",
    },
    username: {
      description: "",
      regex: "",
    },
  },
  reEnableUserResponse: {
    error: undefined,
    loading: false,
    mobileNumber: "",
    mobileNumberDifferent: false,
    ndg: "",
    otpDeviceState: LoginResponseOtpDeviceStateEnum.MISSING,
    username: "",
  },
  recoverPasswordResponse: {
    error: undefined,
    loading: false,
    mobileNumber: "",
    mobileNumberDifferent: false,
    ndg: "",
    otpDeviceState: LoginResponseOtpDeviceStateEnum.MISSING,
    username: "",
  },
  recoverUsernameResponse: {
    error: undefined,
    loading: false,
    mobileNumber: "",
    mobileNumberDifferent: false,
    ndg: "",
    otpDeviceState: LoginResponseOtpDeviceStateEnum.MISSING,
    username: "",
  },
  registerUserResponse: {
    error: undefined,
    loading: false,
    mobileNumber: "",
    mobileNumberDifferent: false,
    ndg: "",
    otpDeviceState: LoginResponseOtpDeviceStateEnum.MISSING,
    username: "",
  },
  ssoAreaRiservataPrecedenteResponse: {
    error: undefined,
    loading: false,
    redirectURI: "",
  },
  userSessionId: "",
};

const successful = {
  error: {
    status: 200,
  },
  loading: false,
};

export default produce((draft: IState, action) => {
  switch (action.type) {
    case SET_TOKEN: {
      draft.jwt = action.payload.token;
      return;
    }

    case CLEAR_TOKEN: {
      draft.jwt = "";
      return;
    }

    case LOGIN: {
      draft.loginResponse = {
        ...action.payload,
        ...successful,
      };
      return;
    }

    case LOGIN_FAILED: {
      if (draft.loginResponse) {
        draft.loginResponse.loading = false;
        draft.loginResponse.error = action.payload;
      }
      return;
    }

    case LOGIN_PENDING: {
      if (draft.loginResponse) {
        draft.loginResponse.loading = true;
      }
      return;
    }

    case LOGIN_RESET_RESPONSE: {
      if (draft.loginResponse) {
        draft.loginResponse.error = undefined;
      }
      return;
    }

    case OTP_VERIFICATION: {
      draft.otpVerificationResponse = {
        ...action.payload,
        ...successful,
      };
      return;
    }

    case OTP_VERIFICATION_FAILED: {
      if (draft.otpVerificationResponse) {
        draft.otpVerificationResponse.loading = false;
        draft.otpVerificationResponse.error = action.payload;
      }
      return;
    }

    case OTP_VERIFICATION_PENDING: {
      if (draft.otpVerificationResponse) {
        draft.otpVerificationResponse.loading = true;
      }
      return;
    }

    case OTP_VERIFICATION_RESET_RESPONSE: {
      if (draft.otpVerificationResponse) {
        draft.otpVerificationResponse.error = undefined;
      }
      return;
    }

    case GET_POLICIES: {
      draft.policies = {
        ...action.payload,
        ...successful,
      };
      return;
    }

    case GET_POLICIES_FAILED: {
      if (draft.policies) {
        draft.policies.loading = false;
        draft.policies.error = action.payload;
      }
      return;
    }

    case GET_POLICIES_PENDING: {
      if (draft.policies) {
        draft.policies.loading = true;
      }
      return;
    }

    case GET_POLICIES_RESET_RESPONSE: {
      if (draft.policies) {
        draft.policies.error = undefined;
      }
      return;
    }

    case REGISTER_USER: {
      draft.registerUserResponse = {
        ...action.payload,
        ...successful,
      };
      return;
    }

    case REGISTER_USER_FAILED: {
      if (draft.registerUserResponse) {
        draft.registerUserResponse.loading = false;
        draft.registerUserResponse.error = action.payload;
      }
      return;
    }

    case REGISTER_USER_PENDING: {
      if (draft.registerUserResponse) {
        draft.registerUserResponse.loading = true;
      }
      return;
    }

    case REGISTER_USER_RESET_RESPONSE: {
      if (draft.registerUserResponse) {
        draft.registerUserResponse.error = undefined;
      }
      return;
    }

    case RECOVER_PASSWORD: {
      draft.recoverPasswordResponse = {
        ...action.payload,
        ...successful,
      };
      return;
    }

    case RECOVER_PASSWORD_FAILED: {
      if (draft.recoverPasswordResponse) {
        draft.recoverPasswordResponse.loading = false;
        draft.recoverPasswordResponse.error = action.payload;
      }
      return;
    }

    case RECOVER_PASSWORD_PENDING: {
      if (draft.recoverPasswordResponse) {
        draft.recoverPasswordResponse.loading = true;
      }
      return;
    }

    case RECOVER_PASSWORD_RESET_RESPONSE: {
      if (draft.recoverPasswordResponse) {
        draft.recoverPasswordResponse.error = undefined;
      }
      return;
    }

    case RECOVER_USERNAME: {
      draft.recoverUsernameResponse = {
        ...action.payload,
        ...successful,
      };
      return;
    }

    case RECOVER_USERNAME_FAILED: {
      if (draft.recoverUsernameResponse) {
        draft.recoverUsernameResponse.loading = false;
        draft.recoverUsernameResponse.error = action.payload;
      }
      return;
    }

    case RECOVER_USERNAME_PENDING: {
      if (draft.recoverUsernameResponse) {
        draft.recoverUsernameResponse.loading = true;
      }
      return;
    }

    case RECOVER_USERNAME_RESET_RESPONSE: {
      if (draft.recoverUsernameResponse) {
        draft.recoverUsernameResponse.error = undefined;
      }
      return;
    }

    case RE_ENABLE_USER: {
      draft.reEnableUserResponse = {
        ...action.payload,
        ...successful,
      };
      return;
    }

    case RE_ENABLE_USER_FAILED: {
      if (draft.reEnableUserResponse) {
        draft.reEnableUserResponse.loading = false;
        draft.reEnableUserResponse.error = action.payload;
      }
      return;
    }

    case RE_ENABLE_USER_PENDING: {
      if (draft.reEnableUserResponse) {
        draft.reEnableUserResponse.loading = true;
      }
      return;
    }

    case RE_ENABLE_USER_RESET_RESPONSE: {
      if (draft.reEnableUserResponse) {
        draft.reEnableUserResponse.error = undefined;
      }
      return;
    }

    case SET_OTP_DEVICE: {
      draft.otpDevice = {
        ...action.payload,
        ...successful,
      };
      return;
    }

    case SET_OTP_DEVICE_FAILED: {
      if (draft.otpDevice) {
        draft.otpDevice.loading = false;
        draft.otpDevice.error = action.payload;
      }
      return;
    }

    case SET_OTP_DEVICE_PENDING: {
      if (draft.otpDevice) {
        draft.otpDevice.loading = true;
      }
      return;
    }

    case SET_OTP_DEVICE_RESET_RESPONSE: {
      if (draft.otpDevice) {
        draft.otpDevice.error = undefined;
      }
      return;
    }

    case PASSWORD_CHANGE: {
      draft.passwordChangeResponse = {
        ...action.payload,
        ...successful,
      };
      return;
    }

    case PASSWORD_CHANGE_FAILED: {
      if (draft.passwordChangeResponse) {
        draft.passwordChangeResponse.loading = false;
        draft.passwordChangeResponse.error = action.payload;
      }
      return;
    }

    case PASSWORD_CHANGE_PENDING: {
      if (draft.passwordChangeResponse) {
        draft.passwordChangeResponse.loading = true;
      }
      return;
    }

    case PASSWORD_CHANGE_RESET_RESPONSE: {
      if (draft.passwordChangeResponse) {
        draft.passwordChangeResponse.error = undefined;
      }
      return;
    }

    case SSO: {
      draft.ssoAreaRiservataPrecedenteResponse = {
        ...action.payload,
        ...successful,
      };
      return;
    }

    case SSO_PENDING: {
      if (draft.ssoAreaRiservataPrecedenteResponse) {
        draft.ssoAreaRiservataPrecedenteResponse.loading = true;
      }
      return;
    }

    case SSO_FAILED: {
      if (draft.ssoAreaRiservataPrecedenteResponse) {
        draft.ssoAreaRiservataPrecedenteResponse.error = action.payload;
        draft.ssoAreaRiservataPrecedenteResponse.loading = false;
      }
      return;
    }

    case AUTHORIZE: {
      if (draft.authorizationResponse) {
        draft.authorizationResponse = {
          ...action.payload,
          ...successful,
        };
      }
      return;
    }

    case AUTHORIZE_PENDING: {
      if (draft.authorizationResponse) {
        draft.authorizationResponse.loading = true;
      }
      return;
    }

    case AUTHORIZE_FAILED: {
      if (draft.authorizationResponse) {
        draft.authorizationResponse.error = action.payload;
        draft.authorizationResponse.loading = false;
      }
      return;
    }
  }
}, initialState);
