import * as React from "react";

const SVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 68 70"
    className={`svg-icon icon-${props.name} ${props.className || ""}`}
    width={props.width || "1em"}
    height={props.height || "1em"}
    strokeWidth={props.strokeWidth || "1.5"}
  >
    <g fill="none" stroke="currentColor"  strokeLinecap="round" strokeLinejoin="round">
      <path d="M67.2 22.6H52.8c-2.2-3.7-6.2-6.2-10.9-6.2S33.2 19 31 22.6H13.4C6.4 22.6.7 28.1.7 35v15.5h12.7V38.1c0-1.7 1.4-3.1 3.2-3.1H31c2.2 3.7 6.2 6.2 10.9 6.2s8.7-2.5 10.9-6.2h14.4V22.6z" />
      <path d="M13.4 62c0 3.8-2.8 7-6.3 7S.8 65.9.8 62s6.3-11.6 6.3-11.6 6.3 7.8 6.3 11.6zM41.9 16.5V7.2M51.4 7.2h-19c-1.7 0-3.2-1.4-3.2-3.1S30.7 1 32.4 1h19c1.7 0 3.2 1.4 3.2 3.1s-1.4 3.1-3.2 3.1z" />
    </g>
  </svg>
);

export default SVG;
