import * as React from "react";

const SVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 90 90"
    className={`svg-icon icon-${props.name} ${props.className || ""}`}
    width={props.width || "1em"}
    height={props.height || "1em"}
    strokeWidth={props.strokeWidth || "1.5"}
  >
    <g fill="none" stroke="currentColor"  strokeLinecap="round" strokeLinejoin="round">
      <path d="M29.7 38.3c0-4.5 1.4-8.1 4.3-11 2.9-3 6.9-4.6 11.1-4.6M50.3 10.7c0 2.8-2.3 5.1-5.1 5.1s-5.1-2.3-5.1-5.1 2.3-5.1 5.1-5.1c2.8-.1 5.1 2.2 5.1 5.1zM53.5 74.2c.1.5.2 1.1.2 1.7 0 4.7-3.8 8.6-8.6 8.6s-8.6-3.8-8.6-8.6c0-.6.1-1.2.2-1.7" />
      <path d="M36.7 74.2h41c-10.1-9.3-10.3-15.3-10.3-35.9 0-7.2-2.7-12.2-6.3-15.9-8.7-8.8-23.3-8.8-32 0-3.5 3.6-6.3 8.7-6.3 15.9 0 20.5-.2 26.6-10.3 35.9h24.2z" />
    </g>
  </svg>
);

export default SVG;
