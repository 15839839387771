import * as React from "react";

const SVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 68 54"
    className={`svg-icon icon-${props.name} ${props.className || ""}`}
    width={props.width || "1em"}
    height={props.height || "1em"}
    strokeWidth={props.strokeWidth || "1.5"}
  >
    <g fill="none" stroke="currentColor"  strokeLinejoin="round">
      <path
        strokeLinecap="round"
        d="M15.9 52.4L62.1 40c3.1-.8 4.9-4 4.1-7.1L58.9 5.7c-.8-3.1-4-4.9-7.1-4.1L5.6 14c-3.1.8-4.9 4-4.1 7.1l7.3 27.2c.8 3.1 4 5 7.1 4.1z"
      />
      <path strokeLinecap="square" d="M4.3 31.4L61.7 16M2.5 24.9L60 9.5" />
    </g>
  </svg>
);

export default SVG;
