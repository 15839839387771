export const ERROR_REQUEST_RECAPTCHA_INVALID = "001";
export const ERROR_REQUEST_USERNAME_OR_PASSWORD_INCORRECT = "101";
export const ERROR_REQUEST_REGISTER_PHONE = "102";
export const ERROR_REQUEST_MIGRATED_NUMBER_MISSING = "103";
export const ERROR_REQUEST_DATA_DIFFERS_FROM_DB = "104";
export const ERROR_REQUEST_OTP_INVALID = "105";
export const ERROR_REQUEST_DISABLED_USER = "106";
export const ERROR_REQUEST_BLOCKED_OTP = "107";
export const ERROR_REQUEST_BLOCKED_USER = "108";
export const ERROR_REQUEST_MIGRATED_WITHOUT_NUMBER = "109";
export const ERROR_REQUEST_CODICE_FISCALE_EXISTS = "701";
export const ERROR_REQUEST_USERNAME_EXISTS = "702";
export const ERROR_REQUEST_USERNAME_INVALID = "703";
export const ERROR_REQUEST_PASSWORD_AND_CONFIRMATION_DIFFERENT = "704";

export const LOGIN = "authentication/LOGIN";
export const LOGIN_PENDING = "authentication/LOGIN_PENDING";
export const LOGIN_FAILED = "authentication/LOGIN_FAILED";
export const LOGIN_RESET_RESPONSE = "authentication/LOGIN_RESET_RESPONSE";

export const OTP_VERIFICATION = "authentication/OTP_VERIFICATION";
export const OTP_VERIFICATION_PENDING = "authentication/OTP_VERIFICATION_PENDING";
export const OTP_VERIFICATION_FAILED = "authentication/OTP_VERIFICATION_FAILED";
export const OTP_VERIFICATION_RESET_RESPONSE = "authentication/OTP_VERIFICATION_RESET_RESPONSE";

export const OTP_RESEND = "authentication/OTP_RESEND";
export const OTP_RESEND_PENDING = "authentication/OTP_RESEND_PENDING";
export const OTP_RESEND_FAILED = "authentication/OTP_RESEND_FAILED";
export const OTP_RESEND_RESET_RESPONSE = "authentication/OTP_RESEND_RESET_RESPONSE";

export const GET_POLICIES = "authentication/GET_POLICIES";
export const GET_POLICIES_PENDING = "authentication/GET_POLICIES_PENDING";
export const GET_POLICIES_FAILED = "authentication/GET_POLICIES_FAILED";
export const GET_POLICIES_RESET_RESPONSE = "authentication/GET_POLICIES_RESET_RESPONSE";

export const REGISTER_USER = "authentication/REGISTER_USER";
export const REGISTER_USER_PENDING = "authentication/REGISTER_USER_PENDING";
export const REGISTER_USER_FAILED = "authentication/REGISTER_USER_FAILED";
export const REGISTER_USER_RESET_RESPONSE = "authentication/REGISTER_USER_RESET_RESPONSE";

export const RECOVER_PASSWORD = "authentication/RECOVER_PASSWORD";
export const RECOVER_PASSWORD_PENDING = "authentication/RECOVER_PASSWORD_PENDING";
export const RECOVER_PASSWORD_FAILED = "authentication/RECOVER_PASSWORD_FAILED";
export const RECOVER_PASSWORD_RESET_RESPONSE = "authentication/RECOVER_PASSWORD_RESET_RESPONSE";

export const RECOVER_USERNAME = "authentication/RECOVER_USERNAME";
export const RECOVER_USERNAME_PENDING = "authentication/RECOVER_USERNAME_PENDING";
export const RECOVER_USERNAME_FAILED = "authentication/RECOVER_USERNAME_FAILED";
export const RECOVER_USERNAME_RESET_RESPONSE = "authentication/RECOVER_USERNAME_RESET_RESPONSE";

export const RE_ENABLE_USER = "authentication/RE_ENABLE_USER";
export const RE_ENABLE_USER_PENDING = "authentication/RE_ENABLE_USER_PENDING";
export const RE_ENABLE_USER_FAILED = "authentication/RE_ENABLE_USER_FAILED";
export const RE_ENABLE_USER_RESET_RESPONSE = "authentication/RE_ENABLE_USER_RESET_RESPONSE";

export const SET_OTP_DEVICE = "authentication/SET_OTP_DEVICE";
export const SET_OTP_DEVICE_PENDING = "authentication/SET_OTP_DEVICE_PENDING";
export const SET_OTP_DEVICE_FAILED = "authentication/SET_OTP_DEVICE_FAILED";
export const SET_OTP_DEVICE_RESET_RESPONSE = "authentication/SET_OTP_DEVICE_RESET_RESPONSE";

export const PASSWORD_CHANGE = "authentication/PASSWORD_CHANGE";
export const PASSWORD_CHANGE_PENDING = "authentication/PASSWORD_CHANGE_PENDING";
export const PASSWORD_CHANGE_FAILED = "authentication/PASSWORD_CHANGE_FAILED";
export const PASSWORD_CHANGE_RESET_RESPONSE = "authentication/PASSWORD_CHANGE_RESET_RESPONSE";

export const SET_TOKEN = "authentication/SET_TOKEN";
export const CLEAR_TOKEN = "authentication/CLEAR_TOKEN";

export const SSO = "authentication/SSO";
export const SSO_PENDING = "authentication/SSO_PENDING";
export const SSO_FAILED = "authentication/SSO_FAILED";

export const AUTHORIZE = "authentication/AUTHORIZE";
export const AUTHORIZE_PENDING = "authentication/AUTHORIZE_PENDING";
export const AUTHORIZE_FAILED = "authentication/AUTHORIZE_FAILED";
