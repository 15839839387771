import * as React from "react";

const SVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 70 62"
    className={`svg-icon icon-${props.name} ${props.className || ""}`}
    width={props.width || "1em"}
    height={props.height || "1em"}
    strokeWidth={props.strokeWidth || "1.5"}
  >
    <g fill="none" stroke="currentColor"  strokeLinecap="round" strokeLinejoin="round">
      <path d="M44 15.1c1.5-1.2 2.3-2.6 2.3-4.1M32.9 19.5c-2.7.5-5.8.8-9 .8-12.3 0-22.4-4.2-22.4-9.3M46.3 11c0-5.2-10-9.3-22.4-9.3S1.6 5.8 1.6 11M25.9 47h-2c-12.3 0-22.4-4.2-22.4-9.3M24.3 33.7h-.4c-12.3 0-22.4-4.2-22.4-9.3M1.6 24.3v13.4M1.6 11v13.4" />
      <path d="M37.4 58.5c-3.7 1.2-8.4 1.9-13.4 1.9-12.3 0-22.4-4.2-22.4-9.3V37.7M46.3 11v4M24.3 33.7c-.2 1.3-.4 2.6-.4 4 0 3.3.7 6.5 2 9.3" />
      <path d="M46.3 15c12.3 0 22.4 10.2 22.4 22.7s-10 22.7-22.4 22.7c-3.2 0-6.2-.7-8.9-1.9M25.9 47c2.3 5.1 6.4 9.3 11.5 11.5M44 15.1c.8-.1 1.5-.1 2.3-.1M32.9 19.5c3.2-2.4 6.9-4 11.1-4.4M24.3 33.7c1-5.8 4.2-10.8 8.6-14.1" />
      <path d="M39.8 34.7c.3-.7.6-1.3 1-1.9.4-.6.8-1.2 1.3-1.7s1-1 1.6-1.4c.6-.4 1.2-.8 1.8-1.1.6-.3 1.3-.5 2-.7.7-.1 1.4-.2 2.1-.2.7 0 1.5.1 2.1.2M39.8 42.2c.3.7.6 1.3 1 1.9.4.6.8 1.2 1.3 1.7s1 1 1.6 1.4c.6.4 1.2.8 1.8 1.1.6.3 1.3.5 2 .7.7.1 1.4.2 2.1.2.7 0 1.5-.1 2.1-.2M36.7 34.9h13.1M36.7 42.1h13.1" />
    </g>
  </svg>
);

export default SVG;
