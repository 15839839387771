import * as React from "react";

const SVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 57 70"
    className={`svg-icon icon-${props.name} ${props.className || ""}`}
    width={props.width || "1em"}
    height={props.height || "1em"}
    strokeWidth={props.strokeWidth || "1.5"}
  >
    <g fill="none" stroke="currentColor"  strokeLinecap="round" strokeLinejoin="round">
      <path d="M46.3 68.9H10.5c-5.1 0-9.2-3.8-9.2-8.4V38.7c0-1 .9-1.9 2-1.9h50c1.1 0 2 .8 2 1.9v21.7c.1 4.7-4 8.5-9 8.5zM47.7 36.8V20.6c0-2.9-.4-5.5-1.3-7.8s-2.2-4.4-3.8-6.1c-1.7-1.7-3.7-3-6.1-4-2.4-.9-5.1-1.4-8.1-1.4s-5.7.5-8.1 1.4-4.4 2.3-6.1 4-2.9 3.7-3.8 6.1c-.9 2.3-1.3 4.9-1.3 7.8v16" />
      <path d="M40 36.8V20c0-1.7-.3-3.3-.8-4.7s-1.3-2.6-2.3-3.7c-1-1-2.2-1.8-3.7-2.4-1.4-.6-3.1-.9-4.9-.9s-3.3.3-4.8.9c-1.4.6-2.7 1.4-3.7 2.4s-1.8 2.2-2.3 3.7c-.5 1.4-.8 3-.8 4.7v16.5M29.6 58.2h-2.5c-1.4 0-2.6-1.1-2.6-2.4v-7.6c0-1.3 1.2-2.4 2.6-2.4h2.5c1.4 0 2.6 1.1 2.6 2.4v7.6c0 1.3-1.2 2.4-2.6 2.4z" />
    </g>
  </svg>
);

export default SVG;
