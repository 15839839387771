import React from "react";
import { FormattedHTMLMessage, FormattedMessage } from "react-intl";
import { ButtonColors } from "../../ui_kit/button/button";
import LinkButton from "../../ui_kit/button/link_button";
import Modal from "../../ui_kit/modal/modal";
import Section from "../../ui_kit/section/section";

export interface IOwnProps {
  onCloseEnd?: (modal: Modal, element: Element) => void;
}

type Props = IOwnProps;

const ModalUserBlocked: React.FunctionComponent<Props> = ({ onCloseEnd }) => {
  const closeEndHandler = (modal: Modal, element: Element) => {
    if (onCloseEnd) {
      onCloseEnd(modal, element);
    }
  };
  return (
    <Modal id="blocked-user-modal" autoOpen={true} dismissible={false} onCloseEnd={closeEndHandler}>
      <Section className="center">
        <Section.Title>
          <FormattedMessage
            id="modal_user_blocked.title"
            defaultMessage="Hai effettuato troppi tentativi di accesso!"
          />
        </Section.Title>
        <Section.Content>
          <FormattedHTMLMessage
            id="modal_user_blocked.identity_verification"
            defaultMessage="Per sbloccare il tuo utente è necessario verificare la tua identità.<br />Prosegui con la procedura di ripristino dell'account."
          />
          <div className="modal-actions center">
            <LinkButton
              color={ButtonColors.Red}
              inverted={true}
              to="/users/re-enable"
              data-cy="re-enable-user-button"
              className="modal-action"
            >
              <FormattedMessage id="modal_user_blocked.re_enable_account" defaultMessage="Ripristina il mio account" />
            </LinkButton>
          </div>
        </Section.Content>
      </Section>
    </Modal>
  );
};

export default React.memo(ModalUserBlocked);
