import { getHOST, stringFlagToBoolean } from "../lib/env_utils";

export const BASE_PATH = process.env.BASE_PATH || "/";
export const NODE_ENV = process.env.NODE_ENV || "development";
export const RECAPTCHA_KEY = process.env.RECAPTCHA_KEY || "";

export const HOST = getHOST(
  process.env.API_PROTOCOL,
  process.env.API_HOSTNAME,
  process.env.API_PORT,
  process.env.API_IGNORE_HOST
);

export const API_PREFIX = process.env.API_PREFIX;
export const GTM_TRACKING_ID = process.env.GTM_TRACKING_ID ?? "";

export const COOKIE_DOMAIN = process.env.COOKIE_DOMAIN ?? "";
export const COOKIE_POLICIES_URL = process.env.COOKIE_POLICIES_URL ?? "";

export const CHIEDI_ESPERTO_URL = process.env.CHIEDI_ESPERTO_URL ?? "";

// Unique name to identify the application in logs
export const APP_ORIGIN = process.env.APP_ORIGIN ?? "timfinarspa";

// ONE TRUST
export const ENABLE_ONETRUST = stringFlagToBoolean(process.env.ENABLE_ONETRUST);
export const ONETRUST_DOMAIN_SCRIPT = process.env.ONETRUST_DOMAIN_SCRIPT ?? "";
export const ONETRUST_SRC = process.env.ONETRUST_SRC ?? "";
export const ONETRUST_BLOCK_SCRIPT_SRC = process.env.ONETRUST_BLOCK_SCRIPT_SRC ?? "";

export const ENABLE_PRESTITO_AD = stringFlagToBoolean(process.env.ENABLE_PRESTITO_AD);
