import React from "react";
import { FormattedMessage } from "react-intl";
import Modal from "../../ui_kit/modal/modal";
import Section from "../../ui_kit/section/section";

export interface IOwnProps {
  onCloseEnd?: (modal: Modal, element: Element) => void;
}

type Props = IOwnProps;

const ModalUserDisabled: React.FunctionComponent<Props> = ({ onCloseEnd }) => {
  const closeEndHandler = (modal: Modal, element: Element) => {
    if (onCloseEnd) {
      onCloseEnd(modal, element);
    }
  };
  return (
    <Modal id="disabled-user-modal" autoOpen={true} dismissible={true} onCloseEnd={closeEndHandler}>
      <Section className="center">
        <Section.Title>
          <FormattedMessage id="modal_user_disabled.title" defaultMessage="Utente disabilitato" />
        </Section.Title>
        <Section.Content>
          <FormattedMessage
            id="modal_user_disabled.support"
            defaultMessage="Per assistenza contattaci all'indirizzo mail {email}"
            values={{
              email: <a href="mailto:assistenza@timfin.it">assistenza@timfin.it</a>,
            }}
          />
          {/* <div className="modal-actions center">
            <a className="btn modal-action" href="tel:848888081">
              <FormattedMessage id="modal_user_disabled.customer_care" defaultMessage="Chiama il servizio clienti" />
            </a>
          </div> */}
        </Section.Content>
      </Section>
    </Modal>
  );
};

export default React.memo(ModalUserDisabled);
