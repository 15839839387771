import "core-js/stable";
import Intl from "intl";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "regenerator-runtime/runtime";
import App from "./app";
import OneTrust from "./components/OneTrust/OneTrust";
import ErrorHandler from "./lib/error-handler";
import initStore from "./store";
window.Intl = Intl;

export const store = initStore();

ReactDOM.render(
  <ErrorHandler>
    <Provider store={store}>
      <OneTrust>
        <App />
      </OneTrust>
    </Provider>
  </ErrorHandler>,
  document.getElementById("root") as HTMLElement
);
