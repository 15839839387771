import { Form, Formik, FormikProps } from "formik";
import get from "lodash.get";
import React, { useEffect } from "react";
import { defineMessages, FormattedHTMLMessage, FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import * as Yup from "yup";
import { SetOtpDeviceRequest } from "../../generated";
import { resetResponseCode, setOtpDevice } from "../../logic/authentication/actions";
import { ERROR_REQUEST_REGISTER_PHONE, SET_OTP_DEVICE_RESET_RESPONSE } from "../../logic/authentication/consts";
import { IBasicRequest, IState } from "../../logic/authentication/types";
import { numeroContrattoRegexp } from "../../logic/ui/consts";
import { IStoreState } from "../../types/store";
import FormAuth from "../shared/form_auth/form_auth";
import NumeroContrattoInput from "../shared/Inputs/NumeroContrattoInput/NumeroContrattoInput";
import ModalNumberNotCorresponding from "../shared/modals/modal_number_not_corresponding";
import ModalRegisterNumber from "../shared/modals/modal_register_number";
import ModalServerError from "../shared/modals/modal_server_error";
import Spinner from "../shared/spinner/spinner";
import Button from "../ui_kit/button/button";
import { InputText } from "../ui_kit/form/input_text";
import "./_form_add_otp_device.scss";

interface IDispatchProps {
  setOtpDevice: (setOtpDeviceRequest: SetOtpDeviceRequest) => void;
  resetResponseCode: (action: string) => void;
}

export interface IStateProps {
  otpDevice: IBasicRequest & SetOtpDeviceRequest;
}

const translations = defineMessages({
  mustBeANumber: {
    defaultMessage: "Il dato inserito deve essere numerico",
    description: "Il dato inserito deve essere numerico",
    id: "generic.mustBeANumber",
  },
  required: {
    defaultMessage: "Campo obbligatorio",
    description: "campo obbligatorio",
    id: "generic.required",
  },
});

type Props = IStateProps & IDispatchProps;

const initialState = {
  codiceFiscale: "",
  mobilePhoneNumber: "",
  numeroContratto: "",
};

const getValidationSchema = (t: any) => {
  return Yup.object().shape({
    codiceFiscale: Yup.string().required(t(translations.required)),
    mobilePhoneNumber: Yup.string().required(t(translations.required)),
    numeroContratto: Yup.string()
      .matches(numeroContrattoRegexp, { message: t(translations.mustBeANumber) })
      .required(t(translations.required)),
  });
};

const FormAddOtpDevice: React.FunctionComponent<Props> = ({
  setOtpDevice,
  otpDevice,
  resetResponseCode,
  intl: { formatMessage: t },
}) => {
  useEffect(() => {
    return () => {
      resetResponseCode(SET_OTP_DEVICE_RESET_RESPONSE);
    };
  }, []);

  const formikSubmit = (values: any, actions: any) => {
    setOtpDevice({ otpAddDeviceRequest: values });
  };

  // TODO: handle a 200 response with content-type text/html by redirecting
  // to /server-error.

  if (get(otpDevice, "error.status") === 200 && !get(otpDevice, "mobileNumberDifferent", false)) {
    // OTP Device present
    return (
      <Redirect
        to={{
          pathname: "/auth/otp",
          state: { actionTelefonoSmarrito: false },
        }}
        // <Redirect to="/auth/otp" />;
      />
    );
  } else if (get(otpDevice, "error.status") === 401) {
    // token non valido, redirect a login
    return <Redirect to="/auth/login" />;
  }

  const closeModalHandler = () => {
    resetResponseCode(SET_OTP_DEVICE_RESET_RESPONSE);
  };

  return (
    <FormAuth
      pageContainerClass="add-otp-device-container"
      actionsClass=""
      formContentClass=""
      title={<FormattedHTMLMessage id="form_add_otp_device.title" defaultMessage="Collega il tuo numero" />}
      intro={
        <FormattedHTMLMessage
          id="form_add_otp_device.intro"
          defaultMessage="Per verificare la tua identità abbiamo bisogno del tuo numero di cellulare e dei seguenti dati:"
        />
      }
      showRequired={true}
      content={
        <Formik initialValues={initialState} validationSchema={getValidationSchema(t)} onSubmit={formikSubmit}>
          {(formikProps: FormikProps<any>) => (
            <Form>
              <InputText
                label={
                  <FormattedMessage id="form_add_otp_device.mobile_phone_number" defaultMessage="Numero cellulare*" />
                }
                name="mobilePhoneNumber"
                defaultHelperText={
                  <FormattedMessage
                    id="form_add_otp_device.mobile_phone_helper"
                    defaultMessage="Il numero di cellulare deve corrispondere con quello registrato in fase di richiesta della pratica, in caso contrario utilizza il link a fondo pagina per inserirlo/modificarlo."
                  />
                }
              />
              <InputText
                label={<FormattedMessage id="form_add_otp_device.codice_fiscale" defaultMessage="Codice fiscale*" />}
                name="codiceFiscale"
              />
              <NumeroContrattoInput />
              {get(otpDevice, "error.status") === 400 && (
                <div className="form-error">{get(otpDevice, "error.response.description")}</div>
              )}
              {get(otpDevice, "loading") ? (
                <Spinner />
              ) : (
                <Button data-cy="button-submit" disabled={!formikProps.isValid} inverted={false} type="submit">
                  <FormattedMessage id="form_add_otp_device.verify" defaultMessage="Verifica" />
                  {/* <Icon name="arrow-right" className="icon-blue" strokeWidth="2" /> */}
                </Button>
              )}
            </Form>
          )}
        </Formik>
      }
      actions={
        <>
          <a className="change-number modal-trigger" data-target="register-number-modal" data-cy="register-number">
            <FormattedMessage
              id="form_add_otp_device.change_number"
              defaultMessage="Vuoi inserire/modificare il cellulare? Chiedi a un esperto."
            />
          </a>
        </>
      }
      modals={
        <>
          {get(otpDevice, "error.status") === 400 &&
            get(otpDevice, "error.response.code") === ERROR_REQUEST_REGISTER_PHONE && (
              <ModalRegisterNumber context="otp" autoOpen={true} dismissible={false} onCloseEnd={closeModalHandler} />
            )}
          <ModalRegisterNumber context="otp" />
          {get(otpDevice, "error.status") === 200 && get(otpDevice, "mobileNumberDifferent", false) && (
            <ModalNumberNotCorresponding onCloseEnd={closeModalHandler} />
          )}
          {get(otpDevice, "error.status") >= 500 && <ModalServerError onCloseEnd={closeModalHandler} />}
        </>
      }
    />
  );
};

const mapStateToProps = (state: IStoreState): IState => ({
  otpDevice: state.authentication.otpDevice,
});

const mapDispatchToProps: IDispatchProps = {
  resetResponseCode,
  setOtpDevice,
};

const withIntlComponent = injectIntl(FormAddOtpDevice);

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(withIntlComponent));
