import { loggingMiddleware } from "../loggingMiddleware";
import { HOST } from "../../config/constants";
import { Configuration, ConfigurationParameters } from "../../generated";
import { tokenMiddleware } from "../auth/token";

// const basePath = `${HOST}${API_PREFIX}`;
const basePath = `${HOST}/api/auth`;

export const apiConfig: (conf?: Partial<ConfigurationParameters>) => Configuration = (conf = {}) => {
  const { basePath: overriddenBasePath, middleware, username, password, apiKey, accessToken } = conf;

  const config: ConfigurationParameters = {};

  if (accessToken) {
    config.accessToken = accessToken;
  }

  if (apiKey) {
    config.apiKey = apiKey;
  }

  config.basePath = basePath;
  if (overriddenBasePath) {
    config.basePath = overriddenBasePath;
  }

  config.middleware = [loggingMiddleware, tokenMiddleware, ...(middleware || [])];

  if (password) {
    config.password = password;
  }

  if (username) {
    config.username = username;
  }

  return new Configuration(config);
};
