export const stringFlagToBoolean = (flag?: string): boolean => flag !== undefined && flag.toLowerCase() === "true";

const isNotIgnored = (ignore?: string): boolean => {
  if (ignore === undefined) {
    return false;
  }
  return ignore.toLowerCase() !== "true";
};

const getProtocol = (env?: string): string => {
  return env ? `${env}://` : "//";
};

export const getHOST = (protocol?: string, hostname?: string, port?: string, ignore?: string): string =>
  isNotIgnored(ignore) ? `${getProtocol(protocol)}${hostname}:${port}` : "";
