import { AjaxError } from "rxjs/ajax";

export const errorAction = (type: string, errorData: AjaxError) => {
  const { status, message } = errorData.response;

  // This could do something with errorData before dispatching it to Redux.
  // E.g. Call to Analytics (?)

  return {
    error: true,
    payload: {
      message,
      status,
    },
    type,
  };
};
