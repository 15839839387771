import * as React from "react";

const SVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 70 70"
    className={`svg-icon icon-${props.name} ${props.className || ""}`}
    width={props.width || "1em"}
    height={props.height || "1em"}
    strokeWidth={props.strokeWidth || "1.5"}
  >
    <g fill="none" stroke="currentColor"  strokeLinecap="round" strokeLinejoin="round">
      <path d="M56.5 12h12.3V7.6c0-3.4-2.8-6.2-6.2-6.2" />
      <path d="M10.9 68.6h36.5c4.9 0 8.9-4 8.9-9v-52h0c0-3.4 2.8-6.2 6.2-6.2H19.8c-4.9 0-8.9 4-8.9 9V58" />
      <path d="M39.7 59.7V58H1.2v1.7c0 4.9 4 9 8.9 9h38.4c-4.8-.1-8.8-4.1-8.8-9zM19 14.7h28.6M19 26.3h28.6M19 35.6h10.9M19 47.2h10.9M38.5 43.8l-1.6 9 5.2-2.6 5.3 2.6-1.7-9.5" />
      <path d="M45.7 43.3c-1 1-2.4 1.6-3.9 1.6-1.2 0-2.4-.4-3.3-1.1M38.5 43.8c-1.3-1-2.2-2.6-2.2-4.4 0-3 2.5-5.5 5.5-5.5s5.5 2.5 5.5 5.5c0 1.5-.6 2.9-1.6 3.9" />
    </g>
  </svg>
);

export default SVG;
