import * as React from "react";

const SVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 61 69"
    className={`svg-icon icon-${props.name} ${props.className || ""}`}
    width={props.width || "1em"}
    height={props.height || "1em"}
    strokeWidth={props.strokeWidth || "1.5"}
  >
    <path
      fill="none"
      stroke="currentColor"
      
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M26.1 61.1c-2.9.6-4.8 1.7-4.8 3 0 1.9 4.2 3.4 9.4 3.4S40 66 40 64.1c0-1.3-1.9-2.4-4.8-3"
    />
    <g fill="none" stroke="currentColor"  strokeLinecap="round" strokeLinejoin="round">
      <path d="M60.2 27.243c-.1-12.8-13.3-23.1-29.6-23.1S1 14.543 1 27.343c0 .2 0 .3.1.5.1 2.8 3.2 5 7.3 5 2.3 0 4.3-.7 5.6-1.8 3 2.9 9.8 4.4 16.6 4.4s13.7-1.5 16.7-4.4c1.3 1.2 3.4 1.9 5.7 1.9 4.1 0 7.3-2.2 7.3-5.1-.1-.3-.1-.5-.1-.6zM13.9 30.943s-1.7-21.5 17.1-26.8M47.6 30.943s1.7-21.5-17.1-26.8" />
    </g>
    <path
      fill="none"
      stroke="currentColor"
      
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M30.6 35.7V63M30.6 1.5v2.6"
    />
  </svg>
);

export default SVG;
