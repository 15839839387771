import * as React from "react";

const SVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 70 70"
    className={`svg-icon icon-${props.name} ${props.className || ""}`}
    width={props.width || "1em"}
    height={props.height || "1em"}
    strokeWidth={props.strokeWidth || "1.5"}
  >
    <g fill="none" stroke="currentColor"  strokeLinecap="round" strokeLinejoin="round">
      <path d="M8.7 68.4L1.6 65l9.8-21.9 7 3.4z" />
      <path d="M17.4 48.7L28.1 43c2.8-1.5 6.1-.9 8.3 1.4l1.1 1.2L25.3 52l-3.5 1.8M25.3 52.1l9.7 6.1 10.6-5c2.8-1.2 5.4-1.4 7.7.2l3.4 2.2-19.1 9.9H10M1.6 35h66.7M4.7 35C6.1 19.5 19.1 7.3 35 7.3S63.9 19.5 65.3 35" />
      <path d="M30.6 7.6V6c0-2.4 1.9-4.4 4.4-4.4s4.4 1.9 4.4 4.4v1.7" />
    </g>
  </svg>
);

export default SVG;
