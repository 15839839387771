import * as React from "react";

const SVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 70 70"
    className={`svg-icon icon-${props.name} ${props.className || ""}`}
    width={props.width || "1em"}
    height={props.height || "1em"}
    strokeWidth={props.strokeWidth || "1.5"}
  >
    <g fill="none" stroke="currentColor"  strokeLinejoin="round">
      <path strokeLinecap="round" d="M1.2 1.2h2.5c1.3 0 2.5.7 3.1 1.8l9.4 16.2" />
      <path d="M13.2 14.1h50.6c3.8 0 6.2 4.1 4.3 7.5l-8.8 15.2C57.8 39.4 55 41 52.1 41H33.5c-3 0-5.7-1.6-7.2-4.2L13.2 14.1z" />
      <path strokeLinecap="round" d="M21.4 58.6l6.3-10.9c1.5-2.6 1.5-5.8 0-8.4l-1.8-3.2" />
      <path d="M24 63.1h27.4M21.4 58.6c1.4 1 2.3 2.7 2.3 4.6 0 3.1-2.5 5.6-5.6 5.6s-5.6-2.5-5.6-5.6 2.5-5.6 5.6-5.6c1.2-.1 2.4.3 3.3 1M62.5 63.1c0 3.1-2.5 5.6-5.6 5.6s-5.6-2.5-5.6-5.6 2.5-5.6 5.6-5.6 5.6 2.5 5.6 5.6z" />
    </g>
  </svg>
);

export default SVG;
