import * as React from "react";

const SVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 69 70"
    className={`svg-icon icon-${props.name} ${props.className || ""}`}
    width={props.width || "1em"}
    height={props.height || "1em"}
    strokeWidth={props.strokeWidth || "1.5"}
  >
    <g fill="none" stroke="currentColor"  strokeLinecap="round" strokeLinejoin="round">
      <path d="M34.4 4.2S23.2 7 23.2 30.5M34.4 4.2S45.6 7 45.6 30.5M34.4 1.3v2.9" />
      <path d="M1 30.5c6.2-3.9 16.1-3.9 22.3 0 6.2-3.9 16.1-3.9 22.3 0 6.2-3.9 16.1-3.9 22.3 0 0-14.5-15-26.3-33.4-26.3S1 16 1 30.5z" />
      <path d="M25.3 62.7v1.5c0 2.4 2 4.4 4.6 4.4s4.6-2 4.6-4.4V27.6" />
    </g>
  </svg>
);

export default SVG;
