// tslint:disable
/**
 * TIMFIN Authentication
 * TIMFIN Authentication API.
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Request for get an authToken
 * @export
 * @interface AuthorizationRequest
 */
export interface AuthorizationRequest {
    /**
     * Application scope, ex. AR stands for Area Riservata
     * @type {string}
     * @memberof AuthorizationRequest
     */
    scope: string;
}
