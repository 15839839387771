import * as React from "react";

const SVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 70 70"
    className={`svg-icon icon-${props.name} ${props.className || ""}`}
    width={props.width || "1em"}
    height={props.height || "1em"}
    strokeWidth={props.strokeWidth || "1.5"}
  >
    <g fill="none" stroke="currentColor"  strokeLinecap="round" strokeLinejoin="round">
      <path d="M45.5 30c2.4 3.2 3.8 7.1 1 10.9-1.3 1.8-3.2 2.9-5 4.2-2 1.5-3.9 3.1-4.6 5.5-.8 2.6-.4 5.1-1.3 7.7-.9 2.6-2.5 5-4.6 6.7-3.2 2.6-7.4 3.8-11.2 3.1-4.5-.8-8.5-4.6-10.9-7-2.4-2.3-6.2-6.3-7-10.8-.7-3.8.5-8.1 3.1-11.3 1.8-2.1 4.1-3.7 6.7-4.6 2.6-.9 5.1-.4 7.7-1.3 2.4-.8 4.1-2.6 5.5-4.6 1.3-1.8 2.4-3.7 4.2-5 3.8-2.9 7.7-1.4 10.9 1" />
      <path d="M33.5 43.7c0 4-3.2 7.3-7.3 7.3S19 47.7 19 43.7s3.2-7.3 7.3-7.3 7.2 3.3 7.2 7.3zM13.2 48.1l8.7 8.7M67.2 8.3L39.1 36.4l-5.5-5.5L61.7 2.8c1.5-1.5 4-1.5 5.5 0s1.5 4 0 5.5zM65.5 10.3l2.9 2.9M59.7 16.1l2.9 2.9M65.5 10.3l2.9 2.9M59.7 16.1l2.9 2.9M53.8 10.3l-2.9-2.9M59.7 4.5l-2.9-2.9" />
    </g>
  </svg>
);

export default SVG;
