import * as React from "react";

const SVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 90 90"
    className={`svg-icon icon-${props.name} ${props.className || ""}`}
    width={props.width || "1em"}
    height={props.height || "1em"}
    strokeWidth={props.strokeWidth || "1.5"}
  >
    <circle fill="none" stroke="currentColor"  cx={45} cy={45} r={38.7} />
    <g fill="none" stroke="currentColor"  strokeLinecap="round">
      <path d="M32.7 32.7l24.6 24.6M57.3 32.7L32.7 57.3" />
    </g>
  </svg>
);

export default SVG;
