import React, { useEffect, useState } from "react";
import { IntlProvider } from "react-intl";
import { Hoc } from "../../types/hoc";
import loadLocale from "./load-locale";

const defaultLocale: IntlProvider.Props = {
  messages: {},
};

const useLocale = (locale: string) => {
  const [intl, setIntl] = useState(defaultLocale);

  const setLocale = async (locale: string) => {
    const intl = await loadLocale(locale);
    setIntl(intl);
  };

  useEffect(() => {
    setLocale(locale);
  }, [locale]);

  return intl;
};

const intl = (locale: string): Hoc => Component => props => {
  const intl = useLocale(locale);
  return intl.locale ? (
    <IntlProvider {...intl}>
      <Component {...props} />
    </IntlProvider>
  ) : null;
};

export default intl;
