import * as React from "react";

const SVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 70 70"
    className={`svg-icon icon-${props.name} ${props.className || ""}`}
    width={props.width || "1em"}
    height={props.height || "1em"}
    strokeWidth={props.strokeWidth || "1.5"}
  >
    <g fill="none" stroke="currentColor" >
      <path d="M6.1 63.9c-6-6-6-15.7 0-21.7l36-36c6-6 15.7-6 21.7 0s6 15.7 0 21.7l-36 36c-5.9 6-15.7 6-21.7 0z" />
      <path d="M56.7 35l7.1 7.1c6 6 6 15.7 0 21.7s-15.7 6-21.7 0L35 56.7M13.3 35l-7.1-7.1c-6-6-6-15.7 0-21.7 3-3 6.9-4.5 10.9-4.5 3.9 0 7.8 1.5 10.8 4.5l7.1 7.1M45.9 45.9L24.2 24.2" />
    </g>
  </svg>
);

export default SVG;
