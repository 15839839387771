import * as React from "react";

const SVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 70 70"
    className={`svg-icon icon-${props.name} ${props.className || ""}`}
    width={props.width || "1em"}
    height={props.height || "1em"}
    strokeWidth={props.strokeWidth || "1.5"}
  >
    <g fill="none" stroke="currentColor"  strokeLinecap="round" strokeLinejoin="round">
      <path d="M68.5 39.4v-8.9c0-1.6-1.3-2.9-2.9-2.9h-7c-.5-1.5-1.1-2.9-1.8-4.3l4.9-4.9c1.1-1.1 1.1-2.9 0-4l-6.3-6.3c-1.1-1.1-2.9-1.1-4 0L46.5 13c-1.4-.7-2.8-1.3-4.3-1.8v-7c0-1.6-1.3-2.9-2.9-2.9h-8.9c-1.6 0-2.9 1.3-2.9 2.9v7c-1.5.5-2.9 1.1-4.3 1.8l-4.9-4.9c-1.1-1.1-2.9-1.1-4 0L8 14.4c-1.1 1.1-1.1 2.9 0 4l4.9 4.9c-.7 1.4-1.3 2.8-1.8 4.3h-7c-1.6 0-2.9 1.3-2.9 2.9v8.9c0 1.6 1.3 2.9 2.9 2.9h7c.5 1.5 1.1 2.9 1.8 4.3L8 51.5c-1.1 1.1-1.1 2.9 0 4l6.3 6.3c1.1 1.1 2.9 1.1 4 0l4.9-4.9c1.4.7 2.8 1.3 4.3 1.8v7c0 1.6 1.3 2.9 2.9 2.9h8.9c1.6 0 2.9-1.3 2.9-2.9v-7c1.5-.5 2.9-1.1 4.3-1.8l4.9 4.9c1.1 1.1 2.9 1.1 4 0l6.3-6.3c1.1-1.1 1.1-2.9 0-4l-4.9-4.9c.7-1.4 1.3-2.8 1.8-4.3h7c1.7 0 2.9-1.3 2.9-2.9z" />
      <path d="M49.6 35c0 8.1-6.5 14.6-14.6 14.6S20.4 43.1 20.4 35 26.9 20.4 35 20.4 49.6 26.9 49.6 35z" />
    </g>
  </svg>
);

export default SVG;
