import * as React from "react";

const SVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 67 67"
    className={`svg-icon icon-${props.name} ${props.className || ""}`}
    width={props.width || "1em"}
    height={props.height || "1em"}
    strokeWidth={props.strokeWidth || "1.5"}
  >
    <path
      fill="none"
      stroke="currentColor"
      
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M39.3 35.4l-.9 19.3 8 5.6V66l-12.8-2.8L20.8 66v-5.7l8-5.6-.9-19.3h0L1 40.5v-5.7l26-16.9h0l-.5-10C26.5 4 29.7.8 33.6.8c2 0 3.7.8 5 2.1 1.3 1.3 2.1 3.1 2.1 5l-.5 10h0l26 16.9v5.7l-26.9-5.1"
    />
  </svg>
);

export default SVG;
