import * as React from "react";

const SVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 70 48"
    className={`svg-icon icon-${props.name} ${props.className || ""}`}
    width={props.width || "1em"}
    height={props.height || "1em"}
    strokeWidth={props.strokeWidth || "1.5"}
  >
    <g fill="none" stroke="currentColor"  strokeLinejoin="round">
      <path
        strokeLinecap="round"
        d="M7.9 46.6H62c3.6 0 6.5-2.9 6.5-6.6V8.2c0-3.6-2.9-6.6-6.5-6.6H7.9c-3.6 0-6.5 2.9-6.5 6.6V40c0 3.7 2.9 6.6 6.5 6.6z"
      />
      <path strokeLinecap="square" d="M1.4 20.4h67.1M1.4 12.9h67.1" />
    </g>
  </svg>
);

export default SVG;
