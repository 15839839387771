// tslint:disable
/**
 * TIMFIN Authentication
 * TIMFIN Authentication API.
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Credentials needed for first step authentication.
 * @export
 * @interface LoginRequest
 */
export interface LoginRequest {
    /**
     * @type {string}
     * @memberof LoginRequest
     */
    username: string;
    /**
     * @type {string}
     * @memberof LoginRequest
     */
    password: string;
    /**
     * @type {string}
     * @memberof LoginRequest
     */
    recaptchaToken: string;
}
