import * as React from "react";
import "./section_content.scss";

interface IProps {
  centered?: boolean;
  className?: string;
  children: React.ReactNode;
}

const SectionContent: React.FunctionComponent<IProps> = ({ centered = false, className, children }) => (
  <div
    className={`section__content${centered ? " section__content--centered" : ""}${className ? ` ${className}` : ""}`}
  >
    {children}
  </div>
);

export default React.memo(SectionContent);
