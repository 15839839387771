// tslint:disable
/**
 * TIMFIN Authentication
 * TIMFIN Authentication API.
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface OtpVerificationResponse
 */
export interface OtpVerificationResponse {
    /**
     * REDIRECT_TO_LOGIN - The user is not authenticated and needs to login (recover username flow).  REDIRECT_TO_APP - The user is fully authenticated and may access the app.  CHANGE_PASSWORD - The user needs to change their password before being allowed access to app.
     * @type {string}
     * @memberof OtpVerificationResponse
     */
    action: OtpVerificationResponseActionEnum;
    /**
     * Application URL (in case of REDIRECT_TO_APP action)
     * @type {string}
     * @memberof OtpVerificationResponse
     */
    applicationUrl?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum OtpVerificationResponseActionEnum {
    CHANGEPASSWORD = 'CHANGE_PASSWORD',
    REDIRECTTOAPP = 'REDIRECT_TO_APP',
    REDIRECTTOLOGIN = 'REDIRECT_TO_LOGIN',
    REDIRECTTOOLDARAPP = 'REDIRECT_TO_OLD_AR_APP'
}

