import * as React from "react";

const SVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 46 70"
    className={`svg-icon icon-${props.name} ${props.className || ""}`}
    width={props.width || "1em"}
    height={props.height || "1em"}
    strokeWidth={props.strokeWidth || "1.5"}
  >
    <g fill="none" stroke="currentColor"  strokeLinecap="round" strokeLinejoin="round">
      <path d="M36 21.9L23.1 1.6 10.2 21.9l-5.7-8.7-1.3 4.6c-1 3.7-1.6 7.5-1.6 11.4 0 12 9.6 18.9 21.5 18.9s21.5-6.8 21.5-18.9c0-3.8-.5-7.7-1.6-11.4l-1.3-4.6-5.7 8.7z" />
      <path d="M17.1 21.9l2-3.2 2-3.2 2-3.1M37.6 48.3L6.3 62.4c-1.6.7-2.3 2.7-1.5 4.3.7 1.5 2.4 2.1 3.9 1.4L40 54c1.6-.7 2.3-2.7 1.5-4.3-.8-1.4-2.5-2-3.9-1.4z" />
      <path d="M30.7 58.2l9.3 4.2c1.6.7 2.3 2.7 1.5 4.3-.5 1.1-1.5 1.7-2.6 1.7-.4 0-.8-.1-1.2-.3l-14.4-6.5M15.6 58.2L6.3 54c-1.6-.7-2.3-2.7-1.5-4.3.5-1.1 1.5-1.7 2.6-1.7.4 0 .8.1 1.2.3L23 54.8" />
    </g>
  </svg>
);

export default SVG;
