import * as React from "react";

const SVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 70 46"
    className={`svg-icon icon-${props.name} ${props.className || ""}`}
    width={props.width || "1em"}
    height={props.height || "1em"}
    strokeWidth={props.strokeWidth || "1.5"}
  >
    <g fill="none" stroke="currentColor"  strokeLinecap="round" strokeLinejoin="round">
      <path d="M1.4 44.9h67.2V1.4H1.4z" />
      <path d="M68.6 1.4L35 30.4 1.4 1.4M68.6 44.9L43.4 23.2M26.6 23.2L1.4 44.9" />
    </g>
  </svg>
);

export default SVG;
