import * as React from "react";

const SVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 70 70"
    className={`svg-icon icon-${props.name} ${props.className || ""}`}
    width={props.width || "1em"}
    height={props.height || "1em"}
    strokeWidth={props.strokeWidth || "1.5"}
  >
    <g stroke="currentColor">
      <g fill="currentColor">
        <path
          strokeMiterlimit={10}
          d="M19.945 53.96v-.2c0-1 .2-2 .6-2.9.4-.9 1-1.8 1.6-2.6.7-.8 1.4-1.6 2.2-2.3.9-.7 1.7-1.5 2.5-2.1.7-.6 1.3-1.1 1.9-1.7.6-.6 1.2-1.1 1.6-1.7.5-.6.9-1.3 1.1-2 .3-.7.4-1.5.4-2.3 0-1.9-.6-3.3-1.7-4.1-.9-.9-2.3-1.3-3.9-1.3-.9 0-1.7.1-2.4.3-.8.2-1.4.4-1.9.7s-1 .5-1.3.8c-.1.1-.2.2-.3.2l-.6-.6c.1-.1.2-.2.4-.3.4-.3.9-.6 1.4-.9.6-.3 1.3-.5 2-.8.8-.2 1.6-.3 2.6-.3 2.5 0 4.4.5 5.6 1.6 1.2 1.1 1.8 2.5 1.8 4.5 0 .8-.2 1.6-.5 2.4-.3.8-.8 1.5-1.4 2.2-.6.7-1.3 1.5-2 2.1l-2.4 2.1c-.7.6-1.4 1.2-2 1.8-.7.7-1.3 1.4-1.8 2-.5.6-1 1.3-1.3 1.9-.3.7-.5 1.3-.5 2v.5h12.9v1h-14.6z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          strokeWidth={0.8}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M19.945 53.96v-.2c0-1 .2-2 .6-2.9.4-.9 1-1.8 1.6-2.6.7-.8 1.4-1.6 2.2-2.3.9-.7 1.7-1.5 2.5-2.1.7-.6 1.3-1.1 1.9-1.7.6-.6 1.2-1.1 1.6-1.7.5-.6.9-1.3 1.1-2 .3-.7.4-1.5.4-2.3 0-1.9-.6-3.3-1.7-4.1-.9-.9-2.3-1.3-3.9-1.3-.9 0-1.7.1-2.4.3-.8.2-1.4.4-1.9.7s-1 .5-1.3.8c-.1.1-.2.2-.3.2l-.6-.6c.1-.1.2-.2.4-.3.4-.3.9-.6 1.4-.9.6-.3 1.3-.5 2-.8.8-.2 1.6-.3 2.6-.3 2.5 0 4.4.5 5.6 1.6 1.2 1.1 1.8 2.5 1.8 4.5 0 .8-.2 1.6-.5 2.4-.3.8-.8 1.5-1.4 2.2-.6.7-1.3 1.5-2 2.1l-2.4 2.1c-.7.6-1.4 1.2-2 1.8-.7.7-1.3 1.4-1.8 2-.5.6-1 1.3-1.3 1.9-.3.7-.5 1.3-.5 2v.5h12.9v1h-14.6z"
        />
        <path
          strokeMiterlimit={10}
          d="M46.745 31.96c-.9.9-1.9 1.9-2.8 3-1 1.1-1.9 2.3-2.7 3.5-.9 1.2-1.7 2.4-2.4 3.7-.7 1.2-1.4 2.4-1.9 3.5l-.4.9h11.3v-15.5l-1.1.9zm1 22v-6.7h-12.3v-.7c.5-1.1 1.1-2.3 1.9-3.7.8-1.4 1.8-2.9 2.9-4.4 1.1-1.5 2.2-2.9 3.5-4.4 1.2-1.4 2.4-2.6 3.6-3.7h1.8v16.2h3.4v.7h-3.4v6.7h-1.4z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          strokeWidth={0.8}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M46.745 31.96c-.9.9-1.9 1.9-2.8 3-1 1.1-1.9 2.3-2.7 3.5-.9 1.2-1.7 2.4-2.4 3.7-.7 1.2-1.4 2.4-1.9 3.5l-.4.9h11.3v-15.5l-1.1.9zm1 22v-6.7h-12.3v-.7c.5-1.1 1.1-2.3 1.9-3.7.8-1.4 1.8-2.9 2.9-4.4 1.1-1.5 2.2-2.9 3.5-4.4 1.2-1.4 2.4-2.6 3.6-3.7h1.8v16.2h3.4v.7h-3.4v6.7h-1.4z"
        />
      </g>
      <path
        fill="none"
        
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M57.8 31.4v22.5h.5c1.8 0 3.3-.2 4.7-.7 1.3-.5 2.4-1.1 3.3-2 .9-.9 1.6-1.9 2-3.1.4-1.2.7-2.6.7-4v-2.9c0-1.5-.2-2.8-.7-4-.4-1.2-1.1-2.3-2-3.1-.9-.9-2-1.5-3.3-2s-2.9-.7-4.7-.7h-.5zM12.6 31.4v22.5H12c-1.8 0-3.3-.2-4.7-.7-1.4-.5-2.4-1.1-3.3-2-.9-.9-1.6-1.9-2-3.1-.4-1.2-.7-2.6-.7-4v-2.9c0-1.5.2-2.8.7-4s1.2-2.2 2-3.1c.9-.9 2-1.5 3.3-2s2.9-.7 4.7-.7h.6z"
      />
      <path
        fill="none"
        
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M57.8 54.4V23.9c0-12.4-10.2-22.5-22.6-22.5h0c-12.4 0-22.5 10.1-22.5 22.5l-.1 11.6"
      />
      <path
        fill="none"
        
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M40.8 66.1h4.4c2.2 0 4.2-.3 5.8-.8s2.9-1.3 3.9-2.3 1.7-2.3 2.2-3.8.7-3.2.7-5.2v-5.5"
      />
      <path
        fill="none"
        
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M31.8 63.3h8.6c1.2 0 2.3 1 2.3 2.3v1.1c0 1.2-1 2.3-2.3 2.3h-8.6c-1.2 0-2.3-1-2.3-2.3v-1.1c0-1.3 1.1-2.3 2.3-2.3z"
      />
    </g>
  </svg>
);

export default SVG;
