// tslint:disable
/**
 * TIMFIN Authentication
 * TIMFIN Authentication API.
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Risposta del servizio di autenticazione
 * @export
 * @interface LoginResponse
 */
export interface LoginResponse {
    /**
     * Numero Direzione Generale - id cliente in ambito bancario
     * @type {string}
     * @memberof LoginResponse
     */
    ndg: string;
    /**
     * PRESENT - user may proceed to OTP verification.  MISSING - user needs to register a valid OTP device before proceeding to OTP verification.
     * @type {string}
     * @memberof LoginResponse
     */
    otpDeviceState: LoginResponseOtpDeviceStateEnum;
    /**
     * Phone number that we use to show the message: \"Riporta di seguito il codice di X cifre che hai ricevuto tramite SMS al numero *******661\"
     * @type {string}
     * @memberof LoginResponse
     */
    mobileNumber?: string;
    /**
     * @type {string}
     * @memberof LoginResponse
     */
    username?: string;
    /**
     * True if there is a different mobile number (in AD and AS400)
     * @type {boolean}
     * @memberof LoginResponse
     */
    mobileNumberDifferent: boolean;
}

/**
 * @export
 * @enum {string}
 */
export enum LoginResponseOtpDeviceStateEnum {
    PRESENT = 'PRESENT',
    MISSING = 'MISSING'
}

