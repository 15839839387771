import * as React from "react";

const SVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 90 90"
    className={`svg-icon icon-${props.name} ${props.className || ""}`}
    width={props.width || "1em"}
    height={props.height || "1em"}
    strokeWidth={props.strokeWidth || "1.5"}
  >
    <g fill="none" stroke="currentColor"  strokeLinecap="round" strokeLinejoin="round">
      <path d="M48.7 48.4l5.4-5.4-5.4-5.3" />
      <path d="M54.1 43H35.9v9.3" />
      <path d="M44.8 82.5L7.5 44.8 44.8 7.5l37.7 37.3z" />
    </g>
  </svg>
);

export default SVG;
