import * as React from "react";

const SVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 70 52"
    className={`svg-icon icon-${props.name} ${props.className || ""}`}
    width={props.width || "1em"}
    height={props.height || "1em"}
    strokeWidth={props.strokeWidth || "1.5"}
  >
    <g fill="none" stroke="currentColor"  strokeLinecap="round" strokeLinejoin="round">
      <path d="M12.8 44.8H1V3.9C1 2.3 2.4 1 4 1h55.7c1.6 0 2.9 1.3 2.9 2.9v5.8c0 1.6-1.3 2.9-2.9 2.9H48v32.1H24.5" />
      <path d="M48 21.4h7.5c2.5 0 4.7 1.6 5.6 4l1.6 4.8 2.6 1.3c2 1 3.3 3 3.3 5.2v8.1h-5.9M50.9 44.8H48M24.5 44.8c0 3.2-2.6 5.8-5.9 5.8s-5.9-2.6-5.9-5.8 2.6-5.8 5.9-5.8 5.9 2.6 5.9 5.8z" />
      <path d="M62.6 44.8c0 3.2-2.6 5.8-5.9 5.8s-5.9-2.6-5.9-5.8 2.6-5.8 5.9-5.8 5.9 2.6 5.9 5.8zM9.8 21.4h17.6v-8.7H9.8z" />
    </g>
  </svg>
);

export default SVG;
