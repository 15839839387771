// tslint:disable
/**
 * TIMFIN Authentication
 * TIMFIN Authentication API.
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders, throwIfRequired } from '../runtime';
import {
    AuthorizationRequest,
    AuthorizationResponse,
    ErrorResponse,
    LoginRequest,
    LoginResponse,
    OtpAddDeviceRequest,
    OtpAddDeviceResponse,
    OtpVerificationRequest,
    OtpVerificationResponse,
    PasswordChangeRequest,
    Policies,
    RecoveryRequest,
    TokenInfoRequest,
    TokenInfoResponse,
    UserRegistrationRequest,
    UsernameRecoveryRequest,
} from '../models';

export interface AuthorizeRequest {
    authorizationRequest: AuthorizationRequest;
}

export interface ChangePasswordRequest {
    passwordChangeRequest: PasswordChangeRequest;
}

export interface LoginUserRequest {
    loginRequest: LoginRequest;
}

export interface OtpValidateRequest {
    otpVerificationRequest: OtpVerificationRequest;
}

export interface ReEnableUserRequest {
    recoveryRequest: RecoveryRequest;
}

export interface RecoverPasswordRequest {
    recoveryRequest: RecoveryRequest;
}

export interface RecoverUsernameRequest {
    usernameRecoveryRequest: UsernameRecoveryRequest;
}

export interface RegisterUserRequest {
    userRegistrationRequest: UserRegistrationRequest;
}

export interface SetOtpDeviceRequest {
    otpAddDeviceRequest: OtpAddDeviceRequest;
}

export interface TokenInfoRequest {
    tokenInfoRequest: TokenInfoRequest;
}

/**
 * no description
 */
export class AuthenticationApi extends BaseAPI {

    /**
     * Get information to get token to access to a landing application
     */
    authorize = (requestParameters: AuthorizeRequest): Observable<AuthorizationResponse> => {
        throwIfRequired(requestParameters, 'authorizationRequest', 'authorize');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
        };

        return this.request<AuthorizationResponse>({
            path: '/auth/authorize',
            method: 'POST',
            headers,
            body: requestParameters.authorizationRequest,
        });
    }

    /**
     * Change password.
     */
    changePassword = (requestParameters: ChangePasswordRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'passwordChangeRequest', 'changePassword');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
        };

        return this.request<void>({
            path: '/users/password',
            method: 'POST',
            headers,
            body: requestParameters.passwordChangeRequest,
        });
    }

    /**
     */
    getPolicies = (): Observable<Policies> => {
        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
        };

        return this.request<Policies>({
            path: '/policies',
            method: 'GET',
            headers,
        });
    }

    /**
     * Ref: 2.1  Maps to Login
     * First step of authentication by username, password and recaptcha token
     */
    loginUser = (requestParameters: LoginUserRequest): Observable<LoginResponse> => {
        throwIfRequired(requestParameters, 'loginRequest', 'loginUser');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
        };

        return this.request<LoginResponse>({
            path: '/auth/login',
            method: 'POST',
            headers,
            body: requestParameters.loginRequest,
        });
    }

    /**
     * Request a new OTP to be sent.
     */
    otpResend = (): Observable<void> => {
        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
        };

        return this.request<void>({
            path: '/auth/otp/resend',
            method: 'GET',
            headers,
        });
    }

    /**
     * Verify OTP
     */
    otpValidate = (requestParameters: OtpValidateRequest): Observable<OtpVerificationResponse> => {
        throwIfRequired(requestParameters, 'otpVerificationRequest', 'otpValidate');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
        };

        return this.request<OtpVerificationResponse>({
            path: '/auth/otp',
            method: 'POST',
            headers,
            body: requestParameters.otpVerificationRequest,
        });
    }

    /**
     * Re-enable a disabled user by providing additional details for security.
     */
    reEnableUser = (requestParameters: ReEnableUserRequest): Observable<LoginResponse> => {
        throwIfRequired(requestParameters, 'recoveryRequest', 'reEnableUser');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
        };

        return this.request<LoginResponse>({
            path: '/users/re-enable',
            method: 'POST',
            headers,
            body: requestParameters.recoveryRequest,
        });
    }

    /**
     * Recover password by providing additional details for security.
     */
    recoverPassword = (requestParameters: RecoverPasswordRequest): Observable<LoginResponse> => {
        throwIfRequired(requestParameters, 'recoveryRequest', 'recoverPassword');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
        };

        return this.request<LoginResponse>({
            path: '/users/password/recover',
            method: 'POST',
            headers,
            body: requestParameters.recoveryRequest,
        });
    }

    /**
     * Recover username by providing additional details for security.
     */
    recoverUsername = (requestParameters: RecoverUsernameRequest): Observable<LoginResponse> => {
        throwIfRequired(requestParameters, 'usernameRecoveryRequest', 'recoverUsername');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
        };

        return this.request<LoginResponse>({
            path: '/users/username/recover',
            method: 'POST',
            headers,
            body: requestParameters.usernameRecoveryRequest,
        });
    }

    /**
     * Register new user.
     */
    registerUser = (requestParameters: RegisterUserRequest): Observable<LoginResponse> => {
        throwIfRequired(requestParameters, 'userRegistrationRequest', 'registerUser');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
        };

        return this.request<LoginResponse>({
            path: '/users',
            method: 'POST',
            headers,
            body: requestParameters.userRegistrationRequest,
        });
    }

    /**
     * Adds an OTP device to the user.
     */
    setOtpDevice = (requestParameters: SetOtpDeviceRequest): Observable<OtpAddDeviceResponse> => {
        throwIfRequired(requestParameters, 'otpAddDeviceRequest', 'setOtpDevice');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
        };

        return this.request<OtpAddDeviceResponse>({
            path: '/users/otp-device',
            method: 'POST',
            headers,
            body: requestParameters.otpAddDeviceRequest,
        });
    }

    /**
     * Get authentication parameter
     */
    tokenInfo = (requestParameters: TokenInfoRequest): Observable<TokenInfoResponse> => {
        throwIfRequired(requestParameters, 'tokenInfoRequest', 'tokenInfo');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
        };

        return this.request<TokenInfoResponse>({
            path: '/auth/token-info',
            method: 'POST',
            headers,
            body: requestParameters.tokenInfoRequest,
        });
    }

}
