import * as React from "react";

const SVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 70 70"
    className={`svg-icon icon-${props.name} ${props.className || ""}`}
    width={props.width || "1em"}
    height={props.height || "1em"}
    strokeWidth={props.strokeWidth || "1.5"}
  >
    <g fill="none" stroke="currentColor"  strokeLinecap="round" strokeLinejoin="round">
      <path d="M35 1.4c-8.1 0-16.3 6.4-14.9 14.9L22 31.2c1 6.8 6.1 11.3 13 11.3S47 38 48.1 31.3L50 16.4c1.3-8.6-6.9-15-15-15zM1.4 61.1c0-5.7 7.7-10.3 16.8-10.3 2.2 0 5.3.6 7.5 1 1.6.3 3.2.6 4.7 1s3 .9 4.6.9c1.7 0 4.3-.8 6.4-1.3 3.1-.7 7.2-1.5 10.4-1.5 10.1 0 16.8 4.6 16.8 10.3V64c0 2.6-2.1 4.7-4.7 4.7H6.1c-2.6 0-4.7-2.1-4.7-4.7v-2.9z" />
    </g>
  </svg>
);

export default SVG;
