import get from "lodash.get";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import { AuthorizationResponse, AuthorizeRequest } from "../../generated";
import { authorize } from "../../logic/authentication/actions";
import { IBasicRequest, IState } from "../../logic/authentication/types";
import { IStoreState } from "../../types/store";
import Spinner from "../shared/spinner/spinner";
export interface IDispatchProps {
  authorize: (authorizationRequest: AuthorizeRequest) => void;
}
export interface IStateProps {
  authorizationResponse: IBasicRequest & AuthorizationResponse;
}

const DummyApplication: React.FunctionComponent<IDispatchProps & IStateProps> = ({
  authorize,
  authorizationResponse,
}) => {
  useEffect(() => {
    authorize({ authorizationRequest: { scope: "area-riservata" } });
  }, []);

  if (get(authorizationResponse, "error.status") === 200) {
    if (!authorizationResponse.redirectUri) {
      return <Redirect to="/server-error" />;
    }
    if (window.Cypress) {
      // If Cypress is running we skip the redirect to avoid dealing with basic auth
      return null;
    } else {
      // Redirect to authorized Application
      window.location.replace(get(authorizationResponse, "redirectUri"));
    }
  }

  return (
    <>
      {authorizationResponse.loading && (
        <div className="redirect-spinner">
          <Spinner />
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: IStoreState): IState => ({
  authorizationResponse: state.authentication.authorizationResponse,
});

const mapDispatchToProps: IDispatchProps = {
  authorize,
};

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(DummyApplication));
