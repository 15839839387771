import * as React from "react";

const SVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 70 70"
    className={`svg-icon icon-${props.name} ${props.className || ""}`}
    width={props.width || "1em"}
    height={props.height || "1em"}
    strokeWidth={props.strokeWidth || "1.5"}
  >
    <g fill="none" stroke="currentColor"  strokeLinejoin="round">
      <path strokeLinecap="round" d="M1.3 68.9l2.8-.5c20.5-3.5 41.4-3.5 61.9 0l2.8.5" />
      <path
        strokeLinecap="square"
        d="M35.1 24.1v40.8M35.1 39.9c0 1.5-1.2 2.7-2.7 2.7H22.1c-14 0-20.8-9.8-20.8-21.8v-.5c3.3-1.4 7.1-2.1 11.1-2.1h.6c12 0 22.1 6.8 22.1 18.1v3.6zM35.1 23c0 1.5 1.2 2.7 2.7 2.7H48c14 0 20.8-9.6 20.8-21.6v-.7c-3.2-1.4-7-2.1-11-2.1h-.6c-12 0-22.1 6.8-22.1 20.8v.9z"
      />
    </g>
  </svg>
);

export default SVG;
