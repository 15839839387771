import * as React from "react";

const SVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 70 70"
    className={`svg-icon icon-${props.name} ${props.className || ""}`}
    width={props.width || "1em"}
    height={props.height || "1em"}
    strokeWidth={props.strokeWidth || "1.5"}
  >
    <g fill="none" stroke="currentColor"  strokeLinecap="round" strokeLinejoin="round">
      <path d="M42.1 28.6l4.4-7.8L12.8 1.4 1.4 21.2l8 4.6" />
      <path d="M47.7 37.4l2.4-4.2-33.7-19.4L4.9 33.6l22.7 13.1" />
      <path d="M31.7 33.4c0 2.3-1.9 4.2-4.2 4.2s-4.2-1.9-4.2-4.2 1.9-4.2 4.2-4.2c2.3.1 4.2 1.9 4.2 4.2zM52.7 50.9l-21 4.6c-2.2.4-4.3-.9-4.9-3-1-3.1.5-6.4 3.4-7.6l15.1-6.6c4.8-2.1 10.2-2.1 14.9 0l8.5 3.7v19.5h-6.8c-1.4 0-2.8.2-4.2.6L38 68c-2.1.7-4.4.7-6.6-.1L2.4 55c-.9-.4-1.3-1.5-.8-2.4l.4-1c.9-1.7 2.8-2.7 4.7-2.4l19.7 3.4" />
    </g>
  </svg>
);

export default SVG;
