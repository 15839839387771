import * as React from "react";

const SVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 70 70"
    className={`svg-icon icon-${props.name} ${props.className || ""}`}
    width={props.width || "1em"}
    height={props.height || "1em"}
    strokeWidth={props.strokeWidth || "1.5"}
  >
    <g fill="none" stroke="currentColor"  strokeLinecap="round" strokeLinejoin="round">
      <path d="M14.9 33.8L29.1 48l37.5-37.6" />
      <path d="M67.7 26.3c.7 2.8 1.1 5.7 1.1 8.7 0 18.7-15.2 33.8-33.8 33.8C16.3 68.8 1.1 53.7 1.1 35 1.1 16.3 16.3 1.1 35 1.1c7 0 13.5 2.1 18.9 5.8" />
    </g>
  </svg>
);

export default SVG;
