import * as React from "react";

const SVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 67 70"
    className={`svg-icon icon-${props.name} ${props.className || ""}`}
    width={props.width || "1em"}
    height={props.height || "1em"}
    strokeWidth={props.strokeWidth || "1.5"}
  >
    <g transform="translate(1 1)">
      <path
        fill="none"
        stroke="currentColor"
        
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M61.7 36.7c.1.7.1 1.5.1 2.2C61.8 55 48 68 30.9 68S0 55 0 38.9 13.8 9.7 30.9 9.7c.8 0 1.6 0 2.3.1-.5.8-.8 1.8-.8 2.8 0 1.7.8 3.3 2.1 4.4-1.3 1.1-2.1 2.6-2.1 4.4 0 3.2 2.8 5.8 6.2 5.8 1.4 0 2.7-.5 3.8-1.2-.4.8-.7 1.7-.7 2.7 0 3.2 2.8 5.8 6.2 5.8 1.9 0 3.6-.8 4.7-2 .4 2.8 3 5 6.1 5 1.1-.1 2.1-.4 3-.8z"
      />
      <ellipse fillRule="evenodd" clipRule="evenodd" fill="currentColor" cx={25.8} cy={22.3} rx={3.1} ry={2.9} />
      <ellipse fillRule="evenodd" clipRule="evenodd" fill="currentColor" cx={19.6} cy={51.5} rx={3.1} ry={2.9} />
      <ellipse fillRule="evenodd" clipRule="evenodd" fill="currentColor" cx={36.1} cy={55.4} rx={3.1} ry={2.9} />
      <ellipse fillRule="evenodd" clipRule="evenodd" fill="currentColor" cx={48.4} cy={43.7} rx={3.1} ry={2.9} />
      <ellipse fillRule="evenodd" clipRule="evenodd" fill="currentColor" cx={13.4} cy={35.9} rx={3.1} ry={2.9} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
        d="M57.7 17.5l2.2 6.8 6.1-6.8zM54.2-.3l-6.7 8.8 14.1 1.7z"
      />
    </g>
  </svg>
);

export default SVG;
