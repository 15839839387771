import { Form, Formik, FormikProps } from "formik";
import get from "lodash.get";
import React, { useEffect, useState } from "react";
import { defineMessages, FormattedHTMLMessage, FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import * as Yup from "yup";
import { LoginResponse, LoginResponseOtpDeviceStateEnum, Policies } from "../../generated";
import { LoginRequest } from "../../generated/models/LoginRequest";
import { clearToken, getPolicies, loginUser, resetResponseCode } from "../../logic/authentication/actions";
import {
  ERROR_REQUEST_BLOCKED_OTP,
  ERROR_REQUEST_BLOCKED_USER,
  ERROR_REQUEST_DISABLED_USER,
  LOGIN_RESET_RESPONSE,
  ERROR_REQUEST_MIGRATED_WITHOUT_NUMBER,
} from "../../logic/authentication/consts";
import { IBasicRequest, IState } from "../../logic/authentication/types";
import { IStoreState } from "../../types/store";
import FormAuth from "../shared/form_auth/form_auth";
import ModalServerError from "../shared/modals/modal_server_error";
import ModalUserBlocked from "../shared/modals/modal_user_blocked";
import ModalUserDisabled from "../shared/modals/modal_user_disabled";
import ReCaptcha, { execute, reCaptchaPubKey } from "../shared/recaptcha/recaptcha";
import Spinner from "../shared/spinner/spinner";
import Button, { ButtonColors } from "../ui_kit/button/button";
import { InputPassword } from "../ui_kit/form/input_password";
import { InputText } from "../ui_kit/form/input_text";
import "./_form_login.scss";
import ModalRegisterAgain from "../shared/modals/modal_register_again";
import { ENABLE_PRESTITO_AD } from "../../config/constants";
import PrestitoAd from "../PrestitoAd/PrestitoAd";

export interface IDispatchProps {
  getPolicies: () => void;
  loginUser: (loginRequest: LoginRequest) => void;
  resetResponseCode: (action: string) => void;
  clearToken: () => void;
}
export interface IStateProps {
  policies: Policies;
  loginResponse: IBasicRequest & LoginResponse;
}

const translations = defineMessages({
  required: {
    defaultMessage: "Campo obbligatorio",
    description: "campo obbligatorio",
    id: "generic.required",
  },
});

const initialState = {
  password: "",
  recaptchaToken: "",
  username: "",
};

const getValidationSchema = (t: any) => {
  return Yup.object().shape({
    password: Yup.string().required(t(translations.required)),
    recaptchaToken: Yup.string(),
    username: Yup.string().required(t(translations.required)),
  });
};

type Props = IStateProps & IDispatchProps;

const FormLogin: React.FunctionComponent<Props> = ({
  clearToken,
  getPolicies,
  loginUser,
  loginResponse,
  policies,
  resetResponseCode,
  intl: { formatMessage: t },
}) => {
  useEffect(() => {
    clearToken();
  }, []);

  useEffect(() => {
    getPolicies();
  }, []);

  useEffect(() => {
    return () => {
      resetResponseCode(LOGIN_RESET_RESPONSE);
    };
  }, []);

  //  const reCaptchaRef = useRef(null);
  const [recaptchaPending, setRecaptchaPending] = useState(false);

  const formikSubmit = (values: any, actions: any) => {
    execute({ sitekey: reCaptchaPubKey, callback: recaptchaCallBack(values), action: "login" });
    setRecaptchaPending(true);
  };

  const recaptchaCallBack = (values: any) => (token: any) => {
    if (token) {
      values.recaptchaToken = token;
    }
    // se token === null, il middleware restiuirà un errore
    loginUser(values);
    setRecaptchaPending(false);
  };

  if (get(loginResponse, "error.status") === 200) {
    if (!loginResponse.ndg) {
      return <Redirect to="/server-error" />;
    }
    if (get(loginResponse, "otpDeviceState") === LoginResponseOtpDeviceStateEnum.PRESENT) {
      // OTP Device present
      return <Redirect to="/auth/otp" />;
    } else {
      // in caso di mancanza faccio redirect su pagina aggiunta device
      return <Redirect to="/users/add-otp-device" />;
    }
  }

  const closeModalHandler = () => {
    resetResponseCode(LOGIN_RESET_RESPONSE);
  };

  return (
    <FormAuth
      hasRecaptcha={true}
      isLoginPage={true}
      pageContainerClass="login-container"
      title={<FormattedHTMLMessage id="form_login.title" defaultMessage="Accedi" />}
      showRequired={true}
      content={
        <>
          <Formik initialValues={initialState} validationSchema={getValidationSchema(t)} onSubmit={formikSubmit}>
            {(formikProps: FormikProps<any>) => (
              <Form>
                <InputText
                  autoComplete="off"
                  label={<FormattedMessage id="form_login.username" defaultMessage="Username*" />}
                  name="username"
                />
                <InputPassword
                  autoComplete="off"
                  label={<FormattedMessage id="form_login.password" defaultMessage="Password*" />}
                  name="password"
                />
                <ReCaptcha action="login" callback={recaptchaCallBack(formikProps)} sitekey={reCaptchaPubKey} />
                {get(loginResponse, "error.status") === 400 && (
                  <div className="form-error">{get(loginResponse, "error.response.description")}</div>
                )}
                {loginResponse.loading || recaptchaPending ? (
                  <Spinner />
                ) : (
                  <Button data-cy="button-submit" disabled={!formikProps.isValid} inverted={false} type="submit">
                    <FormattedMessage id="form_login.continue" defaultMessage="Prosegui" />
                    {/* <Icon name="arrow-right" className="icon-blue" strokeWidth="2" /> */}
                  </Button>
                )}
              </Form>
            )}
          </Formik>
        </>
      }
      actions={
        <>
          <Link to="/users/new" className="registrati double-height" data-cy="register-user">
            <FormattedMessage id="form_login.register" defaultMessage="Non sei ancora registrato? Registrati" />
          </Link>
          <Link to="/users/recover-password" className="recover-password" data-cy="recover-password">
            <FormattedMessage id="form_login.request_password" defaultMessage="Hai dimenticato la password?" />
          </Link>
          <Link to="/users/recover-username" className="recover-username" data-cy="recover-username">
            <FormattedMessage id="form_login.recover_username" defaultMessage="Hai dimenticato lo username?" />
          </Link>
        </>
      }
      footer={ENABLE_PRESTITO_AD && <PrestitoAd />}
      modals={
        <>
          {get(loginResponse, "error.status") === 403 &&
            [ERROR_REQUEST_BLOCKED_USER, ERROR_REQUEST_BLOCKED_OTP].includes(
              get(loginResponse, "error.response.code")
            ) && <ModalUserBlocked onCloseEnd={closeModalHandler} />}
          {get(loginResponse, "error.status") === 403 &&
            get(loginResponse, "error.response.code") === ERROR_REQUEST_DISABLED_USER && (
              <ModalUserDisabled onCloseEnd={closeModalHandler} />
            )}
          {get(loginResponse, "error.status") === 403 &&
            get(loginResponse, "error.response.code") === ERROR_REQUEST_MIGRATED_WITHOUT_NUMBER && (
              <ModalRegisterAgain onCloseEnd={closeModalHandler} />
            )}
          {get(loginResponse, "error.status") >= 500 && <ModalServerError onCloseEnd={closeModalHandler} />}
        </>
      }
    />
  );
};

const mapStateToProps = (state: IStoreState): IState => ({
  loginResponse: state.authentication.loginResponse,
  policies: state.authentication.policies,
});

const mapDispatchToProps: IDispatchProps = {
  clearToken,
  getPolicies,
  loginUser,
  resetResponseCode,
};

const withIntlComponent = injectIntl(FormLogin);

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(withIntlComponent));
