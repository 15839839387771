import produce from "immer";
import get from "lodash.get";
import { Middleware, RequestArgs, ResponseArgs } from "../../generated";
import { store as reduxStore } from "../../index";
import { setToken as setTokenAction } from "../../logic/authentication/actions";

type getTokenFn = () => string | undefined;
/**
 * Gets the token from the Store
 */
export const getToken: getTokenFn = () => {
  const store = reduxStore.getState();
  return get(store, "authentication.jwt");
};

export const setToken = (token: string) => {
  reduxStore.dispatch(setTokenAction(token));
};

interface IAuthToken {
  Authorization: string;
}
type getTokenHeaderFn = () => IAuthToken | undefined;
/**
 * Gets the token from the storage and generates an Authorization
 */
export const getTokenHeader: getTokenHeaderFn = () => {
  const token = getToken();

  if (!token) {
    return undefined;
  }

  return { Authorization: `Bearer ${token}` };
};

export const tokenMiddleware: Middleware = {
  pre(request: RequestArgs): RequestArgs {
    const modifiedRequest: RequestArgs = produce(request, (draft) => {
      const tokenHeader = getTokenHeader();

      if (!draft.headers) {
        draft.headers = {};
      }

      if (tokenHeader) {
        Object.keys(tokenHeader).forEach((header) => {
          draft.headers![header] = tokenHeader[header];
        });
      }
    });

    return modifiedRequest;
  },

  post(response: ResponseArgs): ResponseArgs {
    if (response.xhr.getResponseHeader("Authorization")) {
      setToken(response.xhr.getResponseHeader("Authorization")!.replace("Bearer ", ""));
    }
    return response;
  },
};
