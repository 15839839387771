import * as React from "react";
import {
  ENABLE_ONETRUST,
  ONETRUST_BLOCK_SCRIPT_SRC,
  ONETRUST_DOMAIN_SCRIPT,
  ONETRUST_SRC,
} from "../../config/constants";
import "./OneTrust.scss";

interface IProps {
  children: React.ReactNode | React.ReactNodeArray;
}

const OneTrust: React.FunctionComponent<IProps> = ({ children }) => {
  React.useEffect(() => {
    if (ENABLE_ONETRUST) {
      if (ONETRUST_BLOCK_SCRIPT_SRC) {
        const blockScript = document.createElement("script");
        blockScript.src = ONETRUST_BLOCK_SCRIPT_SRC;
        blockScript.async = true;
        blockScript.type = "text/javascript";
        document.body.appendChild(blockScript);
      }

      if (ONETRUST_DOMAIN_SCRIPT && ONETRUST_SRC) {
        const script = document.createElement("script");
        script.src = ONETRUST_SRC;
        script.setAttribute("data-domain-script", ONETRUST_DOMAIN_SCRIPT);
        script.async = true;
        script.type = "text/javascript";
        document.body.appendChild(script);
      }

      // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
      const OptanonWrapper = () => {};

      return () => {
        if (ONETRUST_BLOCK_SCRIPT_SRC) {
          document.body.removeChild(blockScript);
        }
        if (ONETRUST_DOMAIN_SCRIPT && ONETRUST_SRC) {
          document.body.removeChild(script);
        }
      };
    }
  }, []);

  return <>{children}</>;
};

export default OneTrust;
