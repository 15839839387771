import * as React from "react";

const SVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 48 70"
    className={`svg-icon icon-${props.name} ${props.className || ""}`}
    width={props.width || "1em"}
    height={props.height || "1em"}
    strokeWidth={props.strokeWidth || "1.5"}
  >
    <g fill="none" stroke="currentColor">
      <path
        
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M29.3 23.7C40.2 26 47 32.8 47 47.7v16.4c0 2.5-2 4.5-4.6 4.5H17.3v-4.9c0-.5-.1-1-.4-1.5l-6.8-11.6"
      />
      <path
        strokeMiterlimit={10}
        d="M29.3 35.5V6c0-2.5-2.1-4.6-4.6-4.6H6c-2.5 0-4.6 2-4.6 4.6v40.2c0 2.5 2.1 4.4 4.6 4.4h16.8"
      />
      <path
        
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M29.3 36.1V6c0-2.5-2.1-4.6-4.6-4.6H6c-2.5 0-4.6 2-4.6 4.6v40.2c0 2.5 2.1 4.4 4.6 4.4h17"
      />
      <path
        
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M35.8 42.9L23.7 30.8c-.6-.6-1.3-1-2-1.4-1.7-.8-3.6-1-5.4-.6-2.3.6-3.4 3.2-2.2 5.2l8.7 14.4c0 3.3 1.6 6.9 4 9.3M21.8 7.4h-7M10.3 7.4h-.4"
      />
    </g>
  </svg>
);

export default SVG;
