import React, { useEffect, useState } from "react";
import { RECAPTCHA_KEY } from "../../../config/constants";
import "./_recaptcha.scss";

let readyCheck: any;

export const reCaptchaPubKey = RECAPTCHA_KEY;

export interface IProps {
  action: string;
  elementID?: string | "g-recaptcha";
  sitekey: string;
  recaptchaCallbackName?: string | "recaptchaCallback";
  callback?: (e: any) => void;
}

const ReCaptcha = (props: IProps) => {
  useEffect(() => {
    loadReCaptcha(props.sitekey);
    return () => {
      document.querySelector(`.${recaptchaClass}`)!.outerHTML = "";
    };
  }, []);

  const isReady = () =>
    typeof window !== "undefined" &&
    typeof window.grecaptcha !== "undefined" &&
    typeof window.grecaptcha.execute !== "undefined";

  const [isCaptchaReady, setIsCaptchaReady] = useState(() => isReady());
  // useReadyChecker(isCaptchaReady, props);
  const updateReadyState = () => {
    if (isReady()) {
      setIsCaptchaReady(true);
      clearInterval(readyCheck);
    }
  };
  if (!isCaptchaReady) {
    readyCheck = setInterval(updateReadyState, 1000);
  }

  return isCaptchaReady ? (
    <div id={props.elementID} data-recaptchacallbackname={props.recaptchaCallbackName} />
  ) : (
    <div id={props.elementID} className="g-recaptcha" />
  );
};

export const execute = (props: IProps) => {
  const { sitekey, callback, action } = props;

  if (callback) {
    window.grecaptcha.execute(sitekey, { action }).then(
      (token: string) => {
        // if (typeof verifyCallback !== "undefined") {
        callback(token);
        // }
      },
      (err: any) => {
        callback(err);
      }
    );
  }
};
// const useReadyChecker = (readyState: any, props: IProps) => {
//   useEffect(() => {
//     if (readyState) {
//       execute(readyState, props);
//     }
//     return () => {
//       clearInterval(readyCheck);
//     };
//   }, [readyState]);
// };

const recaptchaClass = "__recaptcha__";

export const loadReCaptcha = (siteKey: string) => {
  const script = document.createElement("script");
  script.async = true;
  script.defer = true;
  // script.crossOrigin = null;
  script.classList.add(recaptchaClass);
  script.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}`;
  document.body.appendChild(script);
};

export default ReCaptcha;
