import React, { CSSProperties } from "react";

import "./icon.scss";
import AdhesiveBandage from "./svgr/output/AdhesiveBandage";
import Airplane from "./svgr/output/Airplane";
import Armchair from "./svgr/output/Armchair";
import ArrowLeft from "./svgr/output/ArrowLeft";
import ArrowLeftBoxed from "./svgr/output/ArrowLeftBoxed";
import ArrowRight from "./svgr/output/ArrowRight";
import ArrowRightBoxed from "./svgr/output/ArrowRightBoxed";
import ArrowsUpDown from "./svgr/output/ArrowsUpDown";
import Assets from "./svgr/output/Assets";
import Assistance from "./svgr/output/Assistance";
import AssistanceCounter from "./svgr/output/AssistanceCounter";
import Asterisks from "./svgr/output/Asterisks";
import Barbell from "./svgr/output/Barbell";
import Bell from "./svgr/output/Bell";
import Bicycle from "./svgr/output/Bicycle";
import Briefcase from "./svgr/output/Briefcase";
import Building from "./svgr/output/Building";
import Calculator from "./svgr/output/Calculator";
import Camper from "./svgr/output/Camper";
import CampFire from "./svgr/output/CampFire";
import Car from "./svgr/output/Car";
import Cart from "./svgr/output/Cart";
import CheckKo from "./svgr/output/CheckKo";
import CheckOk from "./svgr/output/CheckOk";
import ChevronDown from "./svgr/output/ChevronDown";
import ChevronUp from "./svgr/output/ChevronUp";
import Cloche from "./svgr/output/Cloche";
import Clock from "./svgr/output/Clock";
import Close from "./svgr/output/Close";
import Collect from "./svgr/output/Collect";
import Contract from "./svgr/output/Contract";
import Cookie from "./svgr/output/Cookie";
import CreditCard from "./svgr/output/CreditCard";
import CreditCardInsert from "./svgr/output/CreditCardInsert";
import CreditCardLeaning from "./svgr/output/CreditCardLeaning";
import Dashboard from "./svgr/output/Dashboard";
import Deposit from "./svgr/output/Deposit";
import DoneBrokenCircle from "./svgr/output/DoneBrokenCircle";
import DoneCircle from "./svgr/output/DoneCircle";
import Email from "./svgr/output/Email";
import EmptyCircle from "./svgr/output/EmptyCircle";
import Engine from "./svgr/output/Engine";
import Fax from "./svgr/output/Fax";
import GraphNodes from "./svgr/output/GraphNodes";
import Group from "./svgr/output/Group";
import Growth from "./svgr/output/Growth";
import HandCard from "./svgr/output/HandCard";
import HandEuro from "./svgr/output/HandEuro";
import HeartRate from "./svgr/output/HeartRate";
import House from "./svgr/output/House";
import HouseRounded from "./svgr/output/HouseRounded";
import HouseSearch from "./svgr/output/HouseSearch";
import Key from "./svgr/output/Key";
import Leave from "./svgr/output/Leave";
import LifeBuoy from "./svgr/output/Lifebuoy";
import Loan from "./svgr/output/Loan";
import LogoDesktop from "./svgr/output/LogoDesktop";
import LogoMobile from "./svgr/output/LogoMobile";
import Marker from "./svgr/output/Marker";
import Menu from "./svgr/output/Menu";
import MinusCircle from "./svgr/output/MinusCircle";
import Motorcycle from "./svgr/output/Motorcycle";
import PadLock from "./svgr/output/PadLock";
import Phone from "./svgr/output/Phone";
import PhoneRinging from "./svgr/output/PhoneRinging";
import Photo from "./svgr/output/Photo";
import PiggyBank from "./svgr/output/PiggyBank";
import PlusCircle from "./svgr/output/PlusCircle";
import Portfolio from "./svgr/output/Portfolio";
import Question from "./svgr/output/Question";
import Search from "./svgr/output/Search";
import Seedling from "./svgr/output/Seedling";
import Service from "./svgr/output/Service";
import Ship from "./svgr/output/Ship";
import SoccerBall from "./svgr/output/SoccerBall";
import SpeechBubble from "./svgr/output/SpeechBubble";
import Stethoscope from "./svgr/output/Stethoscope";
import SunUmbrella from "./svgr/output/SunUmbrella";
import TapDripping from "./svgr/output/TapDripping";
import Train from "./svgr/output/Train";
import TurnSign from "./svgr/output/TurnSign";
import Ukulele from "./svgr/output/Ukulele";
import Umbrella from "./svgr/output/Umbrella";
import User from "./svgr/output/User";
import UserCircle from "./svgr/output/UserCircle";
import Withdraw from "./svgr/output/Withdraw";

export interface IProps {
  name: string; // "menu" | "search";
  style?: CSSProperties;
  width?: string;
  className?: string;
  viewBox?: string;
  strokeWidth?: string;
}

const Icon: React.FunctionComponent<IProps> = props => {
  switch (props.name) {
    case "adhesive-bandage":
      return <AdhesiveBandage {...props} />;
    case "airplane":
      return <Airplane {...props} />;
    case "armchair":
      return <Armchair {...props} />;
    case "arrow-left-boxed":
      return <ArrowLeftBoxed {...props} />;
    case "arrow-left":
      return <ArrowLeft {...props} />;
    case "arrow-right-boxed":
      return <ArrowRightBoxed {...props} />;
    case "arrow-right":
      return <ArrowRight {...props} />;
    case "arrows-up-down":
      return <ArrowsUpDown {...props} />;
    case "assets":
      return <Assets {...props} />;
    case "assistance-counter":
      return <AssistanceCounter {...props} />;
    case "assistance":
      return <Assistance {...props} />;
    case "asterisks":
      return <Asterisks {...props} />;
    case "barbell":
      return <Barbell {...props} />;
    case "bell":
      return <Bell {...props} />;
    case "bicycle":
      return <Bicycle {...props} />;
    case "briefcase":
      return <Briefcase {...props} />;
    case "building":
      return <Building {...props} />;
    case "calculator":
      return <Calculator {...props} />;
    case "camper":
      return <Camper {...props} />;
    case "camp-fire":
      return <CampFire {...props} />;
    case "car":
      return <Car {...props} />;
    case "cart":
      return <Cart {...props} />;
    case "check-ok":
      return <CheckOk {...props} />;
    case "check-ko":
      return <CheckKo {...props} />;
    case "chevron-down":
      return <ChevronDown {...props} />;
    case "chevron-up":
      return <ChevronUp {...props} />;
    case "cloche":
      return <Cloche {...props} />;
    case "clock":
      return <Clock {...props} />;
    case "close":
      return <Close {...props} />;
    case "collect":
      return <Collect {...props} />;
    case "contract":
      return <Contract {...props} />;
    case "cookie":
      return <Cookie {...props} />;
    case "credit-card-insert":
      return <CreditCardInsert {...props} />;
    case "credit-card-leaning":
      return <CreditCardLeaning {...props} />;
    case "credit-card":
      return <CreditCard {...props} />;
    case "dashboard":
      return <Dashboard {...props} />;
    case "deposit":
      return <Deposit {...props} />;
    case "done-broken-circle":
      return <DoneBrokenCircle {...props} />;
    case "done-circle":
      return <DoneCircle {...props} />;
    case "email":
      return <Email {...props} />;
    case "engine":
      return <Engine {...props} />;
    case "fax":
      return <Fax {...props} />;
    case "graph-nodes":
      return <GraphNodes {...props} />;
    case "group":
      return <Group {...props} />;
    case "growth":
      return <Growth {...props} />;
    case "hand-card":
      return <HandCard {...props} />;
    case "hand-euro":
      return <HandEuro {...props} />;
    case "heart-rate":
      return <HeartRate {...props} />;
    case "house-rounded":
      return <HouseRounded {...props} />;
    case "house-search":
      return <HouseSearch {...props} />;
    case "house":
      return <House {...props} />;
    case "key":
      return <Key {...props} />;
    case "leave":
      return <Leave {...props} />;
    case "life-buoy":
      return <LifeBuoy {...props} />;
    case "loan":
      return <Loan {...props} />;
    case "logo-desktop":
      return <LogoDesktop {...props} />;
    case "logo-mobile":
      return <LogoMobile {...props} />;
    case "marker":
      return <Marker {...props} />;
    case "menu":
      return <Menu {...props} />;
    case "minus-circle":
      return <MinusCircle {...props} />;
    case "motorcycle":
      return <Motorcycle {...props} />;
    case "pad-lock":
      return <PadLock {...props} />;
    case "phone":
      return <Phone {...props} />;
    case "phone-ringing":
      return <PhoneRinging {...props} />;
    case "photo":
      return <Photo {...props} />;
    case "piggy-bank":
      return <PiggyBank {...props} />;
    case "plus-circle":
      return <PlusCircle {...props} />;
    case "empty-circle":
      return <EmptyCircle {...props} />;
    case "portfolio":
      return <Portfolio {...props} />;
    case "question":
      return <Question {...props} />;
    case "search":
      return <Search {...props} />;
    case "seedling":
      return <Seedling {...props} />;
    case "service":
      return <Service {...props} />;
    case "ship":
      return <Ship {...props} />;
    case "soccer-ball":
      return <SoccerBall {...props} />;
    case "speech-bubble":
      return <SpeechBubble {...props} />;
    case "stethoscope":
      return <Stethoscope {...props} />;
    case "sun-umbrella":
      return <SunUmbrella {...props} />;
    case "tap-dripping":
      return <TapDripping {...props} />;
    case "turn-sign":
      return <TurnSign {...props} />;
    case "train":
      return <Train {...props} />;
    case "ukulele":
      return <Ukulele {...props} />;
    case "umbrella":
      return <Umbrella {...props} />;
    case "user-circle":
      return <UserCircle {...props} />;
    case "user":
      return <User {...props} />;
    case "withdraw":
      return <Withdraw {...props} />;
    default:
      return <div>Invalid icon</div>;
  }
};

export default Icon;
