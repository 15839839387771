import * as React from "react";

const SVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 70 70"
    className={`svg-icon icon-${props.name} ${props.className || ""}`}
    width={props.width || "1em"}
    height={props.height || "1em"}
    strokeWidth={props.strokeWidth || "1.5"}
  >
    <g fill="none" stroke="currentColor">
      <path
        
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M40.7 19.3c3 .6 5.7 1.7 8.3 3.2.5.3 1.2.3 1.7 0l.6-.3c3.6-2.1 7.6-1.2 11.2.9l-4.9 8.3 2.6 4.5c1.4 2.4 4 3.9 6.8 3.9h.4l.2.3c1.4 2.4 1.4 5.3 0 7.7l-.2.2c-1.4 2.3-3.8 3.9-6.5 4.1-.5.1-1 .4-1.3.8L56.4 58c-.2.3-.2.5-.2.8v8c0 .9-.8 1.7-1.7 1.7h-5.4c-1.8 0-2.5-.5-3.1-1.8l-.9-1.9c-.6-1-1.7-1.7-3-1.7H35M34.9 59.1v7.8c0 .9-.8 1.7-1.7 1.7h-9.3c-.6 0-1.2-.3-1.5-.8l-2.4-4c-2.5-4.2-9.2-13.1-9.2-21.5 0-12.2 8-20.7 18.5-22.9"
      />
      <path strokeMiterlimit={10} d="M26.3 26.1s4.4-2.2 8.8-2.2 8.8 2.2 8.8 2.2" />
      <path
        
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M26.3 26.1s4.4-2.2 8.8-2.2 8.8 2.2 8.8 2.2M35.1 1.4c5.5 0 10 4.4 10 9.8s-4.5 9.8-10 9.8-10-4.4-10-9.8 4.5-9.8 10-9.8zM49.1 38.3c-1.6 0-3-1.3-3-2.9 0-1.6 1.3-2.9 3-2.9s3 1.3 3 2.9c0 1.6-1.3 2.9-3 2.9M2.9 37.8l4 7.1c.8 1.5.4 3.2-1 4.1-1.3.8-2.9.6-3.9-.6 0-.1-.1-.1-.1-.2-1.3-1.8.1-4.3 2.3-4.3h6.7"
      />
    </g>
  </svg>
);

export default SVG;
