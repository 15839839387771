import classNames from "classnames";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { NavLink } from "react-router-dom";
import Icon from "../icon/icon";
import "./section_title.scss";

interface IProps {
  backURL?: string;
  centered?: boolean;
  children: React.ReactNode;
  className?: string;
  // titleTag could be typed as "div" | "h1" | ... | any.
  // However, "string" makes TS complain.
  // Also look here: https://github.com/Microsoft/TypeScript/issues/8549
  titleTag?: any;
}

const SectionTitle: React.FunctionComponent<IProps> = ({
  backURL,
  centered = true,
  children,
  className,
  titleTag = "h3",
}) => {
  const TitleTag = React.createElement(titleTag, null, children);

  const classes = classNames({
    section__title: true,
    "section__title--centered": centered,
    ...(className ? { [className]: className } : {}),
  });

  const titleColumnClasses = classNames({
    col: true,
    s12: !backURL,
    s8: backURL, // m8 and not s10 to keep it aligned to the center of the page
  });

  return (
    <div className={classes}>
      <div className="container__row row">
        {backURL ? (
          <div className="col s2">
            <NavLink to={backURL} className="section__back-link">
              <Icon name="arrow-left" className="section__back-icon" />
              <span className="section__back-text">
                <FormattedMessage id="section_title.go_back" defaultMessage="Indietro" />
              </span>
            </NavLink>
          </div>
        ) : null}
        <div className={titleColumnClasses}>{TitleTag}</div>
      </div>
    </div>
  );
};

export default React.memo(SectionTitle);
