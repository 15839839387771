import * as React from "react";

const SVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 70 52"
    className={`svg-icon icon-${props.name} ${props.className || ""}`}
    width={props.width || "1em"}
    height={props.height || "1em"}
    strokeWidth={props.strokeWidth || "1.5"}
  >
    <g fill="none" stroke="currentColor"  strokeLinecap="round" strokeLinejoin="round">
      <path d="M62.8 50.8h6.1V35.3h-6.1z" />
      <path d="M56.6 47.7h6.2v-9.3h-6.2z" />
      <path d="M53.5 50.8h-43c-1.7 0-3.1-1.4-3.1-3.1v-31c0-1.7 1.4-3.1 3.1-3.1h43.1c1.7 0 3.1 1.4 3.1 3.1v31c-.1 1.7-1.5 3.1-3.2 3.1zM1.2 13.6V26M1.2 19.8h6.2M7.4 47.7H4.3c-1.7 0-3.1-1.4-3.1-3.1v-6.2c0-1.7 1.4-3.1 3.1-3.1h3.1v12.4zM16.6 7.4h-6.1V4.3c0-1.7 1.4-3.1 3.1-3.1s3.1 1.4 3.1 3.1v3.1zM28.9 7.4h-6.2V4.3c0-1.7 1.4-3.1 3.1-3.1s3.1 1.4 3.1 3.1v3.1zM41.2 7.4H35V4.3c0-1.7 1.4-3.1 3.1-3.1 1.7 0 3.1 1.4 3.1 3.1v3.1zM53.5 7.4h-6.2V4.3c0-1.7 1.4-3.1 3.1-3.1 1.7 0 3.1 1.4 3.1 3.1v3.1zM13.5 7.4v6.2M25.8 7.4v6.2M38.2 7.4v6.2M50.5 7.4v6.2M7.4 22.9h49.2M56.6 35.3H23.2" />
      <path d="M23.2 35.3h-3.5c-1.7 0-3.1-1.4-3.1-3.1v-3.5M32.4 35.3h-3.5c-1.7 0-3.1-1.4-3.1-3.1v-3.5M41.6 35.3h-3.5c-1.7 0-3.1-1.4-3.1-3.1v-3.5M50.8 35.3h-3.5c-1.7 0-3.1-1.4-3.1-3.1v-3.5" />
    </g>
  </svg>
);

export default SVG;
