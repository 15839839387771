import * as React from "react";

const SVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 66 68"
    className={`svg-icon icon-${props.name} ${props.className || ""}`}
    width={props.width || "1em"}
    height={props.height || "1em"}
    strokeWidth={props.strokeWidth || "1.5"}
  >
    <g fill="none" stroke="currentColor"  strokeLinecap="round" strokeLinejoin="round">
      <path d="M63.8 49.7c-1.9-3.6-5.7-6-10-6-6.3 0-11.3 5.1-11.3 11.3s5.1 11.3 11.3 11.3c4.3 0 8-2.4 10-6 .9-1.6 1.4-3.4 1.4-5.4 0-1.8-.5-3.6-1.4-5.2zM63.8 7.6c-1.9-3.6-5.7-6-10-6-6.3 0-11.3 5.1-11.3 11.3s5.1 11.3 11.3 11.3c4.3 0 8-2.4 10-6 .9-1.6 1.4-3.4 1.4-5.4s-.5-3.6-1.4-5.2zM22.9 28.6c-1.9-3.6-5.7-6-10-6-6.2.1-11.3 5.1-11.3 11.4S6.7 45.3 13 45.3c4.3 0 8-2.4 10-6 .9-1.6 1.4-3.4 1.4-5.4-.1-1.9-.6-3.7-1.5-5.3zM22.9 28.6l19.3-10.4M22.9 39.4l19.3 10.4" />
    </g>
  </svg>
);

export default SVG;
