import { addLocaleData, IntlProvider } from "react-intl";
import { DEFAULT_LOCALE } from "../../config";
import localeData from "../../config/locale-data";
import localeMessages from "../../locales";

// this function is marked as async for future compatibility
export default async function loadLocale(locale: string): Promise<IntlProvider.Props> {
  const intl = localeData[locale] || localeData[DEFAULT_LOCALE];
  const messages = localeMessages[locale] || localeMessages[DEFAULT_LOCALE];

  addLocaleData(intl);

  return {
    locale,
    messages,
  };
}
