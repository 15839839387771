import * as React from "react";

const SVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 68 68"
    className={`svg-icon icon-${props.name} ${props.className || ""}`}
    width={props.width || "1em"}
    height={props.height || "1em"}
    strokeWidth={props.strokeWidth || "1.5"}
  >
    <g fill="none" stroke="currentColor" >
      <path d="M.8.8h66.5v66.5H.8z" />
      <g strokeLinecap="round" strokeLinejoin="round">
        <path d="M25.84 34h16.3M35.34 27.2l6.8 6.8-6.8 6.8" />
      </g>
    </g>
  </svg>
);

export default SVG;
