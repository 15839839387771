import React from "react";
import { FormattedMessage } from "react-intl";
import Modal from "../../ui_kit/modal/modal";
import Section from "../../ui_kit/section/section";

export interface IOwnProps {
  onCloseEnd?: (modal: Modal, element: Element) => void;
}

type Props = IOwnProps;

const ModalServerError: React.FunctionComponent<Props> = ({ onCloseEnd }) => {
  const closeEndHandler = (modal: Modal, element: Element) => {
    if (onCloseEnd) {
      onCloseEnd(modal, element);
    }
  };
  return (
    <Modal id="server-error-modal" autoOpen={true} dismissible={true} onCloseEnd={closeEndHandler}>
      <Section className="center">
        <Section.Title>
          <FormattedMessage id="modal_server_error.title" defaultMessage="Errore" />
        </Section.Title>
        <Section.Content>
          <FormattedMessage
            id="modal_server_error.text"
            defaultMessage="Errore nella richiesta si prega di riprovare più tardi."
          />
        </Section.Content>
      </Section>
    </Modal>
  );
};

export default React.memo(ModalServerError);
