import * as React from "react";

const SVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 70 62"
    className={`svg-icon icon-${props.name} ${props.className || ""}`}
    width={props.width || "1em"}
    height={props.height || "1em"}
    strokeWidth={props.strokeWidth || "1.5"}
  >
    <g fill="none" stroke="currentColor" >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M11.7 12.2H3.1c-1 0-1.9-.8-1.9-1.9V6c0-2.6 2.1-4.7 4.7-4.7h58.2c2.6 0 4.7 2.1 4.7 4.7v4.4c0 1-.8 1.9-1.9 1.9h-9.3"
      />
      <path d="M12.5 1.3v52.8c0 3.6 2.9 6.6 6.6 6.6H51c3.6 0 6.6-2.9 6.6-6.6V1.3H12.5zM38.8 1.3v59.4M46.3 1.3v59.4" />
    </g>
  </svg>
);

export default SVG;
