import * as React from "react";

const SVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 70 70"
    className={`svg-icon icon-${props.name} ${props.className || ""}`}
    width={props.width || "1em"}
    height={props.height || "1em"}
    strokeWidth={props.strokeWidth || "1.5"}
  >
    <g fill="none" stroke="currentColor"  strokeLinecap="round" strokeLinejoin="round">
      <path d="M62.6 37.9v-7.3h5.8l-11.6-10V10.3h-8.7V13L35 1.6l-33.4 29h5.8v34.9h20.3V48.1" />
      <path d="M59.7 46.6c0 7.2-5.8 13.1-13.1 13.1s-13.1-5.8-13.1-13.1 5.8-13.1 13.1-13.1 13.1 5.9 13.1 13.1zM56.3 56.3l12.1 12.1" />
    </g>
  </svg>
);

export default SVG;
