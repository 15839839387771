import * as React from "react";

const SVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 68 60"
    className={`svg-icon icon-${props.name} ${props.className || ""}`}
    width={props.width || "1em"}
    height={props.height || "1em"}
    strokeWidth={props.strokeWidth || "1.5"}
  >
    <path
      fill="none"
      stroke="currentColor"
      
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M10 21.4V4.3c0-1.6 1.3-2.9 2.9-2.9h42.9c1.6 0 2.9 1.3 2.9 2.9v17.1M52.9 24.3v14.3H15.7V24.3c0-1.6-1.3-2.9-2.9-2.9H4.3c-1.6 0-2.9 1.3-2.9 2.9v28.5h65.7V24.3c0-1.6-1.3-2.9-2.9-2.9h-8.6c-1.5 0-2.7 1.3-2.7 2.9zM52.9 58.6h5.7v-5.7h-5.7zM10 58.6h5.7v-5.7H10z"
    />
  </svg>
);

export default SVG;
