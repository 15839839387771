import React from "react";
import { FormattedHTMLMessage, FormattedMessage } from "react-intl";
import { ButtonColors } from "../../ui_kit/button/button";
import LinkButton from "../../ui_kit/button/link_button";
import Modal from "../../ui_kit/modal/modal";
import Section from "../../ui_kit/section/section";

export interface IOwnProps {
  onCloseEnd?: (modal: Modal, element: Element) => void;
}

type Props = IOwnProps;

const ModalRegisterAgain: React.FunctionComponent<Props> = ({ onCloseEnd }) => {
  const closeEndHandler = (modal: Modal, element: Element) => {
    if (onCloseEnd) {
      onCloseEnd(modal, element);
    }
  };
  return (
    <Modal id="modal-register-again" autoOpen={true} dismissible={false} onCloseEnd={closeEndHandler}>
      <Section className="center">
        <Section.Title>
          <FormattedMessage id="modal_register_again.title" defaultMessage="Username disabilitato" />
        </Section.Title>
        <Section.Content>
          <FormattedHTMLMessage
            id="modal_register_again.description"
            defaultMessage="Per motivi di sicurezza lo username inserito è stato disabilitato definitivamente. Se hai già effettuato una nuova registrazione accedi con lo username scelto, altrimenti:"
          />
          <div className="modal-actions center">
            <LinkButton
              color={ButtonColors.Red}
              inverted={true}
              to="/users/new"
              data-cy="to-registration"
              className="modal-action"
            >
              <FormattedMessage id="modal_register_again.register" defaultMessage="Registrati nuovamente" />
            </LinkButton>
          </div>
        </Section.Content>
      </Section>
    </Modal>
  );
};

export default React.memo(ModalRegisterAgain);
