import * as React from "react";

const SVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 70 52"
    className={`svg-icon icon-${props.name} ${props.className || ""}`}
    width={props.width || "1em"}
    height={props.height || "1em"}
    strokeWidth={props.strokeWidth || "1.5"}
  >
    <g fill="none" stroke="currentColor"  strokeLinecap="round" strokeLinejoin="round">
      <path d="M66 51H4c-1.6 0-3-1.3-3-2.9V3.9C1 2.3 2.3 1 4 1h62c1.6 0 3 1.3 3 2.9V48c0 1.6-1.4 3-3 3z" />
      <path d="M1 36.7l21.6-18.1 22.7 20.6 13.3-8.8L69 36.6M54.8 17.2c0 4.1-3.3 7.3-7.4 7.3S40 21.2 40 17.2s3.3-7.3 7.4-7.3 7.4 3.2 7.4 7.3z" />
    </g>
  </svg>
);

export default SVG;
