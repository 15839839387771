// tslint:disable
/**
 * TIMFIN Authentication
 * TIMFIN Authentication API.
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface UsernamePolicy
 */
export interface UsernamePolicy {
    /**
     * A natural language description of the current username policy, such as:    \"It needs to have at least eight characters\"
     * @type {string}
     * @memberof UsernamePolicy
     */
    description: string;
    /**
     * A regular expression used to verify the current username policy, such as:  /.{8}.*_/
     * @type {string}
     * @memberof UsernamePolicy
     */
    regex: string;
}
