import * as React from "react";

const SVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 70 52"
    className={`svg-icon icon-${props.name} ${props.className || ""}`}
    width={props.width || "1em"}
    height={props.height || "1em"}
    strokeWidth={props.strokeWidth || "1.5"}
  >
    <g fill="none" stroke="currentColor"  strokeLinecap="round" strokeLinejoin="round">
      <path d="M63.3 39.3V6.1c0-2.6-2.1-4.7-4.7-4.7h-47C9 1.4 6.9 3.5 6.9 6.1v33.2" />
      <path d="M1.3 39.3v6.2c0 2.8 2.3 5.1 5.1 5.1h57.5c2.8 0 5.1-2.3 5.1-5.1v-6.2H1.3z" />
      <path d="M39.8 39.4v1.9h-9.6v-1.9" />
    </g>
  </svg>
);

export default SVG;
