import * as React from "react";

const SVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 70 46"
    className={`svg-icon icon-${props.name} ${props.className || ""}`}
    width={props.width || "1em"}
    height={props.height || "1em"}
    strokeWidth={props.strokeWidth || "1.5"}
  >
    <g fill="none" stroke="currentColor"  strokeLinecap="round" strokeLinejoin="round">
      <path d="M1 33.1h44.4l5.9 5.8h8.9c6.2 0 10.5-6.2 8.2-12l-5.7-14.7C60.1 5.5 53.5 1 46.2 1H1" />
      <path d="M64.2 18.6H45.3l-2.8-8.8h18.2M1 18.5h32.6l-2.9-8.8H1M1.3 44.3h67.3M1.3 38.9h40.8" />
    </g>
  </svg>
);

export default SVG;
