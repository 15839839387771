import * as React from "react";

const SVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 70 70"
    className={`svg-icon icon-${props.name} ${props.className || ""}`}
    width={props.width || "1em"}
    height={props.height || "1em"}
    strokeWidth={props.strokeWidth || "1.5"}
  >
    <path
      fill="none"
      stroke="currentColor"
      
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M10.3 36.9h-9M68.9 36.9H28.5"
    />
    <path
      fill="none"
      stroke="currentColor"
      strokeMiterlimit={10}
      d="M39 36.9l.2-.4c.8-1.4 1.2-3 1.2-4.6v-7.8c.2-3.6 2.1-5 5.3-5 1 0 2.1.1 3.1.4.7.2 1.4.4 2.1.4.5 0 1-.2 1.5-.3l1.5-.3c.7-.1 1.4-.2 2.2-.2 2.9 0 5.5 1.6 5.7 5V32c0 1.6-.4 3.2-1.2 4.6l-.2.4-21.4-.1h0z"
    />
    <path
      fill="none"
      stroke="currentColor"
      
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M39 36.9l.2-.4c.8-1.4 1.2-3 1.2-4.6v-7.8c.2-3.6 2.1-5 5.3-5 1 0 2.1.1 3.1.4.7.2 1.4.4 2.1.4.5 0 1-.2 1.5-.3l1.5-.3c.7-.1 1.4-.2 2.2-.2 2.9 0 5.5 1.6 5.7 5V32c0 1.6-.4 3.2-1.2 4.6M18.9 1.3c2.4 0 4.9 1.9 4.4 4.4l-.7 4.2c-.3 2-1.7 3.8-3.8 3.8s-3.5-1.8-3.8-3.8l-.7-4.2c-.2-2.5 2.2-4.4 4.6-4.4zM50.9 1.3c2.4 0 4.9 1.9 4.4 4.4l-.7 4.2c-.3 2-1.7 3.8-3.8 3.8s-3.5-1.8-3.8-3.8l-.7-4.2c-.2-2.5 2.2-4.4 4.6-4.4zM10.3 36.9l-.6-1.3c-.8-1.4-1.2-3-1.2-4.6v-6.9c.2-3.6 2.4-5 5.7-5 1 0 1.7.1 2.7.4.7.2 1.4.4 2.1.4.5 0 1-.2 1.5-.3l1.5-.3c.7-.1 1.4-.2 2.2-.2 2.9 0 5.5 1.6 5.7 5v7c0 1.6-.4 3.2-1.2 4.6l-2.3 4.8V69M12 68.9V40.4l-1.7-3.5M18.9 68.9V46.6"
    />
  </svg>
);

export default SVG;
