// tslint:disable
/**
 * TIMFIN Authentication
 * TIMFIN Authentication API.
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface PasswordChangeRequest
 */
export interface PasswordChangeRequest {
    /**
     * @type {string}
     * @memberof PasswordChangeRequest
     */
    newPassword: string;
    /**
     * @type {string}
     * @memberof PasswordChangeRequest
     */
    newPasswordConfirmation: string;
}
