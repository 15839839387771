import Icon from "../ui_kit/icon/icon";
import React from "react";
import { FormattedMessage } from "react-intl";
import "./PrestitoAd.scss";

const PrestitoAd: React.FC = () => {
  return (
    <div className="prestito-ad">
      <div className="prestito-ad__title">
        <FormattedMessage id="PrestitoAd.PrestitoAd.title" defaultMessage="Conosci già il nostro prestito?" />
      </div>
      <div className="prestito-ad__cta">
        <a href="https://www.timfin.it/vantaggi-timfin" className="btn__cta" target="_blank" rel="noreferrer">
          <div className="cta__title">
            <Icon name="logo-mobile" className="icon-red logo-mobile" />
            <div>
              <FormattedMessage id="PrestitoAd.PrestitoAd.cta.title" defaultMessage="TIMFin Prestito" />
            </div>
          </div>
          <div className="cta__desc">
            <FormattedMessage id="PrestitoAd.PrestitoAd.cta.desc" defaultMessage="Puoi richiedere fino a 30.000€" />
            <div className="cta__arrow waves-effect">
              <Icon name="arrow-right" className="icon-white arrow-right" strokeWidth="2" />
            </div>
          </div>
        </a>
      </div>
      <div className="prestito-ad__note">
        <FormattedMessage
          id="PrestitoAd.PrestitoAd.note"
          defaultMessage="Offerta con finanziamento TIMFin. Annuncio pubblicitario con finalità promozionale. Condizioni contrattuali ed economiche nelle “Informazioni europee di base sul credito ai consumatori”, alla sezione Trasparenza. Salvo approvazione di TIMFin."
        />
      </div>
    </div>
  );
};

export default PrestitoAd;
