import * as React from "react";

const SVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 70 62"
    className={`svg-icon icon-${props.name} ${props.className || ""}`}
    width={props.width || "1em"}
    height={props.height || "1em"}
    strokeWidth={props.strokeWidth || "1.5"}
  >
    <g fill="none" stroke="currentColor"  strokeLinecap="round" strokeLinejoin="round">
      <path d="M36.8 58.5c-3.8 1.2-8.4 1.9-13.5 1.9C10.9 60.4 1 56.3 1 51.1v-15M23.5 45.1C11.1 45.1 1 41 1 35.9M23.7 34c-.2 1.3-.4 2.6-.4 4 0 3.3.7 6.4 2 9.2M45.8 15.5c12.4 0 22.5 10 22.5 22.4s-10 22.5-22.5 22.5c-3.2 0-6.2-.7-9-1.9M25.3 47.1c2.3 5.1 6.4 9.1 11.5 11.4M43.5 15.6c.8-.1 1.5-.1 2.3-.1M32.3 20c3.2-2.4 7-4 11.1-4.4M23.7 34c1-5.7 4.2-10.6 8.7-14" />
      <path d="M39.3 35c.3-.7.6-1.3 1-1.9.4-.6.8-1.2 1.3-1.7s1-1 1.6-1.4c.6-.4 1.2-.8 1.8-1 .6-.3 1.3-.5 2-.7.7-.1 1.4-.2 2.2-.2.7 0 1.5.1 2.2.2M39.3 42.4c.3.7.6 1.3 1 1.9.4.6.8 1.2 1.3 1.7s1 1 1.6 1.4c.6.4 1.2.8 1.8 1 .6.3 1.3.5 2 .7.7.1 1.4.2 2.2.2.7 0 1.5-.1 2.2-.2M36.1 35.2h13.2M36.1 42.3h13.2M25.5 26.8C12.8 26.8 1 30.9 1 36M22.972 16.468l-3.889 3.89-3.96-3.96M19.083 20.357l-.07-18.88" />
    </g>
  </svg>
);

export default SVG;
