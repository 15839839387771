import React from "react";
import { FormattedMessage } from "react-intl";
import { InputText } from "../../../ui_kit/form/input_text";
import Icon from "../../../ui_kit/icon/icon";
import ModalNumeroContratto from "../../modals/modal_numero_contratto";
import "./NumeroContrattoInput.scss";

const NumeroContrattoInput: React.FunctionComponent = () => {
  return (
    <>
      <ModalNumeroContratto />
      <div id="numero-contratto-input">
        <div className="numero-contratto-modal-trigger modal-trigger" data-target="numero-contratto-modal">
          <Icon name="question" />
        </div>
        <InputText
          label={<FormattedMessage id="NumeroContrattoInput.numero_contratto" defaultMessage="Numero di contratto*" />}
          name="numeroContratto"
          // defaultHelperText={
          //   <FormattedMessage
          //     id="form_recover_password.numero_contratto_helper"
          //     defaultMessage="Puoi inserire un numero contratto, un numero conto o un numero carta di credito (non può essere inserita una pratica di Cessione del Quinto)."
          //   />
          // }
        />
      </div>
    </>
  );
};

export default React.memo(NumeroContrattoInput);
