import * as React from "react";

const SVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 70 70"
    className={`svg-icon icon-${props.name} ${props.className || ""}`}
    width={props.width || "1em"}
    height={props.height || "1em"}
    strokeWidth={props.strokeWidth || "1.5"}
  >
    <g fill="none" stroke="currentColor"  strokeLinejoin="round">
      <path strokeLinecap="round" d="M68.9 47.3l-23-39.7c-2.2-3.9-6.4-6.2-10.8-6.2-4.5 0-8.6 2.4-10.8 6.2l-23 39.7" />
      <path d="M57.8 28.1v36.1c0 2.6-2.1 4.7-4.7 4.7h-36c-2.6 0-4.7-2.1-4.7-4.7V28.1" />
      <path strokeLinecap="round" d="M43.8 68.9H26.4V44c0-1.1.9-2 2-2h13.5c1.1 0 2 .9 2 2v24.9h-.1z" />
    </g>
  </svg>
);

export default SVG;
