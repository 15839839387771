import * as React from "react";

const SVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 70 62"
    className={`svg-icon icon-${props.name} ${props.className || ""}`}
    width={props.width || "1em"}
    height={props.height || "1em"}
    strokeWidth={props.strokeWidth || "1.5"}
  >
    <path
      fill="none"
      stroke="currentColor"
      
      strokeLinejoin="round"
      d="M46.3 1.3h-22C11.7 1.3 1 11.5 1.3 24 1.5 34.4 9 43.1 19 45.2c1.1.2 1.1 1.2 1.1 2.3v9.7c0 1.5 1 2.8 2.4 3.2 1.4.3 2.8-.3 3.4-1.5L33 46.8c.4-.7 1.2-1.2 2.1-1.2h10.8c12.3 0 22.6-9.5 23-21.6.3-12.4-9.9-22.7-22.6-22.7z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="currentColor"
      d="M34.3 35.3c0 1-.8 1.8-1.9 1.8-1 0-1.8-.8-1.8-1.8s.8-1.8 1.8-1.8 1.9.8 1.9 1.8M31 30.4l-.1-2.1c0-.7.1-1.4.4-2.1.6-1 1.4-1.8 2.4-2.4 1-.7 2.1-1.2 3.1-1.9 1-.6 2-1.4 2.7-2.4 1.2-1.8.9-4.5-1-6-1-.7-2.2-.9-3.4-.9-2.2 0-3.6.5-5.4 1.1-.2.1-.5-.1-.6-.3l-.4-1.4c-.1-.2 0-.4.2-.5 1.7-.8 3.2-1.1 5.1-1.2.9-.1 1.9 0 2.8.1 1.5.2 3.1.8 4.3 1.9 1.3 1.2 1.9 3.1 2 4.7v.5c0 1.6-.6 3.2-1.7 4.4-1 1.1-2.4 1.8-3.7 2.6-.6.4-1.3.7-1.9 1.1-.6.4-1.1.8-1.5 1.4-.5.7-.6 1.5-.5 2.6l.1.9c0 .5-.3.8-.8.8H32c-.6-.1-1-.5-1-.9"
    />
  </svg>
);

export default SVG;
