import classNames from "classnames";
import React from "react";
import "./section.scss";
import SectionContent from "./section_content";
import SectionIntro from "./section_intro";
import SectionTitle from "./section_title";

interface IProps {
  className?: string;
  children: React.ReactNode;
}

export default class Section extends React.PureComponent<IProps> {
  public static Title = SectionTitle;
  public static Intro = SectionIntro;
  public static Content = SectionContent;

  public render() {
    const { className, children } = this.props;

    const classes = classNames({
      section: true,
      ...(className ? { [className]: className } : {}),
    });

    return <div className={classes}>{children}</div>;
  }
}
