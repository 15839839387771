import * as React from "react";

const SVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 70 70"
    className={`svg-icon icon-${props.name} ${props.className || ""}`}
    width={props.width || "1em"}
    height={props.height || "1em"}
    strokeWidth={props.strokeWidth || "1.5"}
  >
    <g fill="none" stroke="currentColor"  transform="translate(1 1)">
      <circle cx={34} cy={34} r={34} />
      <path strokeLinecap="round" strokeLinejoin="round" d="M34 24v20M24 34h20" />
    </g>
  </svg>
);

export default SVG;
