import produce from "immer";
import { version } from "../../package.json";
import { Middleware, RequestArgs } from "../generated";
import { getAppOrigin } from "./appOrigin";
import { getUserSessionId } from "./userSessionId";
import { generateSpanId } from "./spanId";
import { generateTraceId } from "./traceId";

export const loggingMiddleware: Middleware = {
  pre(request: RequestArgs): RequestArgs {
    const modifiedRequest: RequestArgs = produce(request, (draft) => {
      if (!draft.headers) {
        draft.headers = {};
      }

      draft.headers["app-origin"] = getAppOrigin();
      draft.headers["application-version"] = version;
      draft.headers["session-id"] = getUserSessionId();
      draft.headers["X-B3-SpanId"] = generateSpanId();
      draft.headers["X-B3-TraceId"] = generateTraceId();
    });

    return modifiedRequest;
  },
};
