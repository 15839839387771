import * as React from "react";

const SVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 70 70"
    className={`svg-icon icon-${props.name} ${props.className || ""}`}
    width={props.width || "1em"}
    height={props.height || "1em"}
    strokeWidth={props.strokeWidth || "1.5"}
  >
    <g fill="none" stroke="currentColor"  strokeLinejoin="round">
      <path
        strokeLinecap="square"
        d="M68.9 35.1c0 18.7-15.1 33.8-33.8 33.8S1.3 53.7 1.3 35.1 16.4 1.3 35.1 1.3s33.8 15.1 33.8 33.8z"
      />
      <path
        strokeLinecap="round"
        d="M35.1 12.6c-5.9 0-11.9 4.7-10.9 10.9l1.4 10.9c.7 4.9 4.5 8.2 9.5 8.2s8.8-3.3 9.6-8.2L46 23.5c1-6.3-5-10.9-10.9-10.9zM9.5 56.7c1.7-4.4 6.7-7.5 12.5-7.5 3.5 0 8 1.6 10.7 2.7 1.6.6 3.4.6 4.9 0 2.7-1.1 7-2.6 10.7-2.6 6.4 0 11 3.2 12.6 7.5"
      />
    </g>
  </svg>
);

export default SVG;
