import * as React from "react";

const SVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 88 55"
    className={`svg-icon icon-${props.name} ${props.className || ""}`}
    width={props.width || "1em"}
    height={props.height || "1em"}
    strokeWidth={props.strokeWidth || "1.5"}
  >
    <g fill="none" stroke="currentColor"  strokeLinecap="round" strokeLinejoin="round">
      <path d="M64.675 53.375h-52.6l-11.2-22.5h86.2zM8.375 30.875v-18.8h41.3l-3.8-3.7h15l15 22.5M68.375 19.575h-60" />
      <path d="M15.875 12.075V.875h7.8v11.2" />
    </g>
  </svg>
);

export default SVG;
