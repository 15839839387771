// tslint:disable
/**
 * TIMFIN Authentication
 * TIMFIN Authentication API.
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface PasswordPolicy
 */
export interface PasswordPolicy {
    /**
     * A natural language description of the current password policy, such as:  \"It needs to have at least eight characters\"
     * @type {string}
     * @memberof PasswordPolicy
     */
    description?: string;
    /**
     * A regular expression used to verify the current password policy, such as:  /.{8}.*_/
     * @type {string}
     * @memberof PasswordPolicy
     */
    regex: string;
}
