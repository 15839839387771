import * as React from "react";

const SVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 68 68"
    className={`svg-icon icon-${props.name} ${props.className || ""}`}
    width={props.width || "1em"}
    height={props.height || "1em"}
    strokeWidth={props.strokeWidth || "1.5"}
  >
    <g fill="none" stroke="currentColor" >
      <path d="M67.2 67.2H.7V.7h66.5z" />
      <g strokeLinecap="round" strokeLinejoin="round">
        <path d="M42.16 34h-16.3M32.66 27.2l-6.8 6.8 6.8 6.8" />
      </g>
    </g>
  </svg>
);

export default SVG;
