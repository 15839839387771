import * as React from "react";

const SVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 69 70"
    className={`svg-icon icon-${props.name} ${props.className || ""}`}
    width={props.width || "1em"}
    height={props.height || "1em"}
    strokeWidth={props.strokeWidth || "1.5"}
  >
    <g fill="none" stroke="currentColor"  strokeLinecap="round" strokeLinejoin="round">
      <path d="M8.2 69L1 65.6l9.9-22.3 7.2 3.4zM17.1 49L28 43.2c2.8-1.5 6.2-.9 8.5 1.4l1.1 1.2-12.4 6.6-3.6 1.9" />
      <path d="M25.1 52.5l9.8 6.2 10.8-5.1c2.9-1.3 5.5-1.5 7.9.2l3.5 2.2-19.5 10H9.5M68 18.7c0 9.8-7.9 17.7-17.7 17.7s-17.7-7.9-17.7-17.7S40.5 1 50.3 1 68 8.9 68 18.7z" />
      <path d="M53.8 27.8c-.5.1-1.1.2-1.6.2-.6 0-1.1-.1-1.6-.2s-1-.3-1.5-.5-.9-.5-1.4-.8c-.4-.3-.8-.7-1.2-1-.4-.4-.7-.8-1-1.3-.3-.5-.5-.9-.7-1.4-.2-.5-.4-1-.5-1.6-.1-.6-.2-1.1-.2-1.7 0-.6.1-1.2.2-1.7.1-.6.3-1.1.5-1.6s.5-1 .7-1.4c.3-.5.6-.9 1-1.3s.8-.7 1.2-1c.4-.3.9-.6 1.4-.8.5-.2 1-.4 1.5-.5.5-.1 1.1-.2 1.6-.2.6 0 1.1.1 1.6.2M42.4 17.2h10.8M42.4 21.7h10.8" />
    </g>
  </svg>
);

export default SVG;
