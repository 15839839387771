import * as React from "react";

const SVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 46 70"
    className={`svg-icon icon-${props.name} ${props.className || ""}`}
    width={props.width || "1em"}
    height={props.height || "1em"}
    strokeWidth={props.strokeWidth || "1.5"}
  >
    <g fill="none" stroke="currentColor"  strokeLinecap="round" strokeLinejoin="round">
      <path d="M30.3 8.9c0 4-3.2 7.3-7.2 7.3S16 12.9 16 8.9s3.2-7.3 7.2-7.3 7.1 3.3 7.1 7.3zM1.6 7.4H16M30.3 7.4h14.3M23.1 68.4c-2.4 0-4.3-1.9-4.3-4.4V52.4c0-2.4 1.9-4.4 4.3-4.4s4.3 1.9 4.3 4.4V64c0 2.4-1.9 4.4-4.3 4.4z" />
      <path d="M27.4 56.8h2.9c4.8 0 8.6-3.9 8.6-8.7V24.8c0-4.8-3.8-8.7-8.6-8.7H16c-4.8 0-8.6 3.9-8.6 8.7V48c0 4.8 3.8 8.7 8.6 8.7h2.9" />
    </g>
  </svg>
);

export default SVG;
