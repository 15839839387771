import classNames from "classnames";
import { ErrorMessage, Field, FieldProps } from "formik";
import React, { useEffect, useState } from "react";
import ClearInputIcon from "../../../assets/clear-input.png";

export interface IInputPasswordProps {
  name: string;
  label: string | React.ReactNode;
  autoComplete?: string;
  maxLength?: number;
  validate?: (value: string) => void;
  fieldClassName?: string;
  tabIndex?: string;
}
export const InputPassword: React.FunctionComponent<IInputPasswordProps> = ({
  name,
  label,
  autoComplete,
  maxLength,
  validate,
  fieldClassName = "",
  tabIndex = "0",
}: IInputPasswordProps) => {
  const inputRef = React.createRef();
  const handleClearInput = ({ form, field }: FieldProps) => () => {
    form.setFieldValue(field.name, "");
    inputRef.current.focus();
  };
  const [isFocused, setIsFocused] = useState(false);
  useEffect(() => {
    const isFieldFocused = document.activeElement === inputRef.current;
    if (isFieldFocused !== isFocused) {
      setIsFocused(isFieldFocused);
    }
  });
  return (
    <Field name={name} validate={validate}>
      {({ field, form }: FieldProps<any>) => {
        const notEmptyValue = field.value ? true : false;
        const formValid = form.touched[name] ? (form.errors[name] ? false : true) : undefined;
        return (
          <div className={`input-field ${fieldClassName}`}>
            <label className={field.value || isFocused ? "active" : ""} htmlFor={name}>
              {label}
            </label>
            {notEmptyValue && (
              <img src={ClearInputIcon} className="postfix" onClick={handleClearInput({ form, field })} />
            )}
            <input
              type="password"
              id={name}
              ref={inputRef}
              {...field}
              {...(autoComplete ? { autoComplete } : {})}
              {...(maxLength ? { maxLength } : {})}
              {...(tabIndex ? { tabIndex } : {})}
              className={classNames({
                "helper-text": true,
                invalid: formValid !== undefined && !formValid,
                valid: formValid,
              })}
            />
            {/* <span className="helper-text" data-error="Formato non valido" data-success="Formato valido"> */}
            <span
              className={classNames({
                "helper-text": true,
                invalid: formValid !== undefined && !formValid,
                valid: formValid,
              })}
            >
              <ErrorMessage name={name} />
            </span>
          </div>
        );
      }}
    </Field>
  );
};
