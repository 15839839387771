import * as React from "react";

const SVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 68 51"
    className={`svg-icon icon-${props.name} ${props.className || ""}`}
    width={props.width || "1em"}
    height={props.height || "1em"}
    strokeWidth={props.strokeWidth || "1.5"}
  >
    <g fill="none" stroke="currentColor"  strokeLinecap="round" strokeLinejoin="round">
      <path d="M29.8 38.2L18.4 49.6V38.2c-9.5-.7-17-8.7-17-18.4 0-5.1 2.1-9.7 5.4-13 3.3-3.3 7.9-5.4 13-5.4h28.3c10.2 0 18.4 8.2 18.4 18.4 0 5.1-2.1 9.7-5.4 13-3.3 3.3-7.9 5.4-13 5.4H29.8zM18.4 15.6h31.2M18.4 24.1h31.2" />
    </g>
  </svg>
);

export default SVG;
