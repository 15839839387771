import classNames from "classnames";
import { omit } from "lodash";
import React from "react";
import "./button.scss";

export enum ButtonColors {
  Blue = "blue",
  Red = "red",
  Green = "green",
  Gray = "gray",
}

export enum ButtonSizes {
  Large = "btn-large",
  Normal = "",
  Small = "btn-small",
  ExtraSmall = "btn-extra-small",
}

interface IButton extends React.ButtonHTMLAttributes<HTMLButtonElement> {}

export interface IProps extends IButton {
  color?: ButtonColors;
  disabled?: boolean;
  inverted?: boolean;
  wide?: boolean;
  size?: ButtonSizes;
  type?: "submit" | "reset" | "button";
  children?: React.ReactNode;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

class Button extends React.PureComponent<IProps> {
  public render() {
    const {
      color = ButtonColors.Blue,
      inverted = false,
      size = ButtonSizes.Normal,
      children,
      className,
      type = "button",
      wide = false,
      disabled = false,
    } = this.props;
    const attributes = omit(this.props, "color", "inverted", "size", "children", "className", "wide") as IButton;
    const dataTarget = this.props["data-target"] ? this.props["data-target"] : null;

    const classes = classNames({
      btn: true,
      "btn-wide": wide,
      [color]: color,
      ...(className ? { [className]: className } : {}),
      inverted,
      [size]: size,
      "waves-effect": true,
      "waves-light": inverted,
    });

    return (
      <button
        className={classes}
        {
          /* dynamically add the data-target attribute only if passed to the <Button> comp */
          ...(dataTarget ? { ["data-target"]: dataTarget } : {})
        }
        {
          /* dynamically add the disabled attribute */
          ...(disabled ? { disabled: true } : {})
        }
        type={type ? type : "button"}
        onClick={this.clickHandler}
        {...attributes}
      >
        {children}
      </button>
    );
  }

  private clickHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    const { onClick, disabled } = this.props;
    if (onClick && !disabled) {
      onClick(e);
    }
  };
}

export default Button;
