import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunkMiddleware from "redux-thunk";

import { NODE_ENV } from "./config/constants";
import authentication from "./logic/authentication/reducer";
import ui from "./logic/ui/reducer";
import { IStoreState } from "./types/store";

const defaultMiddlewares = [thunkMiddleware];

const appReducer = combineReducers<IStoreState>({
  authentication,
  ui,
});

const composedMiddlewares = (middlewares: any) =>
  ["development", "demo"].includes(NODE_ENV)
    ? composeWithDevTools(applyMiddleware(...defaultMiddlewares, ...middlewares))
    : compose(applyMiddleware(...defaultMiddlewares, ...middlewares));

// TODO: Add initialState?: IStoreState to initialize function
const initialize = (middlewares = []) => createStore(appReducer, composedMiddlewares(middlewares));

export default initialize;
