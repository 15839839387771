import classNames from "classnames";
import React from "react";
import { FormattedMessage } from "react-intl";
import Icon from "../../ui_kit/icon/icon";
import "./_form_auth.scss";

interface IProps {
  pageContainerClass?: string;
  title: string | React.ReactNode;
  intro?: string | React.ReactNode;
  showRequired?: boolean;
  content: React.ReactNode;
  actions?: React.ReactNode;
  modals?: React.ReactNode;
  footer?: React.ReactNode;
  formContentClass?: string;
  actionsClass?: string;
  isLoginPage?: boolean;
  hasRecaptcha?: boolean;
  // initialValues: {};
  // validationSchema: {};
  // formFields: React.ReactNode;
  // onSubmit?: (values: any, actions: any) => void;
}

interface IComponentProps {
  children: React.ReactNode;
  className?: string;
}

const Title: React.FunctionComponent<IComponentProps> = ({ children }) => {
  return <h3 className="title">{children}</h3>;
};

const Intro: React.FunctionComponent<IComponentProps> = ({ children, className }) => {
  return (
    <div className="row">
      <div
        className={classNames({
          "col xs12 s10 offset-s1 m8 offset-m2 title-section": true,
          // "col xs12 title-section": true,
          ...(className ? { [className]: className } : {}),
        })}
      >
        <h6 className="intro">{children}</h6>
      </div>
    </div>
  );
};

const Actions: React.FunctionComponent<IComponentProps> = ({ children, className }) => {
  return (
    <div className="row">
      <div
        className={classNames({
          "col xs12 s10 offset-s1 actions": true,
          ...(className ? { [className]: className } : {}),
        })}
      >
        {children}
      </div>
    </div>
  );
};
const Modals: React.FunctionComponent<IComponentProps> = ({ children }) => {
  return <div className="modals">{children}</div>;
};

const FormContent: React.FunctionComponent<IComponentProps> = ({ children, className }) => {
  return (
    <div className="row">
      <div
        className={classNames({
          "col xs12 s10 offset-s1 form-section": true,
          ...(className ? { [className]: className } : {}),
        })}
      >
        {children}
      </div>
    </div>
  );
};

const FormAuth: React.FunctionComponent<IProps> = ({
  pageContainerClass,
  title,
  intro,
  showRequired,
  actions,
  modals,
  content,
  footer,
  actionsClass,
  formContentClass,
  isLoginPage = false,
  hasRecaptcha = false,
}) => {
  return (
    <div className="content-container">
      <div
        className={classNames({
          container: true,
          "form-container": true,
          ...(pageContainerClass ? { [pageContainerClass]: pageContainerClass } : {}),
        })}
      >
        <div className="row">
          {/* <div className="col xs12 s12 m10 offset-m1 l8 offset-l1 xl6 offset-xl3"> */}
          <div className="col xs12 s12 m10 offset-m1 inner-content">
            <Title>{title}</Title>
            {intro && <Intro>{intro}</Intro>}
            {showRequired && (
              <div className="row">
                <div className="required-fields-description col xs12 s10 offset-s1 m8 offset-m2">
                  {<FormattedMessage id="form_auth.required_fields" defaultMessage="*campi obbligatori" />}
                </div>
              </div>
            )}
            <FormContent className={formContentClass}>{content}</FormContent>
            {actions && <Actions className={actionsClass}>{actions}</Actions>}
            {modals && <Modals>{modals}</Modals>}
          </div>
          {footer && <div className="col xs12 s12 no-padding">{footer}</div>}
        </div>
      </div>
      <div className="row">
        <div className="col xs12 disclaimer-container">
          <div className="disclaimer">
            <Icon name="pad-lock" className="icon-white pad-lock" strokeWidth="5" />
            <div className="disclaimer-text">
              <FormattedMessage
                id="form_auth.disclaimer_protected_operations"
                defaultMessage="Tutte le operazioni sono protette, garantite dalla certificazione VeriSign, che attesta la conformità alle norme per la sicurezza sul web. {cambioPassword}"
                values={{
                  cambioPassword: isLoginPage ? (
                    <FormattedMessage
                      id="form_auth.disclaimer_change_password"
                      defaultMessage="É consigliato modificare la propria password almeno una volta all'anno."
                    />
                  ) : (
                    ""
                  ),
                }}
              />
              {hasRecaptcha && (
                <span className="disclaimer-recaptcha">
                  Questo sito è protetto da reCAPTCHA e si applicano la{" "}
                  <a className="disclaimer-recaptcha-link" href="https://policies.google.com/privacy">
                    Privacy Policy
                  </a>{" "}
                  e i{" "}
                  <a className="disclaimer-recaptcha-link" href="https://policies.google.com/terms">
                    Termini di Servizio
                  </a>{" "}
                  di Google.
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(FormAuth);
