import * as React from "react";

const SVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 90 90"
    className={`svg-icon icon-${props.name} ${props.className || ""}`}
    width={props.width || "1em"}
    height={props.height || "1em"}
    strokeWidth={props.strokeWidth || "1.5"}
  >
    <g fill="none" stroke="currentColor"  strokeLinecap="round" strokeLinejoin="round">
      <path d="M13.9 43.1V31.4L37.2 8.1h38.9v35" />
      <path d="M13.9 31.4h23.3V8.1zM6.1 81.9h77.8V43.1H6.1z" />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="currentColor"
      d="M20.1 73H15V51.6h13.6v3.9H20v5.4h8.2v3.5H20V73zm27.2 0l-1.9-5.1h-8.6L35.3 73h-5.4L38 51.6h6.2L52.8 73h-5.5zm-3.1-8.9c-1.6-4.7-2.3-7-2.7-7.8 0-.8-.4-1.2-.4-1.6-.4 1.2-1.6 4.3-3.1 9.3h6.2zM74.9 73h-5.8l-5.4-8.2-5.5 8.2h-5.4l7.8-10.9-7.4-10.5h5.4l5.1 7.8 5.1-7.8h5.4l-7.4 10.5L74.9 73z"
    />
  </svg>
);

export default SVG;
