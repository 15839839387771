import * as React from "react";

const SVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 52 70"
    className={`svg-icon icon-${props.name} ${props.className || ""}`}
    width={props.width || "1em"}
    height={props.height || "1em"}
    strokeWidth={props.strokeWidth || "1.5"}
  >
    <g fill="none" stroke="currentColor"  strokeLinecap="round" strokeLinejoin="round">
      <path d="M5.8 1.3h40.5c2.5 0 4.5 2 4.5 4.5v58.6c0 2.5-2 4.5-4.5 4.5H5.8c-2.5 0-4.5-2-4.5-4.5V5.8c0-2.5 2-4.5 4.5-4.5z" />
      <path d="M36.7 14.2V21v-6.8zM10.4 8.9h31.4c1.1 0 2 .9 2 2v13.6c0 1.1-.9 2-2 2H10.4c-1.1 0-2-.9-2-2V10.8c0-1.1.9-1.9 2-1.9zM14.7 35.1c0 1.5-1.3 2.8-2.8 2.8-1.6 0-2.8-1.2-2.8-2.8 0-1.5 1.3-2.8 2.8-2.8 1.6 0 2.8 1.2 2.8 2.8zM28.9 35.1c0 1.5-1.3 2.8-2.8 2.8-1.6 0-2.8-1.2-2.8-2.8 0-1.5 1.3-2.8 2.8-2.8s2.8 1.2 2.8 2.8zM43 35.1c0 1.5-1.3 2.8-2.8 2.8-1.6 0-2.8-1.2-2.8-2.8 0-1.5 1.3-2.8 2.8-2.8 1.6 0 2.8 1.2 2.8 2.8zM14.7 59.6c0 1.5-1.3 2.8-2.8 2.8-1.6 0-2.8-1.2-2.8-2.8 0-1.5 1.3-2.8 2.8-2.8 1.6 0 2.8 1.2 2.8 2.8zM28.9 59.6c0 1.5-1.3 2.8-2.8 2.8-1.6 0-2.8-1.2-2.8-2.8 0-1.5 1.3-2.8 2.8-2.8s2.8 1.2 2.8 2.8zM43 59.6c0 1.5-1.3 2.8-2.8 2.8-1.6 0-2.8-1.2-2.8-2.8 0-1.5 1.3-2.8 2.8-2.8 1.6 0 2.8 1.2 2.8 2.8zM14.7 47.5c0 1.5-1.3 2.8-2.8 2.8-1.6 0-2.8-1.2-2.8-2.8 0-1.5 1.3-2.8 2.8-2.8 1.6 0 2.8 1.3 2.8 2.8zM28.9 47.5c0 1.5-1.3 2.8-2.8 2.8-1.6 0-2.8-1.2-2.8-2.8 0-1.5 1.3-2.8 2.8-2.8s2.8 1.3 2.8 2.8zM43 47.5c0 1.5-1.3 2.8-2.8 2.8-1.6 0-2.8-1.2-2.8-2.8 0-1.5 1.3-2.8 2.8-2.8 1.6 0 2.8 1.3 2.8 2.8z" />
    </g>
  </svg>
);

export default SVG;
