import * as React from "react";

const SVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 56 56"
    className={`svg-icon icon-${props.name} ${props.className || ""}`}
    width={props.width || "1em"}
    height={props.height || "1em"}
    strokeWidth={props.strokeWidth || "1.5"}
  >
    <path
      fill="none"
      stroke="currentColor"
      
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M37.6 39.9l5.1-5c1.1-1.1 2.8-1.1 3.9 0l7.8 7.8c1.1 1.1 1.1 2.8 0 3.9l-3.9 3.9c-2.6 2.6-6.2 4.1-9.8 4.1-4.7 0-8.9-2.9-12.7-5.4-4.9-3.2-9.4-7-13.4-11.2-4.4-4.7-8.7-10.4-11.7-16.2-2.3-4.5-2-10 .8-14.2.5-.8 1.1-1.5 1.7-2.1l3.9-3.9c1.1-1.1 2.8-1.1 3.9 0L21 9.4c1.1 1.1 1.1 2.8 0 3.9l-5.1 5c-.9.9-1.1 2.4-.3 3.5 5 7.2 11.2 13.4 18.3 18.3 1.3.9 2.8.7 3.7-.2z"
    />
  </svg>
);

export default SVG;
