import { ModalOptions } from "materialize-css";
import React, { RefObject } from "react";
import Icon from "../icon/icon";
import "./modal.scss";

interface IProps {
  id: string;
  className?: string;
  footer?: React.ReactNode;
  children?: React.ReactNode;
  autoOpen?: boolean;

  opacity?: number;
  inDuration?: number;
  outDuration?: number;
  onOpenStart?: (modal: Modal, element: Element) => void;
  onOpenEnd?: (modal: Modal, element: Element) => void;
  onCloseStart?: (modal: Modal, element: Element) => void;
  onCloseEnd?: (modal: Modal, element: Element) => void;
  preventScrolling?: boolean;
  dismissible?: boolean;
  startingTop?: string;
  endingTop?: string;
}

interface IState {
  isOpen: boolean;
}

class Modal extends React.PureComponent<IProps, IState> {
  private static renderHeader(dismissible: boolean) {
    return (
      true && (
        <a className="modal-close modal-close-cross">
          <Icon name="close" className="icon-red" strokeWidth="10" />
        </a>
      )
    );
  }

  private static renderFooter(footerContent: React.ReactNode) {
    if (!footerContent) {
      return null;
    }
    return <div className="modal-footer">{footerContent}</div>;
  }

  private readonly modalRef: RefObject<HTMLDivElement>;
  private modalInstance: any;

  constructor(props: IProps) {
    super(props);

    this.state = {
      isOpen: props.autoOpen ? props.autoOpen : false,
    };

    this.modalRef = React.createRef();
  }

  public componentDidMount() {
    const {
      opacity,
      inDuration,
      outDuration,
      preventScrolling,
      dismissible = true,
      startingTop,
      endingTop,
    } = this.props;

    // in case an option is not passed, the default is used
    const modalConfig: Partial<ModalOptions> = {
      ...(opacity ? { opacity } : {}),
      ...(inDuration ? { inDuration } : {}),
      ...(outDuration ? { outDuration } : {}),
      ...(preventScrolling ? { preventScrolling } : {}),
      ...(startingTop ? { startingTop } : {}),
      ...(endingTop ? { endingTop } : {}),
      dismissible,
      onCloseEnd: this.closeEndHandler as any,
      onCloseStart: this.closeStartHandler as any,
      onOpenEnd: this.openEndHandler as any,
      onOpenStart: this.openStartHandler as any,
    };

    this.modalInstance = M.Modal.init(this.modalRef.current as any, modalConfig);

    if (this.props.autoOpen) {
      this.open();
    }
  }

  public open() {
    this.modalInstance.open();
  }

  public close() {
    this.modalInstance.close();
  }

  public destroy() {
    this.modalInstance.destroy();
  }

  // public componentDidUpdate(prevProps: Readonly<IProps>, snapshot?: any): void {}

  public render() {
    const { id, className, footer, children } = this.props;
    const { isOpen } = this.state;

    return (
      <div ref={this.modalRef} key={id} id={id} className={`no-autoinit modal ${className || ""}`}>
        {isOpen ? (
          <>
            {Modal.renderHeader(this.props.dismissible)}
            <div className="modal-content">{children}</div>
            {Modal.renderFooter(footer)}
          </>
        ) : null}
      </div>
    );
  }

  private openStartHandler = (modal: Modal, element: Element) => {
    const { onOpenStart } = this.props;

    if (onOpenStart) {
      onOpenStart(modal, element);
    }

    this.setState({ isOpen: true });
  };

  private openEndHandler = (modal: Modal, element: Element) => {
    const { onOpenEnd } = this.props;

    if (onOpenEnd) {
      onOpenEnd(modal, element);
    }
  };

  private closeStartHandler = (modal: Modal, element: Element) => {
    const { onCloseStart } = this.props;

    if (onCloseStart) {
      onCloseStart(modal, element);
    }
  };

  private closeEndHandler = (modal: Modal, element: Element) => {
    const { onCloseEnd } = this.props;

    // set isOpen to false BEFORE calling onCloseEnd callback (to avoid mutations on unmounted component)
    this.setState({ isOpen: false });

    if (onCloseEnd) {
      onCloseEnd(modal, element);
    }
  };
}

export default Modal;
