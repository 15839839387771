import classNames from "classnames";
import { ErrorMessage, Field, FieldProps } from "formik";
import React, { useEffect, useState } from "react";
import ClearInputIcon from "../../../assets/clear-input.png";

export interface IInputTextProps {
  autoComplete?: string;
  defaultHelperText?: string | React.ReactNode;
  label: string | React.ReactNode;
  maxLength?: number;
  name: string;
  fieldClassName?: string;
  tabIndex?: string;
}
export const InputText: React.RefForwardingComponent<HTMLInputElement, IInputTextProps> = React.forwardRef<
  HTMLInputElement,
  IInputTextProps
>(({ autoComplete, defaultHelperText, label, maxLength, name, fieldClassName = "", tabIndex = "0" }, ref) => {
  ref = React.createRef();
  const handleClearInput = ({ form, field }: FieldProps) => () => {
    form.setFieldValue(field.name, "");
    ref.current.focus();
  };
  const [isFocused, setIsFocused] = useState(false);
  useEffect(() => {
    const isFieldFocused = document.activeElement === ref.current;
    if (isFieldFocused !== isFocused) {
      setIsFocused(isFieldFocused);
    }
  });

  return (
    <Field name={name}>
      {({ field, form }: FieldProps<any>) => {
        const notEmptyValue = field.value ? true : false;
        const formValid = form.touched[name] ? (form.errors[name] ? false : true) : undefined;
        return (
          <div className={`input-field ${fieldClassName}`}>
            <label className={field.value || isFocused ? "active" : ""} htmlFor={name}>
              {label}
            </label>
            {notEmptyValue && (
              <img src={ClearInputIcon} className="postfix" onClick={handleClearInput({ form, field })} />
            )}
            <input
              type="text"
              id={name}
              ref={ref}
              {...field}
              {...(autoComplete ? { autoComplete } : {})}
              {...(maxLength ? { maxLength } : {})}
              {...(tabIndex ? { tabIndex } : {})}
              className={classNames({
                "helper-text": true,
                invalid: formValid !== undefined && !formValid,
                valid: formValid,
              })}
            />
            <span
              className={classNames({
                "helper-text": true,
                invalid: formValid !== undefined && !formValid,
                valid: formValid,
              })}
              // data-error="wrong"
              // data-success="right"
            >
              {form.errors[name] && form.touched[name] ? <ErrorMessage name={name} /> : defaultHelperText}
              {/* <ErrorMessage name={name} /> */}
            </span>
          </div>
        );
      }}
    </Field>
  );
});
