import * as React from "react";

const SVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 68 14"
    className={`svg-icon icon-${props.name} ${props.className || ""}`}
    width={props.width || "1em"}
    height={props.height || "1em"}
    strokeWidth={props.strokeWidth || "1.5"}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="currentColor"
      d="M8.5 0l-.6 6.4 6.5-1.7.3 2.1-6.3.7 4 5.4-2 1.1-3.1-6-2.9 6-2-1.1 3.9-5.4L0 6.9l.3-2.1 6.4 1.7L6.1 0h2.4zm26.6 0l-.6 6.4L41 4.8l.3 2.1-6.3.6 4 5.4-2 1.1-3.1-6-2.9 6-2-1.1 3.9-5.4-6.3-.6.4-2.1 6.4 1.7-.6-6.5h2.3zm26.7 0l-.6 6.4 6.5-1.7.3 2.2-6.3.6 4 5.4-2 1.1-3.1-6-2.9 6-2-1.1 3.9-5.4-6.3-.6.3-2.1L60 6.4 59.4 0h2.4z"
    />
  </svg>
);

export default SVG;
