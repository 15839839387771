// tslint:disable
/**
 * TIMFIN Authentication
 * TIMFIN Authentication API.
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface OtpVerificationRequest
 */
export interface OtpVerificationRequest {
    /**
     * One-Time Password
     * @type {string}
     * @memberof OtpVerificationRequest
     */
    otp: string;
}
