import * as React from "react";

const SVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 68 65"
    className={`svg-icon icon-${props.name} ${props.className || ""}`}
    width={props.width || "1em"}
    height={props.height || "1em"}
    strokeWidth={props.strokeWidth || "1.5"}
  >
    <g fill="none" stroke="currentColor"  strokeLinecap="round" strokeLinejoin="round">
      <path d="M67.2 31c0 18.3-14.9 33.2-33.2 33.2S.8 49.3.8 31M34 .8V40" />
      <path d="M21.9 28L34 40l12.1-12" />
    </g>
  </svg>
);

export default SVG;
