import get from "lodash.get";
import M from "materialize-css";
import React, { useEffect } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Redirect, Route, Switch } from "react-router-dom";
import FormAddOtpDevice from "./components/add_otp_device/form_add_otp_device";
import FormChangePassword from "./components/change_password/form_change_password";
import DummyApplication from "./components/dummy_application/dummy_application";
import DummyOldApplication from "./components/dummy_old_application/dummy_old_application";
import Header from "./components/header/header";
import FormLogin from "./components/login/form_login";
import NotFound from "./components/not_found/not_found";
import FormOtp from "./components/otp/form_otp";
import FormReEnableUser from "./components/re_enable_user/form_re_enable_user";
import FormRecoverPassword from "./components/recover_password/form_recover_password";
import FormRecoverUsername from "./components/recover_username/form_recover_username";
import ServerError from "./components/server_error/server_error";
import FormUser from "./components/user/form_user";
import ScrollToTop from "./scroll_to_top";

const Routes: React.FunctionComponent<RouteComponentProps> = (props: RouteComponentProps) => {
  const location = get(props, "location");
  useEffect(() => {
    M.AutoInit();
    window.oneTrustAdditions.reloadOTBanner();
  }, [location]);

  return (
    <ScrollToTop>
      <Header />
      <Switch>
        <Redirect from="/" exact={true} to="/auth/login" />
        <Route path="/auth/login" component={FormLogin} />
        <Route path="/auth/otp" component={FormOtp} />
        <Route path="/users/new" component={FormUser} />
        <Route path="/users/re-enable" component={FormReEnableUser} />
        <Route path="/users/recover-password" component={FormRecoverPassword} />
        <Route path="/users/recover-username" component={FormRecoverUsername} />
        <Route path="/users/change-password" component={FormChangePassword} />
        <Route path="/users/add-otp-device" component={FormAddOtpDevice} />
        <Route path="/application" component={DummyApplication} />
        <Route path="/old-application" component={DummyOldApplication} />
        <Route path="/server-error" component={ServerError} />
        <Route component={NotFound} />
      </Switch>
    </ScrollToTop>
  );
};

export default withRouter(Routes);
