import * as React from "react";

const SVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 73 72.4"
    className={`svg-icon icon-${props.name} ${props.className || ""}`}
    width={props.width || "1em"}
    height={props.height || "1em"}
    strokeWidth={props.strokeWidth || "1.5"}
  >
    <g fill="none" stroke="currentColor" >
      <path d="M50.8 49.9c-5 5-11.8 8-19.4 8C16.2 57.9 4 45.7 4 30.5 4 15.4 16.2 3.1 31.4 3.1c15.1 0 27.4 12.3 27.4 27.4 0 7.6-3.1 14.5-8 19.4zM51.2 50.3l19.9 19" />
    </g>
  </svg>
);

export default SVG;
