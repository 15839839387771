import * as React from "react";

const SVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 68 68"
    className={`svg-icon icon-${props.name} ${props.className || ""}`}
    width={props.width || "1em"}
    height={props.height || "1em"}
    strokeWidth={props.strokeWidth || "1.5"}
  >
    <g fill="none" stroke="currentColor"  strokeLinecap="round" strokeLinejoin="round">
      <path d="M66.5 34C66.5 52 52 66.5 34 66.5S1.5 52 1.5 34 16 1.5 34 1.5 66.5 16 66.5 34zM34 7.4V31M34 57.7v3M60.6 34h-3M10.3 34h-3M52.8 15.2l-2.1 2.1M17.3 50.7l-2.1 2.1M52.8 52.8l-2.1-2.1M17.3 17.3l-2.1-2.1" />
      <path d="M37 34c0 1.6-1.3 3-3 3s-3-1.3-3-3 1.3-3 3-3 3 1.4 3 3zM23.2 44.8l8.7-8.7" />
    </g>
  </svg>
);

export default SVG;
