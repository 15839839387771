import * as React from "react";

const SVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 70 70"
    className={`svg-icon icon-${props.name} ${props.className || ""}`}
    width={props.width || "1em"}
    height={props.height || "1em"}
    strokeWidth={props.strokeWidth || "1.5"}
  >
    <g fill="none" stroke="currentColor"  strokeLinecap="round" strokeLinejoin="round">
      <path d="M13.4 63.1c0 3.3-2.8 5.9-6.2 5.9S1 66.4 1 63.1c0-3.3 2.8-5.9 6.2-5.9s6.2 2.6 6.2 5.9z" />
      <path d="M50.5 27.6v25.1c0 5.7-4.8 10.3-10.8 10.3s-10.8-4.6-10.8-10.3V32c0-5.7-4.8-10.3-10.8-10.3S7.2 26.3 7.2 32v25.1" />
      <path d="M62.9 1h3c2.6 0 4 3 2.5 5.1L52.9 26.4c-1.2 1.6-3.7 1.6-4.9 0L32.6 6.1C31 4 32.4 1 35 1h3" />
    </g>
  </svg>
);

export default SVG;
