import * as React from "react";
import "./section_intro.scss";

interface IProps {
  centered?: boolean;
  className?: string;
  children: React.ReactNode;
}

const SectionIntro: React.FunctionComponent<IProps> = ({ centered = true, className, children }) => (
  <div className={`section__intro${centered ? " section__intro--centered" : ""}${className ? ` ${className}` : ""}`}>
    <p>{children}</p>
  </div>
);

export default React.memo(SectionIntro);
