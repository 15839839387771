import React from "react";

const ServerError: React.FC = () => {
  return (
    <div className="server-error content-container">
      <div className="container form-container">
        <div className="row">
          <div className="col xs12 s12 m10 offset-m1 inner-content">
            <h3 className="title">Si è verificato un errore imprevisto.</h3>
            <p className="center">
              Ci scusiamo per il disagio.
              <br />
              Si prega di chiudere il browser e riprovare più tardi.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(ServerError);
