import * as React from "react";

const SVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 30 30"
    className={`svg-icon icon-${props.name} ${props.className || ""}`}
    width={props.width || "1em"}
    height={props.height || "1em"}
    strokeWidth={props.strokeWidth || "1"}
  >
    <path
      d="M12.259 22.22H1.473A1.47 1.47 0 000 23.69v2.532c0 .406.163.772.428 1.038.268.27.636.435 1.045.435H12.26v-5.476zm17.962.436a1.469 1.469 0 00-1.048-.437H18.388v5.476h10.785a1.47 1.47 0 001.472-1.473v-2.531c0-.404-.161-.77-.424-1.035zm0-10.063a1.477 1.477 0 00-1.048-.436H18.388v5.474h10.785a1.467 1.467 0 001.472-1.47v-2.532c0-.403-.161-.77-.424-1.036zm-17.962-.436H1.473c-.407 0-.776.165-1.042.433a1.463 1.463 0 00-.43 1.04v2.53a1.467 1.467 0 001.473 1.47h10.785v-5.473zM29.173 7.57h-27.7c-.409 0-.777-.165-1.045-.434A1.46 1.46 0 010 6.098V3.566a1.473 1.473 0 011.473-1.472h27.7a1.472 1.472 0 011.472 1.472v2.532c0 .406-.161.77-.425 1.036a1.46 1.46 0 01-1.047.436z"
      fill="#E20E24"
      fillRule="evenodd"
    />
  </svg>
);

export default SVG;
