import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "./app.scss";
import { DEFAULT_LOCALE } from "./config";
import { BASE_PATH } from "./config/constants";
import intl from "./lib/intl/intl";
import Routes from "./routes";

const App: React.FunctionComponent = () => (
  <div className="app">
    <Router basename={BASE_PATH}>
      <Routes />
    </Router>
  </div>
);

export default intl(DEFAULT_LOCALE)(App);
