import { Form, Formik, FormikProps } from "formik";
import get from "lodash.get";
import React, { useEffect, useState } from "react";
import { defineMessages, FormattedHTMLMessage, FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import * as Yup from "yup";
import { LoginResponse, LoginResponseOtpDeviceStateEnum, Policies, RecoverPasswordRequest } from "../../generated";
import { clearToken, getPolicies, recoverPassword, resetResponseCode } from "../../logic/authentication/actions";
import {
  ERROR_REQUEST_BLOCKED_OTP,
  ERROR_REQUEST_BLOCKED_USER,
  ERROR_REQUEST_DISABLED_USER,
  ERROR_REQUEST_MIGRATED_NUMBER_MISSING,
  ERROR_REQUEST_REGISTER_PHONE,
  RECOVER_PASSWORD_RESET_RESPONSE,
} from "../../logic/authentication/consts";
import { IBasicRequest, IState } from "../../logic/authentication/types";
import { numeroContrattoRegexp } from "../../logic/ui/consts";
import { IStoreState } from "../../types/store";
import FormAuth from "../shared/form_auth/form_auth";
import NumeroContrattoInput from "../shared/Inputs/NumeroContrattoInput/NumeroContrattoInput";
import ModalMigratedNumberMissing from "../shared/modals/modal_migrated_number_missing";
import ModalNumberNotCorresponding from "../shared/modals/modal_number_not_corresponding";
import ModalRegisterNumber from "../shared/modals/modal_register_number";
import ModalServerError from "../shared/modals/modal_server_error";
import ModalUserBlocked from "../shared/modals/modal_user_blocked";
import ModalUserDisabled from "../shared/modals/modal_user_disabled";
import ReCaptcha, { execute, reCaptchaPubKey } from "../shared/recaptcha/recaptcha";
import Spinner from "../shared/spinner/spinner";
import Button from "../ui_kit/button/button";
import { InputText } from "../ui_kit/form/input_text";
import "./_form_recover_password.scss";

export interface IDispatchProps {
  getPolicies: () => void;
  recoverPassword: (recoverPasswordRequest: RecoverPasswordRequest) => void;
  resetResponseCode: (action: string) => void;
  clearToken: () => void;
}
export interface IStateProps {
  policies: IBasicRequest & Policies;
  recoverPasswordResponse: IBasicRequest & LoginResponse;
}

const translations = defineMessages({
  mustBeANumber: {
    defaultMessage: "Il dato inserito deve essere numerico",
    description: "Il dato inserito deve essere numerico",
    id: "generic.mustBeANumber",
  },
  required: {
    defaultMessage: "Campo obbligatorio",
    description: "campo obbligatorio",
    id: "generic.required",
  },
});

const initialState = {
  cellulare: "",
  codiceFiscale: "",
  email: "",
  numeroContratto: "",
  recaptchaToken: "",
  username: "",
};

const getValidationSchema = (t: any) => {
  return Yup.object().shape({
    cellulare: Yup.string().required(t(translations.required)),
    codiceFiscale: Yup.string().required(t(translations.required)),
    numeroContratto: Yup.string()
      .matches(numeroContrattoRegexp, { message: t(translations.mustBeANumber) })
      .required(t(translations.required)),
    recaptchaToken: Yup.string(),
    username: Yup.string().required(t(translations.required)),
  });
};

type Props = IStateProps & IDispatchProps;

const FormRecoverPassword: React.FunctionComponent<Props> = ({
  clearToken,
  getPolicies,
  policies,
  recoverPassword,
  recoverPasswordResponse,
  resetResponseCode,
  intl: { formatMessage: t },
}) => {
  const [recaptchaPending, setRecaptchaPending] = useState(false);

  useEffect(() => {
    clearToken();
  }, []);

  useEffect(() => {
    getPolicies();
    return () => {
      resetResponseCode(RECOVER_PASSWORD_RESET_RESPONSE);
    };
  }, []);

  const formikSubmit = (values: any, actions: any) => {
    execute({ sitekey: reCaptchaPubKey, callback: recaptchaCallBack(values), action: "recoverPassword" });
    setRecaptchaPending(true);
  };

  const recaptchaCallBack = (values: any) => (token: any) => {
    if (token) {
      values.recaptchaToken = token;
    }

    const data = {
      recoveryRequest: values,
    };
    recoverPassword(data);

    setRecaptchaPending(false);
  };

  if (get(recoverPasswordResponse, "error.status") === 200 && !recoverPasswordResponse.ndg) {
    return <Redirect to="/server-error" />;
  }

  if (
    get(recoverPasswordResponse, "error.status") === 200 &&
    recoverPasswordResponse.otpDeviceState === LoginResponseOtpDeviceStateEnum.MISSING
  ) {
    return <Redirect to="/users/add-otp-device" />;
  }

  if (
    get(recoverPasswordResponse, "error.status") === 200 &&
    !get(recoverPasswordResponse, "mobileNumberDifferent", false)
  ) {
    return <Redirect to="/auth/otp" />;
  }

  const closeModalHandler = () => {
    resetResponseCode(RECOVER_PASSWORD_RESET_RESPONSE);
  };

  return (
    <FormAuth
      hasRecaptcha={true}
      pageContainerClass="recover-password-container"
      actionsClass=""
      formContentClass=""
      title={<FormattedHTMLMessage id="form_recover_password.title" defaultMessage="Password smarrita" />}
      intro={
        <FormattedHTMLMessage
          id="form_recover_password.intro"
          defaultMessage="Puoi richiedere una nuova password compilando i seguenti campi:"
        />
      }
      showRequired={true}
      content={
        <Formik initialValues={initialState} validationSchema={getValidationSchema(t)} onSubmit={formikSubmit}>
          {(formikProps: FormikProps<any>) => (
            <Form>
              <InputText
                label={<FormattedMessage id="form_recover_password.username" defaultMessage="Username*" />}
                name="username"
              />
              {/* <InputText label={<FormattedMessage id="form.email" defaultMessage="Email*" />} name="email" /> */}
              <InputText
                label={<FormattedMessage id="form_recover_password.codice_fiscale" defaultMessage="Codice fiscale*" />}
                name="codiceFiscale"
              />
              <NumeroContrattoInput />
              <InputText
                label={<FormattedMessage id="form_recover_password.cellulare" defaultMessage="Numero cellulare*" />}
                name="cellulare"
                defaultHelperText={
                  <FormattedMessage
                    id="form_recover_password.mobile_phone_helper"
                    defaultMessage="Il numero di cellulare deve corrispondere con quello registrato in fase di richiesta della pratica, in caso contrario utilizza il link a fondo pagina per inserirlo/modificarlo."
                  />
                }
              />
              <ReCaptcha
                action="recover-password"
                callback={recaptchaCallBack(formikProps)}
                sitekey={reCaptchaPubKey}
              />
              {get(recoverPasswordResponse, "error.status") === 400 && (
                <div className="form-error">{get(recoverPasswordResponse, "error.response.description")}</div>
              )}
              {recoverPasswordResponse.loading || recaptchaPending ? (
                <Spinner />
              ) : (
                <Button data-cy="button-submit" disabled={!formikProps.isValid} inverted={false} type="submit">
                  <FormattedMessage id="form_recover_password.request_password" defaultMessage="Richiedi password" />
                  {/* <Icon name="arrow-right" className="icon-blue" strokeWidth="2" /> */}
                </Button>
              )}
            </Form>
          )}
        </Formik>
      }
      actions={
        <>
          <a className="change-number modal-trigger" data-target="register-number-modal" data-cy="register-number">
            <FormattedMessage
              id="form_recover_password.change_number"
              defaultMessage="Vuoi inserire/modificare il cellulare? Chiedi a un esperto."
            />
          </a>
        </>
      }
      modals={
        <>
          {get(recoverPasswordResponse, "error.status") === 403 &&
            (get(recoverPasswordResponse, "error.response.code") === ERROR_REQUEST_BLOCKED_USER ||
              get(recoverPasswordResponse, "error.response.code") === ERROR_REQUEST_BLOCKED_OTP) && (
              <ModalUserBlocked onCloseEnd={closeModalHandler} />
            )}
          {get(recoverPasswordResponse, "error.status") === 403 &&
            get(recoverPasswordResponse, "error.response.code") === ERROR_REQUEST_DISABLED_USER && (
              <ModalUserDisabled onCloseEnd={closeModalHandler} />
            )}
          {get(recoverPasswordResponse, "error.status") === 400 &&
            get(recoverPasswordResponse, "error.response.code") === ERROR_REQUEST_MIGRATED_NUMBER_MISSING && (
              <ModalMigratedNumberMissing onCloseEnd={closeModalHandler} />
            )}
          {get(recoverPasswordResponse, "error.status") === 400 &&
            get(recoverPasswordResponse, "error.response.code") === ERROR_REQUEST_REGISTER_PHONE && (
              <ModalRegisterNumber
                context="recover_password"
                autoOpen={true}
                dismissible={false}
                onCloseEnd={closeModalHandler}
              />
            )}
          {get(recoverPasswordResponse, "error.status") === 200 &&
            get(recoverPasswordResponse, "mobileNumberDifferent", false) && (
              <ModalNumberNotCorresponding onCloseEnd={closeModalHandler} />
            )}
          <ModalRegisterNumber context="recover_password" />
          {get(recoverPasswordResponse, "error.status") >= 500 && <ModalServerError onCloseEnd={closeModalHandler} />}
        </>
      }
    />
  );
};

const mapStateToProps = (state: IStoreState): IState => ({
  policies: state.authentication.policies,
  recoverPasswordResponse: state.authentication.recoverPasswordResponse,
});

const mapDispatchToProps: IDispatchProps = {
  clearToken,
  getPolicies,
  recoverPassword,
  resetResponseCode,
};

const withIntlComponent = injectIntl(FormRecoverPassword);

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(withIntlComponent));
